import http from '@/plugins/axios';

const endpoint = '/aseos';

const cleanlinessService = {
  getCleanliness() {
    return http.get(`${endpoint}`)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  getCleanlinessByDogId(dogId) {
    return http.get(`/caninos/${dogId}/aseos`)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  createCleanliness(payload) {
    return http.post(endpoint, payload)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  updateCleanliness(cleanlinessId, payload) {
    return http.put(`${endpoint}/${cleanlinessId}`, payload)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default cleanlinessService;
