<template>
  <v-dialog
    v-model="active"
    width="25%"
    persistent
  >
    <template #activator="{ on, attrs }">
      <ASecondaryButton
        label="Subir foto"
        small
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <ACard>
      <v-card-title>
        Subir foto de la mascota
      </v-card-title>
      <v-card-text>
        <v-file-input
          v-model="dogPhoto"
          color="white"
          filled
          rounded
          prepend-icon=""
          append-icon="mdi-camera"
          hide-details=""
        />
      </v-card-text>
      <v-card-actions class="justify-center">
        <ASecondaryButton
          label="Cerrar"
          @click="active = false"
        />
        <APrimaryButton
          label="Guardar"
          :loading="loading"
          :disabled="loading"
          @click="savePhoto()"
        />
      </v-card-actions>
    </ACard>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import ACard from '../atoms/ACard.vue';
import APrimaryButton from '../atoms/APrimaryButton.vue';
import ASecondaryButton from '../atoms/ASecondaryButton.vue';

export default {
  components: {
    APrimaryButton,
    ASecondaryButton,
    ACard,
  },

  props: {
    dog: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      active: false,
      loading: false,
      dogPhoto: null,
    };
  },

  methods: {
    ...mapActions('dog', ['updateDogPhoto']),
    ...mapMutations(['SET_SNACKBAR']),

    savePhoto() {
      this.loading = true;
      try {
        this.updateDogPhoto({
          ...this.dog,
          foto: this.dogPhoto,
        });
        this.SET_SNACKBAR({
          show: true,
          color: 'success',
          message: 'Foto registrada correctamente.',
        });
      } catch (error) {
        this.SET_SNACKBAR({
          show: true,
          color: 'error',
          message: error.name ? 'Ocurrió un error al registrar la foto.' : error,
        });
      }
      this.loading = false;
      this.active = false;
      this.dogPhoto = null;
    },
  },
};
</script>
