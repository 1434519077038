<template>
  <v-app id="app">
    <OAppBar v-if="!$route.meta.isPublic" />
    <v-main class="surface">
      <router-view />
    </v-main>
    <ODogPdf v-if="!$route.meta.isPublic" />
    <ODogPersonalDialog v-if="!$route.meta.isPublic" />
    <ASnackbar />
    <BaseDialog />
  </v-app>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import ASnackbar from './components/atoms/ASnackbar.vue';
import BaseDialog from './components/base/BaseDialog.vue';
import OAppBar from './components/organisms/OAppBar.vue';
import ODogPdf from './components/organisms/ODogPdf.vue';
import ODogPersonalDialog from './components/organisms/ODogPersonalDialog.vue';

export default {
  components: {
    ASnackbar,
    OAppBar,
    ODogPdf,
    ODogPersonalDialog,
    BaseDialog,
  },

  async mounted() {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user'));
      this.SET_USER_INFO(user);
    }

    await this.getDogs();
    await this.getUnsuscribedTypes();
    await this.getEntranceTypes();
    await this.getGenders();
    await this.getRaces();
    await this.getColors();
    await this.getCourseStatus();
    await this.getCourseResults();
    await this.getStatus();
    await this.getMedicalStatus();
    await this.getDocumentTypes();
    await this.getCustoms();
    await this.getVaccineStatus();
    await this.getWarehouses();
    await this.getCategories();
    await this.getSubcategories();
    await this.getMeasurements();
    await this.getMovementTypes();
    await this.getWarehouseMovements();
    await this.getMainWarehouses();
    await this.getMainCustoms();
    await this.getDecorationTypes();
    await this.getAnimalTypes();
    await this.getTipoBaja();
    await this.getUsers();
  },

  methods: {
    ...mapActions('unsuscribedType', ['getUnsuscribedTypes']),
    ...mapActions('entranceType', ['getEntranceTypes']),
    ...mapActions('gender', ['getGenders']),
    ...mapActions('race', ['getRaces']),
    ...mapActions('color', ['getColors']),
    ...mapActions('courseStatus', ['getCourseStatus']),
    ...mapActions('courseResult', ['getCourseResults']),
    ...mapActions('status', ['getStatus']),
    ...mapActions('medicalStatus', ['getMedicalStatus']),
    ...mapActions('documentType', ['getDocumentTypes']),
    ...mapActions('custom', ['getCustoms', 'getMainCustoms']),
    ...mapActions('vaccineStatus', ['getVaccineStatus']),
    ...mapActions('warehouse', ['getWarehouses']),
    ...mapActions('category', ['getCategories']),
    ...mapActions('subcategory', ['getSubcategories']),
    ...mapActions('measurement', ['getMeasurements']),
    ...mapActions('movementType', ['getMovementTypes']),
    ...mapActions('warehouseMovement', ['getWarehouseMovements']),
    ...mapActions('mainWarehouse', ['getMainWarehouses']),
    ...mapActions('dog', ['getDogs']),
    ...mapActions(['login']),
    ...mapActions('decorationType', ['getDecorationTypes']),
    ...mapActions('animalType', ['getAnimalTypes']),
    ...mapActions('tipoBaja', ['getTipoBaja']),
    ...mapActions('user', ['getUsers']),
    ...mapMutations(['SET_USER_INFO']),
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
