<template>
  <v-container>
    <v-row
      justify="space-between"
      align="center"
    >
      <v-col cols="4">
        <ATextField
          v-model="searchInput"
          label="Buscar"
          hide-details
          dense
        />
      </v-col>
      <v-col cols="auto">
        <APrimaryButton
          label="Nuevo registro"
          @click="SET_DIALOG(DIALOG_TYPES.CREATE_GENDER_DIALOG)"
        />
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="genders"
          :search="searchInput"
          class="transparent"
        >
          <template #[`item.actions`]="{ item }">
            <v-icon
              class="mx-2"
              @click="openEditDialog(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DIALOG_TYPES from '@/dialog-types';
import APrimaryButton from '../atoms/APrimaryButton.vue';
import ATextField from '../atoms/ATextField.vue';

export default {
  components: { APrimaryButton, ATextField },

  data() {
    return {
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Estatus de vacuna', value: 'title' },
        { text: 'Activo', value: 'activo' },
        { text: 'Acciones', value: 'actions', align: 'end' },
      ],
      DIALOG_TYPES,
      isDeleting: false,
      searchInput: '',
    };
  },

  computed: {
    ...mapGetters('gender', {
      genders: 'getGenders',
    }),
  },

  methods: {
    ...mapActions('gender', ['deleteGender']),

    openEditDialog(status) {
      this.$store.state.gender.genderForm = { ...status };
      this.SET_DIALOG(DIALOG_TYPES.UPDATE_GENDER_DIALOG);
    },

    async triggerDelete(status) {
      this.isDeleting = true;
      await this.deleteGender(status);
      this.isDeleting = false;
    },
  },
};
</script>
