import createTipoBaja from '@/modules/tipoBaja/application/createTipoBaja';
import deleteTipoBaja from '@/modules/tipoBaja/application/deleteTipoBaja';
import getTipoBaja from '@/modules/tipoBaja/application/getTipoBaja';
import updateTipoBaja from '@/modules/tipoBaja/application/updateTipoBaja';
import createApiTipoBajaRepository from '@/modules/tipoBaja/infrastructure/ApiTipoBajaRepository';

const repository = createApiTipoBajaRepository();

export default {
  namespaced: true,

  state: {
    tipoBaja: [],
    tipoBajaForm: {
      id: null,
      title: '',
      activo: true,
    },
  },

  getters: {
    getTipoBaja(state) {
      return state.tipoBaja;
    },
  },

  mutations: {
    SET_TIPOS_BAJA(state, payload) {
      state.tipoBaja = payload;
    },
  },

  actions: {
    async getTipoBaja({ commit }) {
      const tipoBaja = await getTipoBaja(repository);
      commit('SET_TIPOS_BAJA', tipoBaja);
    },

    async createTipoBaja({ commit, state, dispatch }) {
      try {
        await createTipoBaja(repository, state.tipoBajaForm.title);
        dispatch('getTipoBaja');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Registro agregado al catálogo.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar agregar el registro.',
        }, { root: true });
      }
    },

    async updateTipoBaja({ commit, state, dispatch }) {
      try {
        await updateTipoBaja(repository, state.tipoBajaForm);
        dispatch('getTipoBaja');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Registro actualizado.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar actualizar el registro.',
        }, { root: true });
      }
    },

    async deleteTipoBaja({ commit, dispatch }, { id }) {
      try {
        await deleteTipoBaja(repository, id);
        dispatch('getTipoBaja');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Registro eliminado.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar eliminar el registro.',
        }, { root: true });
      }
    },
  },
};
