<template>
  <v-menu :close-on-content-click="false">
    <template #activator="{on, attrs}">
      <v-btn
        icon
        color="white"
        rounded
        large
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>
          mdi-account-circle
        </v-icon>
      </v-btn>
    </template>
    <ACard>
      <v-card-title class="text-center d-block">
        <div class="mt-2">
          {{ username }}
        </div>
        <div class="text-subtitle-2">
          {{ userEmployeeId }}
        </div>
        <ASelect
          v-if="isDevMode"
          v-model="$store.state.userInfo.perfiles[0].perfil"
          label="Perfil"
          :items="roles"
        />
        <div
          v-else
          class="text-subtitle-2"
        >
          {{ userProfile }}
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-list
          dense
          nav
          color="transparent"
        >
          <v-list-item @click="logout()">
            <v-list-item-title>Salir</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card-text>
    </ACard>
  </v-menu>
</template>

<script>
import ROLES from '@/roles';
import { mapGetters } from 'vuex';
import ACard from '../atoms/ACard.vue';
import ASelect from '../atoms/ASelect.vue';

export default {
  components: {
    ACard,
    ASelect,
  },

  data() {
    return {
      ROLES,
    };
  },

  computed: {
    ...mapGetters({
      username: 'getUserName',
      userEmployeeId: 'getUserEmployeeId',
      userProfile: 'getUserProfile',
    }),

    isDevMode() {
      return process.env.NODE_ENV === 'development';
    },

    roles() {
      return Object.values(this.ROLES).map((role) => ({
        title: role,
        id: role,
      }));
    },
  },

  methods: {
    logout() {
      localStorage.removeItem('user');
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
