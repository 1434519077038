import ROLES from '@/roles';

export default class UserEntity {
  id;

  nombre;

  perfil;

  email;

  password;

  fechaCreacion;

  ultimoLogin;

  activo;

  constructor() {
    this.id = null;
    this.nombre = '';
    this.perfil = '';
    this.email = '';
    this.password = '';
    this.fechaCreacion = null;
    this.ultimoLogin = null;
    this.activo = true;
  }

  fromDto(userDto) {
    this.id = userDto.id;
    this.nombre = userDto.nombre;
    this.perfil = ROLES[userDto.perfil];
    this.email = userDto.email;
    this.password = '';
    this.fechaCreacion = userDto.fecha_creacion;
    this.ultimoLogin = userDto.ultimo_login;
    this.activo = userDto.activo;

    return this;
  }
}
