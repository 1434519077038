import ROLES from './roles';

const {
  1: CONSULT,
  2: CONSULT_AND_CAPTURE,
  3: CATALOG_ADMIN,
} = ROLES;

const PERMISSIONS = {
  CREATE: 'CREATE',
  READ: 'READ',
  UPDATE: 'UPDATE',
  CATALOGS: 'CATALOGS',
};

const ALL_PERMISSIONS = [
  PERMISSIONS.CREATE,
  PERMISSIONS.READ,
  PERMISSIONS.UPDATE,
  PERMISSIONS.CATALOGS,
];

const ROLE_PERMISSIONS = {};

ROLE_PERMISSIONS[CONSULT] = [PERMISSIONS.READ];
ROLE_PERMISSIONS[CONSULT_AND_CAPTURE] = [PERMISSIONS.READ, PERMISSIONS.UPDATE, PERMISSIONS.CREATE];
ROLE_PERMISSIONS[CATALOG_ADMIN] = ALL_PERMISSIONS;

export default ROLE_PERMISSIONS;
