<template>
  <v-btn
    color="secondary"
    class="px-8"
    v-bind="$attrs"
    v-on="$listeners"
  >
    {{ label }}
  </v-btn>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
  },
};
</script>
