import createResponsible from '@/modules/responsible/application/createResponsible';
import getResponsibles from '@/modules/responsible/application/getResponsibles';
import updateResponsible from '@/modules/responsible/application/updateResponsible';
import ResponsibleEntity from '@/modules/responsible/domain/ResponsibleEntity';
import createApiResponsibleRepository from '@/modules/responsible/infrastructure/ApiResponsibleRepository';
import Vue from 'vue';

const repository = createApiResponsibleRepository();

export default {
  namespaced: true,

  state: {
    responsibles: [],
    responsibleForm: new ResponsibleEntity(),
  },

  getters: {
    getResponsibles(state) {
      return state.responsibles;
    },
  },

  mutations: {
    SET_RESPONSIBLES(state, payload) {
      Vue.set(state, 'responsibles', payload);
    },

    SET_CANINO_ID_TO_RESPONSIBLES(state, idCanino) {
      Vue.set(state.responsibleForm, 'idCanino', Number(idCanino));

      const mappedResponsibles = state.responsibles.map(
        (responsible) => ({ ...responsible, idCanino: Number(idCanino) }),
      );

      Vue.set(state, 'responsibles', [...mappedResponsibles]);
    },
  },

  actions: {
    getResponsibles: async ({ commit }, dogId) => {
      try {
        const responsibles = await getResponsibles(repository, dogId);
        commit('SET_RESPONSIBLES', responsibles);
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar obtener la lista de responsables.',
        }, { root: true });
      }
    },

    createResponsible: async ({ dispatch, commit, state }) => {
      try {
        await createResponsible(repository, state.responsibleForm);
        dispatch('getResponsibles');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Responsable agregado.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar agregar el nuevo responsable.',
        }, { root: true });
      }
    },

    updateResponsible: async ({ dispatch, commit, state }) => {
      try {
        await updateResponsible(repository, state.responsibleForm);
        dispatch('getResponsibles');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Responsable editado.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar editar el responsable.',
        }, { root: true });
      }
    },
  },
};
