import helpers from '@/mixins/helpers';
import taskService from '@/services/task.service';

export default {
  namespaced: true,

  state: {
    currentTask: null,
    tasks: [],

  },

  getters: {
    getCurrentTask(state) {
      return { ...state.currentTask };
    },

    getTasks(state) {
      return state.tasks.sort(helpers.methods.sortDescHelper);
    },
  },

  mutations: {
    SET_CURRENT_TASK(state, payload) {
      state.currentTask = payload;
    },

    SET_TASKS(state, payload) {
      state.tasks = payload;
    },

    ADD_TASK(state, payload) {
      state.tasks.push(payload);
    },

    UPDATE_TASKS(state, payload) {
      state.tasks = [
        ...state.tasks.filter((task) => task.id !== payload.id),
        payload,
      ];
    },

    UPDATE_CURRENT_TASK(state, payload) {
      state.currentTask = { ...payload };
    },
  },

  actions: {
    async getTasks({ commit }) {
      const tasks = await taskService.getTasks();
      commit('SET_TASKS', tasks);
    },

    async getTaskByDogId({ commit }, dogId) {
      const task = await taskService.getTaskByDogId(dogId);
      commit('SET_TASKS', task);
    },

    async createTask({ dispatch }, task) {
      const taskFormData = new FormData();
      taskFormData.append('fk_idCanino', task.fk_idCanino);
      taskFormData.append('descripcion', task.descripcion);
      taskFormData.append('fechaInicio', task.fechaInicio);
      taskFormData.append('fechaFin', task.fechaFin);
      taskFormData.append('adjunto', task.adjunto);
      taskFormData.append('fk_idCatEstatus', task.fk_idCatEstatus);

      await taskService.createTask(taskFormData);
      dispatch('getTaskByDogId', task.fk_idCanino);
    },

    async updateTask({ dispatch }, task) {
      const taskFormData = new FormData();
      taskFormData.append('fk_idCanino', task.fk_idCanino);
      taskFormData.append('descripcion', task.descripcion);
      taskFormData.append('fechaInicio', task.fechaInicio);
      taskFormData.append('fechaFin', task.fechaFin);
      taskFormData.append('adjunto', task.adjunto);
      taskFormData.append('fk_idCatEstatus', task.fk_idCatEstatus);

      await taskService.updateTask(task.id, taskFormData);
      dispatch('getTaskByDogId', task.fk_idCanino);
    },
  },
};
