<template>
  <v-btn
    fab
    color="secondary"
    dark
    tile
    small
    class="rounded-lg"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-icon>{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>
