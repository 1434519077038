import RaceDto from '../domain/RaceDto';

export default async function createRace(repository, raceForm) {
  const payload = new RaceDto().fromEntity(raceForm);
  return repository.createRace({
    activo: payload.activo,
    raza: payload.raza,
    id_cat_tipo_animal: payload.id_cat_tipo_animal,
  });
}
