<template>
  <v-container class="mt-4">
    <v-row>
      <v-col
        cols="12"
        align="right"
      >
        <OCreateDietDialog />
      </v-col>
      <v-col
        v-for="diet, index in diets"
        :key="index"
        cols="4"
      >
        <ODietCard :diet="diet" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import OCreateDietDialog from './organisms/OCreateDietDialog.vue';
import ODietCard from './organisms/ODietCard.vue';

export default {
  components: {
    OCreateDietDialog,
    ODietCard,
  },

  computed: {
    ...mapGetters('diet', {
      diets: 'getDiets',
    }),
  },
};
</script>
