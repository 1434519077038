import helpers from '@/mixins/helpers';
import cleanlinessService from '@/services/cleanliness.service';

export default {
  namespaced: true,

  state: {
    currentCleanliness: null,
    cleanliness: [],
  },

  getters: {
    getCurrentCleanliness(state) {
      return { ...state.currentCleanliness };
    },

    getCleanliness(state) {
      return state.cleanliness.sort(helpers.methods.sortDescHelper);
    },
  },

  mutations: {
    SET_CURRENT_CLEANLINESS(state, payload) {
      state.currentCleanliness = payload;
    },

    SET_CLEANLINESS_LIST(state, payload) {
      state.cleanliness = payload;
    },

    ADD_CLEANLINESS(state, payload) {
      state.cleanliness.push(payload);
    },

    UPDATE_CLEANLINESS(state, payload) {
      state.cleanliness = [
        ...state.cleanliness.filter((cleanliness) => cleanliness.id !== payload.id),
        payload,
      ];
    },

    UPDATE_CURRENT_CLEANLINESS(state, payload) {
      state.currentCleanliness = { ...payload };
    },
  },

  actions: {
    async getCleanliness({ commit }) {
      const cleanliness = await cleanlinessService.getCleanliness();
      commit('SET_CLEANLINESS_LIST', cleanliness);
    },

    async getCleanlinessByDogId({ commit }, dogId) {
      const cleanliness = await cleanlinessService
        .getCleanlinessByDogId(dogId);
      commit('SET_CLEANLINESS_LIST', cleanliness);
    },

    async createCleanliness({ dispatch }, cleanliness) {
      const cleanlinessFormData = new FormData();
      cleanlinessFormData.append('fk_idCanino', cleanliness.fk_idCanino);
      cleanlinessFormData.append('descripcion', cleanliness.descripcion);
      cleanlinessFormData.append('fechaInicio', cleanliness.fechaInicio);
      cleanlinessFormData.append('fechaFin', cleanliness.fechaFin);
      cleanlinessFormData.append('adjunto', cleanliness.adjunto);
      cleanlinessFormData.append('comentarios', cleanliness.comentarios);
      cleanlinessFormData.append('fk_idCatEstatus', cleanliness.fk_idCatEstatus);

      await cleanlinessService
        .createCleanliness(cleanlinessFormData);
      dispatch('getCleanlinessByDogId', cleanliness.fk_idCanino);
    },

    async updateCleanliness({ dispatch }, cleanliness) {
      const cleanlinessFormData = new FormData();
      cleanlinessFormData.append('fk_idCanino', cleanliness.fk_idCanino);
      cleanlinessFormData.append('descripcion', cleanliness.descripcion);
      cleanlinessFormData.append('fechaInicio', cleanliness.fechaInicio);
      cleanlinessFormData.append('fechaFin', cleanliness.fechaFin);
      cleanlinessFormData.append('adjunto', cleanliness.adjunto);
      cleanlinessFormData.append('comentarios', cleanliness.comentarios);
      cleanlinessFormData.append('fk_idCatEstatus', cleanliness.fk_idCatEstatus);

      await cleanlinessService.updateCleanliness(cleanliness.id, cleanlinessFormData);
      dispatch('getCleanlinessByDogId', cleanliness.fk_idCanino);
    },
  },
};
