import catalogosApi from '@/plugins/catalogos.axios';

async function getDocumentType() { // TODO: Corregir typo
  const { data } = await catalogosApi.get('/tiposdocumento/');
  return data.data;
}

async function createDocumentType(documentType) {
  const { data } = await catalogosApi.post('/tiposdocumento/', documentType);
  return data.data[0].id;
}

async function updateDocumentType(documentType) {
  await catalogosApi.put(`/tiposdocumento/${documentType.id}`, documentType);
}

async function deleteDocumentType(id) {
  return Promise.resolve(id);
}

export default function createApiDocumentTypeRepository() {
  return {
    getDocumentType,
    createDocumentType,
    updateDocumentType,
    deleteDocumentType,
  };
}
