import Vue from 'vue';
import Vuex from 'vuex';
import http from '@/plugins/axiosPersonalAnam';
import httpAccesoUnico from '@/plugins/axiosAccesoUnico';
import getCookieByName from '@/mixins/get-cookie-by-name.helper';
import createApiAuthorizationRepository from '@/modules/authorization/infrastructure/ApiAuthorizationRepsitory';
import login from '@/modules/authorization/application/login';
import ROLES from '@/roles';
import router from '@/router';
import assignment from './modules/assignment';
import category from './modules/category';
import cleanliness from './modules/cleanliness';
import color from './modules/color';
import course from './modules/course';
import courseResult from './modules/courseResult';
import courseStatus from './modules/courseStatus';
import custom from './modules/custom';
import decoration from './modules/decoration';
import diet from './modules/diet';
import document from './modules/document';
import documentType from './modules/documentType';
import dog from './modules/dog';
import entranceType from './modules/entranceType';
import gender from './modules/gender';
import measurement from './modules/measurement';
import medicalComment from './modules/medicalComment';
import medicalDiagnostic from './modules/medicalDiagnostic';
import medicalStatus from './modules/medicalStatus';
import movementType from './modules/movementType';
import personal from './modules/personal';
import race from './modules/race';
import status from './modules/status';
import subcategory from './modules/subcategory';
import task from './modules/task';
import treatment from './modules/treatment';
import treatmentMonitoring from './modules/treatmentMonitoring';
import unsuscribedType from './modules/unsuscribedType';
import vaccine from './modules/vaccine';
import vaccineStatus from './modules/vaccineStatus';
import warehouse from './modules/warehouse';
import warehouseMovement from './modules/warehouseMovement';
import mainWarehouse from './modules/mainWarehouse';
import decorationType from './modules/decorationTypes';
import dialog from './modules/dialog';
import responsible from './modules/responsible';
import animalType from './modules/animalType';
import tipoBaja from './modules/tipoBaja';
import user from './modules/user';

Vue.use(Vuex);

const repository = createApiAuthorizationRepository();

export default new Vuex.Store({
  state: {
    loading: true,
    loginData: null,
    snackbar: {
      show: false,
      color: 'accent',
      message: '',
    },
    userInfo: {
      apellido: '',
      curp: '',
      nombre: 'Usuario',
      numero_empleado: '123456',
      rfc: '',
      perfiles: [
        {
          perfil: ROLES[3],
        },
      ],
    },
  },

  getters: {
    getLoading(state) {
      return state.loading;
    },

    getSnackbar(state) {
      return state.snackbar;
    },

    getUserName(state) {
      return `${state.userInfo.nombre} ${state.userInfo.apellido}`;
    },

    getUserEmployeeId(state) {
      return state.userInfo.numero_empleado;
    },

    getUserProfile(state) {
      if (state.userInfo.perfiles.length > 0) {
        return state.userInfo.perfiles[0].perfil;
      }

      return '';
    },
  },

  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    },

    SET_LOGIN_DATA(state, payload) {
      state.loginData = payload;
    },

    SET_SNACKBAR(state, snackbar) {
      state.snackbar = { ...snackbar };
    },

    SET_USER_INFO(state, payload) {
      state.userInfo = { ...payload };
    },
  },

  actions: {
    async getUserInfo({ dispatch, commit }) {
      const anamSession = getCookieByName('anam_session');

      if (anamSession) {
        const response = await httpAccesoUnico.post('/session/user-info', {
          client_token: anamSession,
        }, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200) {
          console.info('Información de usuario obtenida correctamente.');
          try {
            const personalAnamData = await dispatch(
              'searchPersonal',
              response.data.numero_empleado || 'sin numero empleado',
            );
            if (personalAnamData.length > 0) {
              commit('SET_USER_INFO', {
                apellido: `${personalAnamData[0].apaterno} ${personalAnamData[0].amaterno}`,
                curp: personalAnamData[0].curp,
                nombre: personalAnamData[0].nombre,
                numero_empleado: personalAnamData[0].CUIP,
                rfc: personalAnamData[0].rfc,
                perfiles: [
                  {
                    perfil: response.data.perfiles ? response.data.perfiles[0].perfil : 'Sin perfil asignado',
                  },
                ],
              });
            } else {
              commit('SET_USER_INFO', response.data);
            }
          } catch (error) {
            commit('SET_USER_INFO', response.data);
          }
        } else {
          window.location.href = process.env.VUE_APP_REDIRECT_URL;
        }
      } else {
        window.location.href = process.env.VUE_APP_REDIRECT_URL;
      }
    },

    async login({ commit }, { username, password }) {
      try {
        const loggedUser = await login(repository, { username, password });
        const payload = {
          apellido: '',
          curp: '',
          nombre: loggedUser.nombre,
          numero_empleado: '',
          rfc: '',
          perfiles: [
            {
              perfil: ROLES[loggedUser.perfil],
            },
          ],
        };
        localStorage.setItem('user', JSON.stringify(payload));
        commit('SET_USER_INFO', payload);
        router.push('/home');
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar iniciar sesión.',
        }, { root: true });
      }
    },

    logout() {
      window.location.href = process.env.VUE_APP_REDIRECT_URL;
    },

    async searchPersonal(_, employeeNumber) {
      const response = await http.post('/EonCloud/EstadoFuerza/search/personal', {
        nombre: '',
        segundoNombre: '',
        apaterno: '',
        amaterno: '',
        curp: '',
        CUIP: employeeNumber,
      });

      return response.data.data;
    },
  },

  modules: {
    assignment,
    category,
    cleanliness,
    color,
    course,
    courseResult,
    courseStatus,
    custom,
    decoration,
    decorationType,
    dialog,
    diet,
    document,
    documentType,
    dog,
    entranceType,
    gender,
    mainWarehouse,
    measurement,
    medicalComment,
    medicalDiagnostic,
    medicalStatus,
    movementType,
    personal,
    race,
    status,
    subcategory,
    task,
    treatment,
    treatmentMonitoring,
    unsuscribedType,
    vaccine,
    vaccineStatus,
    warehouse,
    warehouseMovement,
    responsible,
    animalType,
    tipoBaja,
    user,
  },
});
