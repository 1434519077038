<template>
  <v-dialog
    v-model="createTreatmentDialog"
    width="50%"
    overlay-opacity="0.9"
  >
    <template #activator="{on, attrs}">
      <APrimaryButton
        v-if="hasPermission(userProfile, 'CREATE')"
        label="Agregar incidencia médica"
        :loading="loading"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <ACard>
      <v-card-title class="justify-center mb-4">
        Registro de nuevo incidencia médica
      </v-card-title>
      <v-card-text>
        <v-form v-model="createTreatmentForm">
          <v-row>
            <v-col cols="12">
              <div class="text-caption ml-6 mb-2">
                Descripción
              </div>
              <wysiwyg
                v-model="treatment.descripcion"
                class="white text--black black--text"
              />
            </v-col>
            <v-col cols="6">
              <ADateInput
                v-model="treatment.fechaInicio"
                label="Fecha de inicio"
              />
            </v-col>
            <v-col cols="6">
              <ADateInput
                v-model="treatment.fechaFin"
                label="Fecha de finalización"
              />
            </v-col>
            <v-col cols="6">
              <ASelect
                v-model="treatment.fk_idCatEstatus"
                label="Estatus"
                :items="statusList"
                item-value="id"
                item-text="title"
              />
            </v-col>
            <v-col cols="6">
              <v-file-input
                v-model="treatment.adjunto"
                color="white"
                label="Archivo adjunto"
                filled
                rounded
                prepend-icon=""
                append-icon="mdi-file"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <ASecondaryButton
          label="Cancelar"
          @click="createTreatmentDialog = false"
        />
        <APrimaryButton
          label="Guardar registro"
          :loading="loading"
          :disabled="loading || !createTreatmentForm"
          @click="triggerCreateTreatment()"
        />
      </v-card-actions>
    </ACard>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ACard from '../atoms/ACard.vue';
import ADateInput from '../atoms/ADateInput.vue';
import APrimaryButton from '../atoms/APrimaryButton.vue';
import ASecondaryButton from '../atoms/ASecondaryButton.vue';
import ASelect from '../atoms/ASelect.vue';

export default {
  components: {
    APrimaryButton,
    ACard,
    ASelect,
    ASecondaryButton,
    ADateInput,
  },

  data() {
    return {
      createTreatmentDialog: false,
      createTreatmentForm: false,
      loading: false,
      treatment: {
        fk_idCanino: null,
        descripcion: '',
        fechaInicio: '',
        fechaFin: '',
        adjunto: null,
        fk_idCatEstatus: null,
      },
      selectedDateStart: '',
      selectedDateEnd: '',
    };
  },

  computed: {
    ...mapGetters('dog', {
      dog: 'getCurrentDog',
    }),
    ...mapGetters('status', {
      statusList: 'getStatus',
    }),
  },

  methods: {
    ...mapActions('treatment', ['createTreatment']),
    ...mapMutations(['SET_SNACKBAR']),

    async triggerCreateTreatment() {
      this.loading = true;

      try {
        this.treatment.fechaInicio = this.treatment.fechaInicio ? this.treatment.fechaInicio : '1900-01-01';
        this.treatment.fechaFin = this.treatment.fechaFin ? this.treatment.fechaFin : '1900-01-01';
        this.treatment.fk_idCanino = this.dog.id;

        await this.createTreatment(this.treatment);
        this.SET_SNACKBAR({
          show: true,
          color: 'success',
          message: 'Incidencia médica registrada correctamente.',
        });
      } catch (error) {
        this.SET_SNACKBAR({
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar registrar la incidencia médica.',
        });
      }

      this.treatment = {
        fk_idCanino: null,
        descripcion: '',
        fecha: '',
        adjunto: null,
        examenes: '',
        fk_idCatEstatus: null,
      };
      this.selectedDateStart = '';
      this.selectedDateEnd = '';
      this.loading = false;
      this.createTreatmentDialog = false;
    },
  },
};
</script>
