import helpers from '@/mixins/helpers';
import assignmentService from '@/services/assignment.service';

export default {
  namespaced: true,

  state: {
    currentAssignment: null,
    assignments: [],
  },

  getters: {
    getCurrentAssignment(state) {
      return { ...state.currentAssignment };
    },

    getAssignments(state) {
      return state.assignments.sort(helpers.methods.sortDescHelper);
    },
  },

  mutations: {
    SET_CURRENT_ASSIGNMENT(state, payload) {
      state.currentAssignment = payload;
    },

    SET_ASSIGNMENTS(state, payload) {
      state.assignments = payload;
    },

    ADD_ASSIGNMENT(state, payload) {
      state.assignments.push(payload);
    },

    UPDATE_ASSIGNMENTS(state, payload) {
      state.assignments = [
        ...state.assignments.filter((assignment) => assignment.id !== payload.id),
        payload,
      ];
    },

    UPDATE_CURRENT_ASSIGNMENT(state, payload) {
      state.currentAssignment = { ...payload };
    },
  },

  actions: {
    async getAssignments({ commit }) {
      const assignments = await assignmentService.getAssignments();
      commit('SET_ASSIGNMENTS', assignments);
    },

    async getAssignmentByDogId({ commit }, assignmentId) {
      const assignment = await assignmentService.getAssignmentByDogId(assignmentId);
      commit('SET_ASSIGNMENTS', assignment);
      return assignment;
    },

    async createAssignment({ dispatch }, assignment) {
      await assignmentService.createAssignment(assignment);
      dispatch('getAssignmentByDogId', assignment.fk_idCanino);
    },

    async updateAssignment({ dispatch }, assignment) {
      await assignmentService.updateAssignment(assignment.id, assignment);
      dispatch('getAssignmentByDogId', assignment.fk_idCanino);
    },
  },
};
