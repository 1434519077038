import helpers from '@/mixins/helpers';
import medicalCommentService from '@/services/medicalComment.service';

export default {
  namespaced: true,

  state: {
    currentMedicalComment: null,
    medicalComments: [],
  },

  getters: {
    getCurrentMedicalComment(state) {
      return { ...state.currentMedicalComment };
    },

    getMedicalComments(state) {
      return state.medicalComments.sort(helpers.methods.sortDescHelper);
    },
  },

  mutations: {
    SET_CURRENT_MEDICAL_COMMENT(state, payload) {
      state.currentMedicalComment = payload;
    },

    SET_MEDICAL_COMMENTS(state, payload) {
      state.medicalComments = payload;
    },

    ADD_MEDICAL_COMMENT(state, payload) {
      state.medicalComments.push(payload);
    },

    UPDATE_MEDICAL_COMMENTS(state, payload) {
      state.medicalComments = [
        ...state.medicalComments.filter(
          (comment) => comment.id !== payload.id,
        ),
        payload,
      ];
    },

    UPDATE_CURRENT_MEDICAL_COMMENT(state, payload) {
      state.currentMedicalComment = { ...payload };
    },
  },

  actions: {
    async getMedicalComments({ commit }) {
      const medicalComments = await medicalCommentService.getMedicalComments();
      commit('SET_MEDICAL_COMMENTS', medicalComments);
    },

    async getMedicalCommentByMedicalId({ commit }, medicalCommentId) {
      const medicalComment = await medicalCommentService
        .getMedicalCommentByMedicalId(medicalCommentId);
      commit('SET_MEDICAL_COMMENTS', medicalComment);
    },

    async createMedicalComment({ dispatch }, medicalComment) {
      await medicalCommentService.createMedicalComment(medicalComment);
      dispatch('getMedicalCommentByMedicalId', medicalComment.fk_idMedico);
    },

    async updateMedicalComment({ dispatch }, medicalComment) {
      await medicalCommentService.updateMedicalComment(
        medicalComment.id,
        medicalComment,
      );
      dispatch('getMedicalCommentByMedicalId', medicalComment.fk_idMedico);
    },
  },
};
