<template>
  <MglMap
    :access-token="accessToken"
    :map-style="mapStyle"
    :center="defaultLocation"
    :zoom="8"
    :max-zoom="18"
    :min-zoom="5"
    :bearing="-17.6"
    :pitch="45"
    :antialias="true"
    :double-click-zoom="false"
    @load="mapLoaded"
  >
    <v-container fluid>
      <v-row>
        <v-col
          md="3"
          lg="4"
        >
          <v-card
            class="glass-card"
            outlined
          >
            <v-card-text>
              <v-expansion-panels
                flat
                hover
                tile
              >
                <v-expansion-panel class="transparent">
                  <v-expansion-panel-header>
                    Búsqueda
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="pt-4 text-center">
                    <MapSearchForm />
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="transparent">
                  <v-expansion-panel-header>
                    Resultados
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="pt-4">
                    <MapSearchResultsContent />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </MglMap>
</template>

<script>
import { MglMap } from 'vue-mapbox';
import Mapbox from 'mapbox-gl';
import { mapGetters } from 'vuex';
import MapSearchForm from '@/components/map/MapSearchForm.vue';
import MapSearchResultsContent from '@/components/map/MapSearchResultsContent.vue';

let mapRef = {};

export default {
  components: {
    MglMap,
    MapSearchForm,
    MapSearchResultsContent,
  },

  data() {
    return {
      accessToken: process.env.VUE_APP_MAPBOX_KEY,
      mapStyle: process.env.VUE_APP_MAPBOX_MAP_STYLE,
      mapbox: null,
      defaultLocation: [-98.20346, 19.03793],
      showCustomCard: false,
      selectedCustom: null,
      ticketsList: [],
      newTicketDialog: false,
      heatmapData: {
        type: 'FeatureCollection',
        features: [],
      },
    };
  },

  computed: {
    ...mapGetters('dog', {
      dogs: 'getDogs',
    }),
    ...mapGetters('custom', {
      customs: 'getCustoms',
    }),

    filteredDogsByCustom() {
      if (this.selectedCustom) {
        return this.dogs.filter(
          (dog) => dog.catAduana.fk_idAduanaPrincipal === this.selectedCustom.id,
        );
      }

      return this.dogs;
    },
  },

  created() {
    this.mapbox = Mapbox;
  },

  methods: {
    mapLoaded(event) {
      mapRef = event.map;
      this.generateHeatMapData();
    },

    showCustomMapCard(custom) {
      this.selectedCustom = custom;
      mapRef.flyTo({
        center: [custom.direccion.lgt, custom.direccion.lat],
        zoom: 12,
      });
      this.showCustomCard = true;
    },

    hideCustomCard() {
      this.showCustomCard = false;
      this.selectedCustom = null;
    },

    generateHeatMapData() {
      this.dogs.forEach((dog) => {
        if (dog.catAduana?.direccion) {
          this.heatmapData.features.push({
            type: 'Feature',
            properties: {
              dogs: 1,
            },
            geometry: {
              coordinates: [
                dog.catAduana.direccion.lgt,
                dog.catAduana.direccion.lat,
              ],
              type: 'Point',
            },
          });
        }
      });

      const data = this.heatmapData;

      mapRef.addSource('dogs', {
        type: 'geojson',
        data,
      });
      mapRef.addLayer(
        {
          id: 'trees-heat',
          type: 'heatmap',
          source: 'dogs',
          maxzoom: 15,
        },
        'waterway-label',
      );
    },
  },
};
</script>

<style scoped>
.fixed-card{
  position: fixed;
  bottom:48px;
  left: 50%;
  transform: translateX(-50%);
}

.glass-card{
  background: rgba(48, 46, 46, 0.76);
  box-shadow: 0 8px 32px 0 rgba(51, 42, 29, 0.37);
  backdrop-filter: blur( 5.5px );
  -webkit-backdrop-filter: blur( 5.5px );
  border-radius: 10px;
}

.theme--dark.v-sheet--outlined {
  border: 1px solid rgba(90, 62, 23, 0.144);
}
</style>
