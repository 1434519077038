<template>
  <v-breadcrumbs
    :items="breadcrumbs"
    large
  >
    <template #item="{ item }">
      <v-breadcrumbs-item
        :to="item.href"
        :disabled="item.disabled"
      >
        <span class="text--secondary font-weight-bold">
          {{ item.text }}
        </span>
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  computed: {
    breadcrumbs() {
      const breadcrumbs = [
        {
          text: 'Centro de Bienestar Animal',
          disabled: false,
          href: '/home',
        },
      ];

      if (this.$route.name === 'dog-record') {
        breadcrumbs.push({
          text: 'Expediente de Mascota',
          disabled: true,
        });
      }
      return breadcrumbs;
    },

  },
};
</script>

<style>

</style>
