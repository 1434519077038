<template>
  <Transition name="fade">
    <LoaderContainer v-if="isLoading" />
    <v-container
      v-else
      fluid
    >
      <v-row>
        <v-col
          cols="12"
        >
          <ODogListFilter
            :name-filter.sync="nameFilter"
            :tattoo-filter.sync="tattooFilter"
            :chip-filter.sync="chipFilter"
            :inventory-invoice-filter.sync="inventoryInvoiceFilter"
            :show-inactives.sync="showInactives"
          />
        </v-col>
        <v-col
          v-for="dog, index in filteredDogs"
          :key="index"
          cols="3"
        >
          <ODogResumeCard :dog="dog" />
        </v-col>
      </v-row>
    </v-container>
  </Transition>
</template>

<script>
import ODogResumeCard from '@/components/organisms/ODogResumeCard.vue';
import { mapGetters, mapMutations } from 'vuex';
import LoaderContainer from '@/components/LoaderContainer.vue';
import ODogListFilter from '@/components/organisms/ODogListFilter.vue';

export default {
  components: {
    ODogResumeCard,
    LoaderContainer,
    ODogListFilter,
  },

  data() {
    return {
      nameFilter: '',
      tattooFilter: '',
      chipFilter: '',
      inventoryInvoiceFilter: '',
      showInactives: false,
    };
  },

  computed: {
    ...mapGetters('dog', {
      dogs: 'getDogs',
    }),
    ...mapGetters({
      isLoading: 'getLoading',
    }),

    filteredDogs() {
      if (this.dogs.length === 0) {
        return this.dogs;
      }

      if (!this.showInactives) {
        return this.dogs
          .filter((dog) => dog.nombre.toLowerCase().indexOf(this.nameFilter.toLowerCase()) >= 0)
          .filter((dog) => dog.tatuaje.toLowerCase().indexOf(this.tattooFilter.toLowerCase()) >= 0)
          .filter((dog) => dog.noMicrochip.toLowerCase()
            .indexOf(this.chipFilter.toLowerCase()) >= 0)
          .filter((dog) => dog.folioInventario.toLowerCase()
            .indexOf(this.inventoryInvoiceFilter.toLowerCase()) >= 0)
          .filter((dog) => !dog.catTipoBaja);
      }

      return this.dogs
        .filter((dog) => dog.nombre.toLowerCase().indexOf(this.nameFilter.toLowerCase()) >= 0)
        .filter((dog) => dog.tatuaje.toLowerCase().indexOf(this.tattooFilter.toLowerCase()) >= 0)
        .filter((dog) => dog.noMicrochip.toLowerCase().indexOf(this.chipFilter.toLowerCase()) >= 0)
        .filter((dog) => dog.folioInventario.toLowerCase()
          .indexOf(this.inventoryInvoiceFilter.toLowerCase()) >= 0);
    },
  },

  async mounted() {
    this.SET_LOADING(false);
  },

  methods: {
    ...mapMutations(['SET_LOADING']),
  },
};
</script>
