/* eslint-disable camelcase */
export default class ResponsibleDto {
  id_canino;

  id;

  nombre;

  paterno;

  materno;

  tel1;

  email;

  activo;

  constructor() {
    this.id_canino = null;
    this.id = null;
    this.nombre = '';
    this.paterno = '';
    this.materno = '';
    this.tel1 = '';
    this.email = '';
    this.activo = true;
  }

  fromEntity(responsible) {
    this.id_canino = responsible.idCanino;
    this.id = responsible.id;
    this.nombre = responsible.nombre;
    this.paterno = responsible.paterno;
    this.materno = responsible.materno;
    this.tel1 = responsible.telefono;
    this.email = responsible.email;
    this.activo = true;

    return this;
  }
}
