<template>
  <v-dialog
    v-model="active"
    width="50%"
    persistent
  >
    <template #activator="{ on, attrs }">
      <AIconButton
        icon="mdi-clipboard-text-search"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <ACard :loading="loading">
      <v-card-title>
        Seguimiento de tratamiento
        <v-spacer />
        <OTreatmentMonitoringFormDialog
          v-if="hasPermission(userProfile, 'CREATE')"
          label="Agregar seguimiento"
          :treatment-id="treatmentId"
        />
      </v-card-title>
      <v-card-text>
        <v-list
          v-if="treatmentMonitorings.length > 0"
          color="transparent"
        >
          <v-virtual-scroll
            height="360"
            :items="treatmentMonitorings"
            item-height="64"
          >
            <template #default="{item}">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.descripcion }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.fecha }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <AIconButton
                    v-if="item.urlAdjunto !== ''"
                    icon="mdi-download"
                  />
                </v-list-item-action>
                <v-list-item-action>
                  <OTreatmentMonitoringFormDialog :update-data="item" />
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </v-list>
        <div
          v-else
          class="text-subtitle-1"
        >
          No hay Seguimientos registrados
        </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <APrimaryButton
          label="Cerrar"
          @click="active = false"
        />
      </v-card-actions>
    </ACard>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ACard from '../atoms/ACard.vue';
import AIconButton from '../atoms/AIconButton.vue';
import APrimaryButton from '../atoms/APrimaryButton.vue';
import OTreatmentMonitoringFormDialog from './OTreatmentMonitoringFormDialog.vue';

export default {
  components: {
    AIconButton,
    ACard,
    OTreatmentMonitoringFormDialog,
    APrimaryButton,
  },

  props: {
    treatmentId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      active: false,
      loading: false,
    };
  },

  computed: {
    ...mapGetters('treatmentMonitoring', {
      treatmentMonitorings: 'getTreatmentMonitorings',
    }),
  },

  watch: {
    async active(newValue) {
      if (newValue === true) {
        this.loading = true;
        await this.getTreatmentMonitoringByTreatmentId(this.treatmentId);
        this.loading = false;
      }
    },
  },

  methods: {
    ...mapActions('treatmentMonitoring', ['getTreatmentMonitoringByTreatmentId']),
  },
};
</script>
