<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <ATextField
          v-model="$store.state.tipoBaja.tipoBajaForm.title"
          label="Tipo de baja"
        />
      </v-col>
      <v-col
        v-if="!isNewTipoBaja"
        cols="12"
      >
        <v-switch
          v-model="$store.state.tipoBaja.tipoBajaForm.activo"
          label="Activo"
          color="secondary"
        />
      </v-col>
      <v-col
        cols="6"
        class="text-center"
      >
        <ASecondaryButton
          label="Cancelar"
          @click="closeDialog()"
        />
      </v-col>
      <v-col
        cols="6"
        class="text-center"
      >
        <APrimaryButton
          label="Guardar"
          @click="triggerSave()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import APrimaryButton from '../atoms/APrimaryButton.vue';
import ASecondaryButton from '../atoms/ASecondaryButton.vue';
import ATextField from '../atoms/ATextField.vue';

export default {
  components: { ATextField, APrimaryButton, ASecondaryButton },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    isNewTipoBaja() {
      return !this.$store.state.tipoBaja.tipoBajaForm.id;
    },
  },

  methods: {
    ...mapActions('tipoBaja', ['createTipoBaja', 'updateTipoBaja']),

    async triggerSave() {
      this.isLoading = true;

      if (this.isNewTipoBaja) {
        await this.createTipoBaja();
      } else {
        await this.updateTipoBaja();
      }

      this.isLoading = false;
      this.closeDialog();
    },

    closeDialog() {
      this.CLOSE_DIALOG();
      this.$store.state.tipoBaja.tipoBajaForm = {
        id: null,
        title: '',
      };
    },
  },
};
</script>
