import createDocumentType from '@/modules/documentType/application/createDocumentType';
import deleteDocumentType from '@/modules/documentType/application/deleteDocumentType';
import updateDocumentType from '@/modules/documentType/application/updateDocumentType';
import createApiDocumentTypeRepository from '@/modules/documentType/infrastructure/ApiDocumentTypeRepository';
import getDocumentType from '@/modules/documentType/application/getDocumentType';

const repository = createApiDocumentTypeRepository();

export default {
  namespaced: true,

  state: {
    documentTypes: [],
    documentType: {
      id: null,
      title: '',
      activo: true,
    },
  },

  getters: {
    getDocumentTypes(state) {
      return state.documentTypes;
    },
  },

  mutations: {
    SET_DOCUMENT_TYPES(state, payload) {
      state.documentTypes = payload;
    },
  },

  actions: {
    async getDocumentTypes({ commit }) {
      const documentTypes = await getDocumentType(repository);
      commit('SET_DOCUMENT_TYPES', documentTypes);
    },

    async createDocumentType({ commit, state, dispatch }) {
      try {
        await createDocumentType(repository, state.documentType.title);
        dispatch('getDocumentTypes');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Registro agregado al catálogo.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar agregar el registro.',
        }, { root: true });
      }
    },

    async updateDocumentType({ commit, state, dispatch }) {
      try {
        await updateDocumentType(repository, state.documentType);
        dispatch('getDocumentTypes');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Registro actualizado.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar actualizar el registro.',
        }, { root: true });
      }
    },

    async deleteDocumentType({ commit, dispatch }, { id }) {
      try {
        await deleteDocumentType(repository, id);
        dispatch('getDocumentTypes');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Registro eliminado.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar eliminar el registro.',
        }, { root: true });
      }
    },
  },
};
