import helpers from '@/mixins/helpers';
import courseService from '@/services/course.service';

export default {
  namespaced: true,

  state: {
    currentCourse: null,
    courses: [],
  },

  getters: {
    getCurrentCourse(state) {
      return { ...state.currentCourse };
    },

    getCourses(state) {
      return state.courses.sort(helpers.methods.sortDescHelper);
    },
  },

  mutations: {
    SET_CURRENT_COURSE(state, payload) {
      state.currentCourse = payload;
    },

    SET_COURSES(state, payload) {
      state.courses = payload;
    },

    ADD_COURSE(state, payload) {
      state.courses.push(payload);
    },

    UPDATE_COURSES(state, payload) {
      state.courses = [
        ...state.courses.filter((course) => course.id !== payload.id),
        payload,
      ];
    },

    UPDATE_CURRENT_COURSE(state, payload) {
      state.currentCourse = { ...payload };
    },
  },

  actions: {
    async getCourses({ commit }) {
      const courses = await courseService.getCourses();
      commit('SET_COURSES', courses);
    },

    async getCourseByDogId({ commit }, courseId) {
      const course = await courseService.getCourseByDogId(courseId);
      commit('SET_COURSES', course);
    },

    async createCourse({ dispatch }, course) {
      const courseFormData = new FormData();
      courseFormData.append('fk_idCanino', course.fk_idCanino);
      courseFormData.append('nombreCurso', course.nombreCurso);
      courseFormData.append('descripcion', course.descripcion);
      courseFormData.append('fechaInicio', course.fechaInicio);
      courseFormData.append('fechaFin', course.fechaFin);
      courseFormData.append('lugar', course.lugar);
      courseFormData.append('adjunto', course.adjunto);
      courseFormData.append('fk_idCatEstatusCurso', course.fk_idCatEstatusCurso);
      courseFormData.append('fk_idCatResultadoCurso', course.fk_idCatResultadoCurso);

      await courseService.createCourse(courseFormData);
      dispatch('getCourseByDogId', course.fk_idCanino);
    },

    async updateCourse({ dispatch }, course) {
      const courseFormData = new FormData();
      courseFormData.append('fk_idCanino', course.fk_idCanino);
      courseFormData.append('nombreCurso', course.nombreCurso);
      courseFormData.append('descripcion', course.descripcion);
      courseFormData.append('fechaInicio', course.fechaInicio);
      courseFormData.append('fechaFin', course.fechaFin);
      courseFormData.append('lugar', course.lugar);
      courseFormData.append('adjunto', course.adjunto);
      courseFormData.append('fk_idCatEstatusCurso', course.fk_idCatEstatusCurso);
      courseFormData.append('fk_idCatResultadoCurso', course.fk_idCatResultadoCurso);

      await courseService.updateCourse(course.id, courseFormData);
      dispatch('getCourseByDogId', course.fk_idCanino);
    },
  },
};
