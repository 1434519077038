import axios from '@/plugins/axios';

const http = {
  get(endpoint) {
    return axios.get(endpoint)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  create(endpoint, payload) {
    return axios.post(endpoint, payload)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  update(endpoint, payload) {
    return axios.put(endpoint, payload)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default http;
