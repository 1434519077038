import catalogosApi from '@/plugins/catalogos.axios';

async function getRace() {
  const { data } = await catalogosApi.get('/razas/');
  return data.data;
}

async function createRace(race) {
  const { data } = await catalogosApi.post('/razas/', race);
  return data.data[0].id;
}

async function updateRace(race) {
  await catalogosApi.put(`/razas/${race.id}`, race);
}

async function deleteRace(id) {
  return Promise.resolve(id);
}
export default function createRaceApiRepository() {
  return {
    getRace,
    createRace,
    updateRace,
    deleteRace,
  };
}
