import http from '@/services/http-handler.service';

const endpoint = '/unidadesmedida';

export default {
  namespaced: true,

  state: {
    measurements: [],
  },

  getters: {
    getMeasurements(state) {
      return state.measurements;
    },
  },

  mutations: {
    SET_MEASUREMENTS(state, payload) {
      state.measurements = payload;
    },
  },

  actions: {
    async getMeasurements({ commit }) {
      const measurements = await http.get(endpoint);
      commit('SET_MEASUREMENTS', measurements);
      return measurements;
    },
  },
};
