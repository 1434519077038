<template>
  <v-snackbar
    :value="snackbar.show"
    :timeout="5000"
    :color="snackbar.color"
    @input="resetSnackbar()"
  >
    {{ snackbar.message }}
  </v-snackbar>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      snackbar: 'getSnackbar',
    }),
  },

  methods: {
    ...mapMutations(['SET_SNACKBAR']),

    resetSnackbar() {
      this.SET_SNACKBAR({
        show: false,
        color: 'accent',
        message: '',
      });
    },
  },
};
</script>

<style>

</style>
