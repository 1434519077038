<template>
  <v-dialog
    v-model="isActiveDialog"
    :width="dialog.width"
    persistent
  >
    <ACard>
      <v-card-title class="text-subtitle1">
        {{ dialog.title }}
      </v-card-title>

      <v-card-text>
        <component :is="dialog.component" />
      </v-card-text>
    </ACard>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import ACard from '../atoms/ACard.vue';

export default {
  components: {
    ACard,
  },

  computed: {
    ...mapGetters('dialog', {
      dialog: 'getSelectedDialog',
    }),

    isActiveDialog() {
      return this.dialog.title !== '';
    },
  },
};
</script>
