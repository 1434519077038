import http from '@/services/http-handler.service';

const endpoint = '/categorias';

export default {
  namespaced: true,

  state: {
    categories: [],
  },

  getters: {
    getCategories(state) {
      return state.categories;
    },
  },

  mutations: {
    SET_CATEGORIES(state, payload) {
      state.categories = payload;
    },
  },

  actions: {
    async getCategories({ commit }) {
      const categories = await http.get(endpoint);
      commit('SET_CATEGORIES', categories);
      return categories;
    },

    async createCategory({ dispatch }, category) {
      await http.create(endpoint, category);
      dispatch('getCategories');
    },

    async updateCategory({ dispatch }, category) {
      await http.update(`${endpoint}/${category.id}`, category);
      dispatch('getCategories');
    },
  },
};
