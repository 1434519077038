import ROLE_PERMISSIONS from '@/permissions';
import ROLES from '@/roles';
import moment from 'moment/moment';
import { mapGetters } from 'vuex';

const helpers = {
  data() {
    return {
      required: (v) => !!v || 'Este campo es requerido',
    };
  },

  computed: {
    ...mapGetters({
      userProfile: 'getUserProfile',
    }),
  },

  methods: {
    groupDateTime(date, time) {
      const defaultDate = '1900-01-01';
      const defaultTime = '00:00:00';

      if (date !== '' && time !== '') {
        return `${date} ${time}:00`;
      }

      if (date === '') {
        if (time === '') {
          return `${defaultDate} ${defaultTime}`;
        }
        return `${defaultDate} ${time}`;
      }

      if (time === '') {
        if (date === '') {
          return `${defaultDate} ${defaultTime}`;
        }
        return `${date} ${defaultTime}`;
      }

      return `${defaultDate} ${defaultTime}`;
    },

    getAge(birthDate) {
      const momentBirthDate = moment(birthDate);
      return String(moment().diff(momentBirthDate, 'years'));
    },

    getFullName(person) {
      if (person.segundoNombre === '') {
        return `${person.apaterno} ${person.amaterno} ${person.nombre}`;
      }
      return `${person.apaterno} ${person.amaterno} ${person.nombre}${person.segundoNombre}`;
    },

    getStatusColor(statusId) {
      switch (statusId) {
        case 0:
          return 'gray';
        case 1:
          return 'success';
        case 2:
          return 'amber darken-1';
        case 3:
          return 'info';
        default:
          return 'gray';
      }
    },

    sortDescHelper(a, b) {
      if (a.id > b.id) {
        return -1;
      }
      if (a.id < b.id) {
        return 1;
      }
      return 0;
    },

    formatTimelineDate(date) {
      const day = moment(date, 'MM/DD/YYYY H:mm:ss A').lang('es').format('dddd');
      const dayText = moment(date, 'MM/DD/YYYY H:mm:ss A').lang('es').format('DD');
      const month = moment(date, 'MM/DD/YYYY H:mm:ss A').lang('es').format('MMMM');
      const year = moment(date, 'MM/DD/YYYY H:mm:ss A').lang('es').format('YYYY');
      return `${day} ${dayText} de ${month} de ${year}`;
    },

    getYear(date) {
      const year = moment(date, 'MM/DD/YYYY H:mm:ss A').format('YYYY');
      return year;
    },

    formatDate(date) {
      return moment(moment(date)).format('YYYY-MM-DD HH:mm:ss');
    },

    getReadableDate(date) {
      const dayText = moment(date, 'MM/DD/YYYY H:mm:ss A').lang('es').format('DD');
      const month = moment(date, 'MM/DD/YYYY H:mm:ss A').lang('es').format('MMMM');
      const year = moment(date, 'MM/DD/YYYY H:mm:ss A').lang('es').format('YYYY');
      return `${dayText} de ${month} de ${year}`;
    },

    downloadDocument(url) {
      window.open(url, '_blank');
    },

    getAddress(address) {
      return `${address.calle || ''} ${address.interior || ''}, ${address.colonia || ''}. ${address.municipio?.title || ''}, ${address.municipio?.estado?.title || ''}`;
    },

    mapValidEndDate(date) {
      let clonedDate = date;
      const year = moment(clonedDate, 'MM/DD/YYYY H:mm:ss A').year();

      if (year === 1900) {
        clonedDate = '';
      }

      return clonedDate;
    },

    hasPermission(role, permission) {
      if (!Object.values(ROLES).includes(role)) {
        window.location.href = process.env.VUE_APP_REDIRECT_URL;
      }

      return ROLE_PERMISSIONS[role].includes(permission);
    },
  },
};

export default helpers;
