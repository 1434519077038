import helpers from '@/mixins/helpers';
import treatmentService from '@/services/treatment.service';

export default {
  namespaced: true,

  state: {
    currentTreatment: null,
    treatments: [],
  },

  getters: {
    getCurrentTreatment(state) {
      return { ...state.currentTreatment };
    },

    getTreatments(state) {
      return state.treatments.sort(helpers.methods.sortDescHelper);
    },
  },

  mutations: {
    SET_CURRENT_TREATMENT(state, payload) {
      state.currentTreatment = payload;
    },

    SET_TREATMENTS(state, payload) {
      state.treatments = payload;
    },

    ADD_TREATMENT(state, payload) {
      state.treatments.push(payload);
    },

    UPDATE_TREATMENTS(state, payload) {
      state.treatments = [
        ...state.treatments.filter(
          (treatment) => treatment.id !== payload.id,
        ),
        payload,
      ];
    },

    UPDATE_CURRENT_TREATMENT(state, payload) {
      state.currentTreatment = { ...payload };
    },
  },

  actions: {
    async getTreatments({ commit }) {
      const treatments = await treatmentService.getTreatments();
      commit('SET_TREATMENTS', treatments);
    },

    async getTreatmentByDogId({ commit }, treatmentId) {
      const treatment = await treatmentService.getTreatmentByDogId(treatmentId);
      commit('SET_TREATMENTS', treatment);
    },

    async createTreatment({ dispatch }, treatment) {
      const treatmentFormData = new FormData();
      treatmentFormData.append('fk_idCanino', treatment.fk_idCanino);
      treatmentFormData.append('descripcion', treatment.descripcion);
      treatmentFormData.append('fechaInicio', treatment.fechaInicio);
      treatmentFormData.append('fechaFin', treatment.fechaFin);
      treatmentFormData.append('adjunto', treatment.adjunto);
      treatmentFormData.append('fk_idCatEstatus', treatment.fk_idCatEstatus);

      await treatmentService.createTreatment(treatmentFormData);
      dispatch('getTreatmentByDogId', treatment.fk_idCanino);
    },

    async updateTreatment({ dispatch }, treatment) {
      const treatmentFormData = new FormData();
      treatmentFormData.append('fk_idCanino', treatment.fk_idCanino);
      treatmentFormData.append('descripcion', treatment.descripcion);
      treatmentFormData.append('fechaInicio', treatment.fechaInicio);
      treatmentFormData.append('fechaFin', treatment.fechaFin);
      treatmentFormData.append('adjunto', treatment.adjunto);
      treatmentFormData.append('fk_idCatEstatus', treatment.fk_idCatEstatus);

      await treatmentService.updateTreatment(treatment.id, treatmentFormData);
      dispatch('getTreatmentByDogId', treatment.fk_idCanino);
    },
  },
};
