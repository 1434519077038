<template>
  <v-card class="glass-card">
    <v-card-text>
      <v-tabs
        v-model="selectedTab"
        color="white"
        background-color="transparent"
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.name"
        >
          {{ tab.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items
        v-model="selectedTab"
        class="transparent"
      >
        <v-tab-item
          v-for="tab in tabs"
          :key="tab.name"
        >
          <component :is="tab.component" />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import ACard from './atoms/ACard.vue';
import AnimalTypeTabContent from './tab-content/AnimalTypeTabContent.vue';
import ColoresTabContent from './tab-content/ColoresTabContent.vue';
import DocumentTypesTabContent from './tab-content/DocumentTypesTabContent.vue';
import EstatusMedicosTabContent from './tab-content/EstatusMedicosTabContent.vue';
import GenderTabContent from './tab-content/GenderTabContent.vue';
import RazasTabContent from './tab-content/RazasTabContent.vue';
import TipoBajaTabContent from './tab-content/TipoBajaTabContent.vue';
import UsersTabContent from './tab-content/UsersTabContent.vue';
import VaccineStatusTabContent from './tab-content/VaccineStatusTabContent.vue';

export default {
  components: { ACard },
  data() {
    return {
      selectedTab: 'Categoría',
      tabs: [
        {
          name: 'Colores',
          component: ColoresTabContent,
        },
        {
          name: 'Tipos de animales',
          component: AnimalTypeTabContent,
        },
        {
          name: 'Razas',
          component: RazasTabContent,
        },
        {
          name: 'Estatus médicos',
          component: EstatusMedicosTabContent,
        },
        {
          name: 'Estatus de vacunas',
          component: VaccineStatusTabContent,
        },
        {
          name: 'Géneros',
          component: GenderTabContent,
        },
        {
          name: 'Tipos de documentos',
          component: DocumentTypesTabContent,
        },
        {
          name: 'Tipos de baja',
          component: TipoBajaTabContent,
        },
        {
          name: 'Usuarios',
          component: UsersTabContent,
        },
      ],
    };
  },
};
</script>

<style scoped>
.glass-card{
  background: rgba(48, 46, 46, 0.76);
  box-shadow: 0 8px 32px 0 rgba(51, 42, 29, 0.37);
  backdrop-filter: blur( 5.5px );
  -webkit-backdrop-filter: blur( 5.5px );
  border-radius: 10px;
}

.theme--dark.v-sheet--outlined {
  border: 1px solid rgba(90, 62, 23, 0.144);
}
</style>
