<template>
  <v-container class="mt-4">
    <v-row>
      <v-col
        cols="12"
        align="right"
      >
        <OCreateTreatmentDialog />
      </v-col>
      <v-col
        v-for="treatment, index in treatments"
        :key="index"
        cols="4"
      >
        <OTreatmentCard :treatment="treatment" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import OCreateTreatmentDialog from './organisms/OCreateTreatmentDialog.vue';
import OTreatmentCard from './organisms/OTreatmentCard.vue';

export default {
  components: {
    OCreateTreatmentDialog,
    OTreatmentCard,
  },

  computed: {
    ...mapGetters('treatment', {
      treatments: 'getTreatments',
    }),
  },
};
</script>
