<template>
  <Transition name="fade">
    <LoaderContainer v-if="isLoading" />
    <v-container
      v-else
      fluid
    >
      <v-row>
        <v-col cols="3">
          <ODogMainCard />
        </v-col>
        <v-col cols="9">
          <DogInfoTabsComponent />
        </v-col>
      </v-row>
    </v-container>
  </Transition>
</template>

<script>
import DogInfoTabsComponent from '@/components/DogInfoTabsComponent.vue';
import ODogMainCard from '@/components/organisms/ODogMainCard.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import LoaderContainer from '@/components/LoaderContainer.vue';

export default {
  components: {
    ODogMainCard,
    DogInfoTabsComponent,
    LoaderContainer,
  },

  computed: {
    ...mapGetters({
      isLoading: 'getLoading',
    }),
    ...mapGetters('dog', {
      dog: 'getCurrentDog',
    }),
  },

  async mounted() {
    try {
      if (!this.dog) {
        await this.getDogById(this.$route.params.id);
      }

      await this.getTaskByDogId(this.$route.params.id);
      await this.getDietByDogId(this.$route.params.id);
      await this.getCleanlinessByDogId(this.$route.params.id);
      await this.getMedicalDiagnosticByDogId(this.$route.params.id);
      await this.getTreatmentByDogId(this.$route.params.id);
      await this.getCourseByDogId(this.$route.params.id);
      await this.getDecorationByDogId(this.$route.params.id);
      await this.getDocumentByDogId(this.$route.params.id);
      await this.getAssignmentByDogId(this.$route.params.id);
      await this.getVaccineByDogId(this.$route.params.id);
      await this.getResponsibles();
      this.SET_CANINO_ID_TO_RESPONSIBLES(this.$route.params.id);
    } catch (error) {
      console.error(error);
    }

    this.SET_LOADING(false);
  },

  methods: {
    ...mapMutations(['SET_LOADING']),
    ...mapMutations('responsible', ['SET_CANINO_ID_TO_RESPONSIBLES']),
    ...mapActions('dog', ['getDogById']),
    ...mapActions('task', ['getTaskByDogId']),
    ...mapActions('diet', ['getDietByDogId']),
    ...mapActions('cleanliness', ['getCleanlinessByDogId']),
    ...mapActions('medicalDiagnostic', ['getMedicalDiagnosticByDogId']),
    ...mapActions('treatment', ['getTreatmentByDogId']),
    ...mapActions('course', ['getCourseByDogId']),
    ...mapActions('decoration', ['getDecorationByDogId']),
    ...mapActions('document', ['getDocumentByDogId']),
    ...mapActions('assignment', ['getAssignmentByDogId']),
    ...mapActions('vaccine', ['getVaccineByDogId']),
    ...mapActions('responsible', ['getResponsibles']),
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
