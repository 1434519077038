<template>
  <v-dialog
    v-model="updateVaccineDialog"
    width="33%"
    overlay-opacity="0.9"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #activator="{on, attrs}">
      <AIconButton
        v-if="hasPermission(userProfile, 'UPDATE')"
        icon="mdi-pencil"
        class="mx-1"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <ACard>
      <v-card-title class="justify-center mb-4">
        Edición de vacuna
      </v-card-title>
      <v-card-text>
        <v-form v-model="updateVaccineForm">
          <v-row>
            <v-col cols="12">
              <ATextField
                v-model="vaccine.descripcion"
                label="Descripción"
              />
            </v-col>
            <v-col cols="6">
              <ADateInput
                v-model="vaccine.fechaAplicacion"
                label="Fecha de aplicación"
              />
            </v-col>
            <v-col cols="6">
              <ADateInput
                v-model="vaccine.fechaProximaDosis"
                label="Fecha de próxima dosis"
                clearable
              />
            </v-col>
            <v-col cols="6">
              <ADateInput
                v-model="vaccine.fechaProgramada"
                label="Fecha programada"
                clearable
              />
            </v-col>
            <v-col cols="6">
              <ASelect
                v-model="vaccine.fk_idEstatusVacuna"
                label="Estatus de vacuna"
                :items="vaccineStatusList"
                item-value="id"
                item-text="title"
              />
            </v-col>
            <v-col cols="6">
              <v-file-input
                v-model="vaccine.adjunto"
                color="white"
                label="Archivo adjunto"
                filled
                rounded
                prepend-icon=""
                append-icon="mdi-file"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <ASecondaryButton
          label="Cancelar"
          @click="updateVaccineDialog = false"
        />
        <APrimaryButton
          label="Guardar registro"
          :loading="loading"
          :disabled="loading || !updateVaccineForm"
          @click="triggerUpdateVaccine()"
        />
      </v-card-actions>
    </ACard>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ACard from '../atoms/ACard.vue';
import ADateInput from '../atoms/ADateInput.vue';
import AIconButton from '../atoms/AIconButton.vue';
import APrimaryButton from '../atoms/APrimaryButton.vue';
import ASecondaryButton from '../atoms/ASecondaryButton.vue';
import ASelect from '../atoms/ASelect.vue';
import ATextField from '../atoms/ATextField.vue';

export default {
  components: {
    APrimaryButton,
    ACard,
    ATextField,
    ASelect,
    ASecondaryButton,
    AIconButton,
    ADateInput,
  },

  data() {
    return {
      updateVaccineDialog: false,
      updateVaccineForm: false,
      loading: false,
    };
  },

  computed: {
    ...mapGetters('dog', {
      dog: 'getCurrentDog',
    }),
    ...mapGetters('vaccine', {
      vaccine: 'getCurrentVaccine',
    }),
    ...mapGetters('vaccineStatus', {
      vaccineStatusList: 'getVaccineStatus',
    }),
  },

  methods: {
    ...mapActions('vaccine', ['updateVaccine']),
    ...mapMutations(['SET_SNACKBAR']),

    async triggerUpdateVaccine() {
      this.loading = true;

      this.vaccine.fechaAplicacion = this.vaccine.fechaAplicacion !== '' ? this.vaccine.fechaAplicacion : '1900-01-01';
      this.vaccine.fechaProximaDosis = this.vaccine.fechaProximaDosis !== '' ? this.vaccine.fechaProximaDosis : '1900-01-01';
      this.vaccine.fechaProgramada = this.vaccine.fechaProgramada !== '' ? this.vaccine.fechaProgramada : '1900-01-01';

      try {
        await this.updateVaccine(this.vaccine);
        this.SET_SNACKBAR({
          show: true,
          color: 'success',
          message: 'Vacuna registrada correctamente.',
        });
      } catch (error) {
        this.SET_SNACKBAR({
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar editar la vacuna.',
        });
      }

      this.loading = false;
      this.updateVaccineDialog = false;
    },
  },
};
</script>
