import courseResultService from '@/services/courseResult.service';

export default {
  namespaced: true,

  state: {
    courseResults: [],
  },

  getters: {
    getCourseResults(state) {
      return state.courseResults;
    },
  },

  mutations: {
    SET_COURSE_RESULTS(state, payload) {
      state.courseResults = payload;
    },
  },

  actions: {
    async getCourseResults({ commit }) {
      const courseResults = await courseResultService.getCourseResults();
      commit('SET_COURSE_RESULTS', courseResults);
    },
  },
};
