// TODO: Corregir nombre de este archivo a ApiGenderRepository.js
import catalogosApi from '@/plugins/catalogos.axios';

async function getGender() {
  const { data } = await catalogosApi.get('/sexos/');
  return data.data;
}
async function createGender(gender) {
  const { data } = await catalogosApi.post('/sexos/', gender);
  return data.data[0].id;
}

async function updateGender(gender) {
  await catalogosApi.put(`/sexos/${gender.id}`, gender);
}

async function deleteGender(id) {
  return Promise.resolve(id);
}

export default function createApiGenderRepository() {
  return {
    getGender,
    createGender,
    updateGender,
    deleteGender,
  };
}
