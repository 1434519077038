import createGender from '@/modules/gender/application/createGender';
import deleteGender from '@/modules/gender/application/deleteGender';
import updateGender from '@/modules/gender/application/updateGender';
import createApiGenderRepository from '@/modules/gender/infrastructure/ApiGenderRepository';
import getGender from '@/modules/gender/application/getGender';

const repository = createApiGenderRepository();

export default {
  namespaced: true,

  state: {
    genders: [],
    genderForm: {
      id: null,
      title: '',
      activo: true,
    },
  },

  getters: {
    getGenders(state) {
      return state.genders;
    },
  },

  mutations: {
    SET_GENDERS(state, payload) {
      state.genders = payload;
    },
  },

  actions: {
    async getGenders({ commit }) {
      const genders = await getGender(repository);
      commit('SET_GENDERS', genders);
    },

    async createGender({ commit, state, dispatch }) {
      try {
        await createGender(repository, state.genderForm.title);
        dispatch('getGenders');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Registro agregado al catálogo.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar agregar el registro.',
        }, { root: true });
      }
    },

    async updateGender({ commit, state, dispatch }) {
      try {
        await updateGender(repository, state.genderForm);
        dispatch('getGenders');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Registro actualizado.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar actualizar el registro.',
        }, { root: true });
      }
    },

    async deleteGender({ commit, dispatch }, { id }) {
      try {
        await deleteGender(repository, id);
        dispatch('getGenders');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Registro eliminado.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar eliminar el registro.',
        }, { root: true });
      }
    },
  },
};
