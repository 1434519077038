import http from '@/plugins/axios';

const endpoint = '/EstatusCurso';

const courseStatusService = {
  getCourseStatus() {
    return http.get(`${endpoint}`)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default courseStatusService;
