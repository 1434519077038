<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <ATextField
          v-model="$store.state.user.userForm.nombre"
          label="User"
        />
      </v-col>
      <v-col cols="12">
        <ASelect
          v-model="$store.state.user.userForm.perfil"
          label="Perfil"
          :items="roles"
        />
      </v-col>
      <v-col cols="12">
        <ATextField
          v-model="$store.state.user.userForm.email"
          label="E-mail"
        />
      </v-col>
      <v-col
        v-if="isNewUser"
        cols="12"
      >
        <ATextField
          v-model="$store.state.user.userForm.password"
          label="Contraseña"
          type="password"
        />
      </v-col>
      <v-col
        v-if="!isNewUser"
        cols="12"
      >
        <v-switch
          v-model="$store.state.user.userForm.activo"
          label="Activo"
          color="secondary"
        />
      </v-col>
      <v-col
        cols="6"
        class="text-center"
      >
        <ASecondaryButton
          label="Cancelar"
          @click="closeDialog()"
        />
      </v-col>
      <v-col
        cols="6"
        class="text-center"
      >
        <APrimaryButton
          label="Guardar"
          @click="triggerSaveUser()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserEntity from '@/modules/user/domain/UserEntity';
import { mapActions } from 'vuex';
import APrimaryButton from '../atoms/APrimaryButton.vue';
import ASecondaryButton from '../atoms/ASecondaryButton.vue';
import ASelect from '../atoms/ASelect.vue';
import ATextField from '../atoms/ATextField.vue';

export default {
  components: {
    ATextField, APrimaryButton, ASecondaryButton, ASelect,
  },

  data() {
    return {
      isLoading: false,
      roles: [
        { id: 1, title: 'Consulta' },
        { id: 2, title: 'Consulta y Captura' },
        { id: 3, title: 'Consulta, Captura y Edición de catálogos' },
      ],
    };
  },

  computed: {
    isNewUser() {
      return !this.$store.state.user.userForm.id;
    },
  },

  methods: {
    ...mapActions('user', ['createUser', 'updateUser']),

    async triggerSaveUser() {
      this.isLoading = true;

      if (this.isNewUser) {
        await this.createUser();
      } else {
        await this.updateUser();
      }

      this.isLoading = false;
      this.closeDialog();
    },

    closeDialog() {
      this.CLOSE_DIALOG();
      this.$store.state.user.userForm = new UserEntity();
    },
  },
};
</script>
