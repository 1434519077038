<template>
  <v-container class="mt-4">
    <v-form v-model="updateDogForm">
      <v-row>
        <v-col cols="4">
          <ATextField
            v-model="dog.nombre"
            label="Nombre"
          />
        </v-col>
        <v-col cols="4">
          <ATextField
            v-model="dog.propiedad"
            label="Calle"
          />
        </v-col>
        <v-col cols="4">
          <ATextField
            v-model="dog.apellido"
            label="Numero"
          />
        </v-col>
        <v-col cols="4">
          <AAutocomplete
            v-model="selectedMainCustom"
            label="Municipio"
            :items="mainCustoms"
          />
        </v-col>
        <v-col cols="4">
          <AAutocomplete
            v-model="dog.fk_idCatAduana"
            label="Colonia"
            :items="filteredCustoms"
          />
        </v-col>
        <v-col cols="4">
          <AAutocomplete
            v-model="dog.fk_idCatAduana"
            label="Especie"
          />
        </v-col>
        <v-col cols="4">
          <ASelect
            v-model="dog.fk_idRaza"
            label="Raza"
            :items="races"
          />
        </v-col>
        <v-col cols="4">
          <ASelect
            v-model="dog.fk_idColor"
            label="Color"
            :items="colors"
          />
        </v-col>
        <v-col cols="4">
          <ASelect
            v-model="dog.fk_idSexo"
            label="Sexo"
            :items="genders"
          />
        </v-col>
        <v-col cols="4">
          <ADateInput
            v-model="dog.fechaNacimiento"
            label="Fecha de nacimiento"
          />
        </v-col>
        <v-col cols="4">
          <ATextField
            v-model="dog.propiedad"
            label="Edad"
          />
        </v-col>
        <v-col cols="4">
          <ATextField
            v-model="dog.lugarNacimiento"
            label="Lugar de nacimiento"
          />
        </v-col>
        <v-col cols="4">
          <ATextField
            v-model="dog.tatuaje"
            label="Tatuaje *"
            :rules="[required]"
          />
        </v-col>
        <v-col cols="4">
          <ATextField
            v-model="dog.noMicrochip"
            label="Número de microchip *"
            :rules="[required]"
          />
        </v-col>
        <v-col cols="4">
          <ATextField
            v-model="dog.folioInventario"
            label="Tag RFID *"
            :rules="[required]"
          />
        </v-col>
        <v-col cols="3">
          <ASelect
            v-model="dog.fk_idTipoBaja"
            label="Finado"
            :items="unsuscribedTypes"
            clearable
          />
        </v-col>
        <v-col cols="3">
          <ADateInput
            v-model="dog.fechaBaja"
            label="Fecha de deceso"
            clearable
          />
        </v-col>
        <v-col cols="6">
          <ATextField
            v-model="dog.notasBaja"
            label="Detalles del deceso"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col
        cols="12"
        align="right"
      >
        <APrimaryButton
          v-if="hasPermission(userProfile, 'UPDATE')"
          label="Guardar cambios"
          :loading="loading"
          :disabled="loading || !updateDogForm"
          @click="triggerUpdateDog()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ASelect from './atoms/ASelect.vue';
import ATextField from './atoms/ATextField.vue';
import APrimaryButton from './atoms/APrimaryButton.vue';
import ADateInput from './atoms/ADateInput.vue';
import AAutocomplete from './atoms/AAutocomplete.vue';

export default {
  components: {
    ATextField,
    ASelect,
    APrimaryButton,
    ADateInput,
    AAutocomplete,
  },

  data() {
    return {
      updateDogForm: false,
      loading: false,
      selectedDate: '',
      selectedDateIngreso: '',
      selectedDateBaja: '',
      selectedMainCustom: null,
    };
  },

  computed: {
    ...mapGetters('dog', {
      dog: 'getCurrentDog',
    }),

    ...mapGetters('race', {
      races: 'getRaces',
    }),

    ...mapGetters('color', {
      colors: 'getColors',
    }),

    ...mapGetters('gender', {
      genders: 'getGenders',
    }),

    ...mapGetters('entranceType', {
      entranceTypes: 'getEntranceTypes',
    }),

    ...mapGetters('unsuscribedType', {
      unsuscribedTypes: 'getUnsuscribedTypes',
    }),

    ...mapGetters('custom', {
      customs: 'getCustoms',
      mainCustoms: 'getMainCustoms',
    }),

    filteredCustoms() {
      if (this.selectedMainCustom) {
        return this.customs.filter(
          (custom) => custom.fk_idAduanaPrincipal === this.selectedMainCustom,
        );
      }
      return this.customs;
    },
  },

  methods: {
    ...mapActions('dog', ['updateDog']),
    ...mapMutations(['SET_SNACKBAR']),

    async triggerUpdateDog() {
      this.loading = true;

      this.dog.edad = this.getAge(this.dog.fechaNacimiento);

      this.dog.fechaBaja = this.dog.fechaBaja ?? '1900-01-01';

      if (this.dog.fk_idTipoBaja === 0) {
        this.dog.fk_idTipoBaja = null;
      }

      if (!this.dog.notasBaja) {
        this.dog.notasBaja = '';
      }

      try {
        await this.updateDog(this.dog);
        this.SET_SNACKBAR({
          show: true,
          color: 'success',
          message: 'Mascota editada correctamente.',
        });
      } catch (error) {
        this.SET_SNACKBAR({
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar editar la información de la mascota.',
        });
      }

      this.loading = false;
      // this.$router.go();
    },
  },
};
</script>
