import personalService from '@/services/personal.service';

export default {
  namespaced: true,

  state: {
    personal: [],
  },

  getters: {
    getPersonal(state) {
      return [...state.personal];
    },
  },

  mutations: {
    SET_PERSONAL(state, payload) {
      state.personal = payload;
    },
  },

  actions: {
    async searchPersonal({ commit }, employeeNumber) {
      const personal = await personalService.searchPersonal(employeeNumber);
      commit('SET_PERSONAL', personal);
      return personal;
    },
  },
};
