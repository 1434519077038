import http from '@/plugins/axios';

const endpoint = '/ComentariosMedicos';

const medicalCommentService = {
  getMedicalComments() {
    return http.get(`${endpoint}`)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  getMedicalCommentByMedicalId(dogId) {
    return http.get(`diagnosticosmedicos/${dogId}${endpoint}`)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  createMedicalComment(payload) {
    return http.post(endpoint, payload)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  updateMedicalComment(medicalCommentId, payload) {
    return http.put(`${endpoint}/${medicalCommentId}`, payload)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default medicalCommentService;
