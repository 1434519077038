import http from '@/services/http-handler.service';

const endpoint = '/tiposmovimiento';

export default {
  namespaced: true,

  state: {
    movementTypes: [],
  },

  getters: {
    getMovementTypes(state) {
      return state.movementTypes;
    },
  },

  mutations: {
    SET_MOVEMENT_TYPES(state, payload) {
      state.movementTypes = payload;
    },
  },

  actions: {
    async getMovementTypes({ commit }) {
      const movementTypes = await http.get(endpoint);
      commit('SET_MOVEMENT_TYPES', movementTypes);
      return movementTypes;
    },
  },
};
