<template>
  <v-app-bar
    app
    class="primary"
    flat
  >
    <v-img
      src="@/assets/img/logo-puebla.png"
      max-width="100"
      contain
    />
    <v-spacer />
    <ABreadcrumbs />
    <v-spacer />
    <v-btn
      v-if="hasPermission(userProfile, 'CATALOGS')"
      icon
      class="mr-4"
      rounded
      large
      to="/catalogos"
    >
      <v-icon>
        mdi-cog
      </v-icon>
    </v-btn>
    <v-btn
      icon
      class="mr-4"
      rounded
      large
      to="/mapa"
    >
      <v-icon>
        mdi-earth
      </v-icon>
    </v-btn>
    <OUserAppMenu />
  </v-app-bar>
</template>

<script>
import ABreadcrumbs from '@/components/atoms/ABreadcrumbs.vue';
import OUserAppMenu from '@/components/organisms/OUserAppMenu.vue';

export default {
  components: {
    ABreadcrumbs,
    OUserAppMenu,
  },
};
</script>
