import createAnimalType from '@/modules/animalType/application/createAnimalType';
import deleteAnimalType from '@/modules/animalType/application/deleteAnimalType';
import updateAnimalType from '@/modules/animalType/application/updateAnimalType';
import createAnimalTypeApiRepository from '@/modules/animalType/infrastructure/ApiAnimalTypeRepository';
import Vue from 'vue';
import getAnimalTypes from '../../modules/animalType/application/getAnimalTypes';

const repository = createAnimalTypeApiRepository();

export default {
  namespaced: true,

  state: {
    animalTypes: [],
    animalType: {
      id: null,
      nombre: '',
      activo: true,
    },
  },

  getters: {
    getAnimalTypes(state) {
      return state.animalTypes;
    },

    animalTypesSelectOptions(state) {
      return state.animalTypes.map(
        (animalType) => ({
          id: animalType.id,
          title: animalType.nombre,
        }),
      );
    },
  },

  mutations: {
    SET_ANIMAL_TYPES(state, payload) {
      Vue.set(state, 'animalTypes', payload);
    },
  },

  actions: {
    async getAnimalTypes({ commit }) {
      const animalTypes = await getAnimalTypes(repository);
      commit('SET_ANIMAL_TYPES', animalTypes);
    },

    async createAnimalType({ commit, state, dispatch }) {
      try {
        await createAnimalType(repository, state.animalType.nombre);
        dispatch('getAnimalTypes');

        commit('SET_SNACKBAR', {
          show: true,
          animalType: 'success',
          message: 'Registro agregado al catálogo.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          animalType: 'error',
          message: 'Ocurrió un error al intentar agregar el registro.',
        }, { root: true });
      }
    },

    async updateAnimalType({ commit, state, dispatch }) {
      try {
        await updateAnimalType(repository, state.animalType);
        dispatch('getAnimalTypes');

        commit('SET_SNACKBAR', {
          show: true,
          animalType: 'success',
          message: 'Registro actualizado.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          animalType: 'error',
          message: 'Ocurrió un error al intentar actualizar el registro.',
        }, { root: true });
      }
    },

    async deleteAnimalType({ commit, dispatch }, { id, title }) {
      try {
        await deleteAnimalType(repository, id);
        dispatch('getAnimalTypes');

        commit('SET_SNACKBAR', {
          show: true,
          animalType: 'success',
          message: `AnimalType ${title} eliminado.`,
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          animalType: 'error',
          message: 'Ocurrió un error al intentar eliminar el registro.',
        }, { root: true });
      }
    },
  },
};
