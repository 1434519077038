var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Apexchart',{attrs:{"width":"100%","type":"pie","options":{
    labels: ['Canino', 'Felino', 'Ave'],
    theme: {
      mode: 'dark',
      monochrome: {
        enabled: true,
        color: '#611230',
        shadeTo: 'light',
        shadeIntensity: 0.65
      },
    },
    chart:{
      background: 'transparent'
    },
  },"series":[44, 55, 12]}})
}
var staticRenderFns = []

export { render, staticRenderFns }