<template>
  <v-dialog
    v-model="createVaccineDialog"
    width="33%"
    overlay-opacity="0.9"
  >
    <template #activator="{on, attrs}">
      <APrimaryButton
        v-if="hasPermission(userProfile, 'CREATE')"
        label="Agregar vacuna"
        :loading="loading"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <ACard>
      <v-card-title class="justify-center mb-4">
        Registro de nueva vacuna
      </v-card-title>
      <v-card-text>
        <v-form v-model="createVaccineForm">
          <v-row>
            <v-col cols="12">
              <ATextField
                v-model="vaccine.descripcion"
                label="Descripción"
              />
            </v-col>
            <v-col cols="6">
              <ADateInput
                v-model="vaccine.fechaAplicacion"
                label="Fecha de aplicación"
              />
            </v-col>
            <v-col cols="6">
              <ADateInput
                v-model="vaccine.fechaProximaDosis"
                label="Fecha de próxima dosis"
              />
            </v-col>
            <v-col cols="6">
              <ASelect
                v-model="vaccine.fk_idEstatusVacuna"
                label="Estatus de vacuna"
                :items="vaccineStatusList"
                item-value="id"
                item-text="title"
              />
            </v-col>
            <v-col cols="6">
              <ADateInput
                v-model="vaccine.fechaProgramada"
                label="Fecha programada"
              />
            </v-col>
            <v-col cols="6">
              <v-file-input
                v-model="vaccine.adjunto"
                color="white"
                label="Archivo adjunto"
                filled
                rounded
                prepend-icon=""
                append-icon="mdi-file"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <ASecondaryButton
          label="Cancelar"
          @click="createVaccineDialog = false"
        />
        <APrimaryButton
          label="Guardar registro"
          :loading="loading"
          :disabled="loading || !createVaccineForm"
          @click="triggerCreateVaccine()"
        />
      </v-card-actions>
    </ACard>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ACard from '../atoms/ACard.vue';
import ADateInput from '../atoms/ADateInput.vue';
import APrimaryButton from '../atoms/APrimaryButton.vue';
import ASecondaryButton from '../atoms/ASecondaryButton.vue';
import ASelect from '../atoms/ASelect.vue';
import ATextField from '../atoms/ATextField.vue';

export default {
  components: {
    APrimaryButton,
    ACard,
    ATextField,
    ASelect,
    ASecondaryButton,
    ADateInput,
  },

  data() {
    return {
      createVaccineDialog: false,
      createVaccineForm: false,
      loading: false,
      vaccine: {
        fk_idCanino: null,
        descripcion: '',
        fk_idEstatusVacuna: null,
        fechaAplicacion: '',
        fechaProximaDosis: '',
        adjunto: null,
        fechaProgramada: '',
      },
    };
  },

  computed: {
    ...mapGetters('dog', {
      dog: 'getCurrentDog',
    }),
    ...mapGetters('vaccineStatus', {
      vaccineStatusList: 'getVaccineStatus',
    }),
  },

  methods: {
    ...mapActions('vaccine', ['createVaccine']),
    ...mapMutations(['SET_SNACKBAR']),

    async triggerCreateVaccine() {
      this.loading = true;

      try {
        this.vaccine.fechaAplicacion = this.vaccine.fechaAplicacion !== '' ? this.vaccine.fechaAplicacion : '1900-01-01';
        this.vaccine.fechaProximaDosis = this.vaccine.fechaProximaDosis !== '' ? this.vaccine.fechaProximaDosis : '1900-01-01';
        this.vaccine.fechaProgramada = this.vaccine.fechaProgramada !== '' ? this.vaccine.fechaProgramada : '1900-01-01';
        this.vaccine.fk_idCanino = this.dog.id;

        await this.createVaccine(this.vaccine);
        this.SET_SNACKBAR({
          show: true,
          color: 'success',
          message: 'Vacuna registrada correctamente.',
        });
      } catch (error) {
        this.SET_SNACKBAR({
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar registrar la vacuna.',
        });
      }

      this.vaccine = {
        fk_idCanino: null,
        descripcion: '',
        fk_idEstatusVacuna: null,
        fechaAplicacion: '',
        fechaProximaDosis: '',
        adjunto: null,
        fechaProgramada: null,
      };
      this.selectedDateAplicacion = '';
      this.selectedDateProximaDosis = '';
      this.selectedDateProgramada = '';
      this.loading = false;
      this.createVaccineDialog = false;
    },
  },
};
</script>
