import entranceTypeService from '@/services/entranceType.service';

export default {
  namespaced: true,

  state: {
    entranceTypes: [],
  },

  getters: {
    getEntranceTypes(state) {
      return state.entranceTypes;
    },
  },

  mutations: {
    SET_ENTRANCE_TYPES(state, payload) {
      state.entranceTypes = payload;
    },
  },

  actions: {
    async getEntranceTypes({ commit }) {
      const entranceTypes = await entranceTypeService.getEntranceTypes();
      commit('SET_ENTRANCE_TYPES', entranceTypes);
    },
  },
};
