export default class ResponsibleEntity {
  idCanino;

  id;

  nombre;

  paterno;

  materno;

  telefono;

  email;

  activo;

  constructor() {
    this.idCanino = null;
    this.id = null;
    this.nombre = '';
    this.paterno = '';
    this.materno = '';
    this.telefono = '';
    this.email = '';
    this.activo = true;
  }

  fromDto(responsible) {
    this.idCanino = responsible.id_canino;
    this.id = responsible.id;
    this.nombre = responsible.nombre;
    this.paterno = responsible.paterno;
    this.materno = responsible.materno;
    this.telefono = responsible.tel1;
    this.email = responsible.email;

    return this;
  }

  withCaninoId(idCanino) {
    this.idCanino = idCanino;

    return this;
  }
}
