<template>
  <v-container class="mt-4">
    <v-row>
      <v-col
        cols="12"
        align="right"
      >
        <OCreateCleanlinessDialog />
      </v-col>
      <v-col
        v-for="cleanliness, index in cleanlinessList"
        :key="index"
        cols="4"
      >
        <OCleanlinessCard :cleanliness="cleanliness" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import OCreateCleanlinessDialog from './organisms/OCreateCleanlinessDialog.vue';
import OCleanlinessCard from './organisms/OCleanlinessCard.vue';

export default {
  components: {
    OCreateCleanlinessDialog,
    OCleanlinessCard,
  },

  computed: {
    ...mapGetters('cleanliness', {
      cleanlinessList: 'getCleanliness',
    }),
  },
};
</script>
