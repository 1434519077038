<template>
  <v-dialog
    v-model="updateTreatmentDialog"
    width="33%"
    overlay-opacity="0.9"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #activator="{on, attrs}">
      <AIconButton
        v-if="hasPermission(userProfile, 'UPDATE')"
        icon="mdi-pencil"
        class="mx-1"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <ACard>
      <v-card-title class="justify-center mb-4">
        Edición de incidencia médica
      </v-card-title>
      <v-card-text>
        <v-form v-model="updateTreatmentForm">
          <v-row>
            <v-col cols="12">
              <div class="text-caption ml-6 mb-2">
                Descripción
              </div>
              <wysiwyg
                v-model="treatment.descripcion"
                class="white text--black black--text"
              />
            </v-col>
            <v-col cols="6">
              <ADateInput
                v-model="treatment.fechaInicio"
                label="Fecha de inicio"
              />
            </v-col>
            <v-col cols="6">
              <ADateInput
                v-model="treatment.fechaFin"
                label="Fecha de finalización"
                clearable
              />
            </v-col>
            <v-col cols="6">
              <ASelect
                v-model="treatment.fk_idCatEstatus"
                label="Estatus"
                :items="statusList"
                item-value="id"
                item-text="title"
              />
            </v-col>
            <v-col cols="6">
              <v-file-input
                v-model="treatment.adjunto"
                color="white"
                label="Archivo adjunto"
                filled
                rounded
                prepend-icon=""
                append-icon="mdi-file"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <ASecondaryButton
          label="Cancelar"
          @click="updateTreatmentDialog = false"
        />
        <APrimaryButton
          label="Guardar registro"
          :loading="loading"
          :disabled="loading || !updateTreatmentForm"
          @click="triggerUpdateTreatment()"
        />
      </v-card-actions>
    </ACard>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ACard from '../atoms/ACard.vue';
import AIconButton from '../atoms/AIconButton.vue';
import APrimaryButton from '../atoms/APrimaryButton.vue';
import ASecondaryButton from '../atoms/ASecondaryButton.vue';
import ASelect from '../atoms/ASelect.vue';
import ADateInput from '../atoms/ADateInput.vue';

export default {
  components: {
    APrimaryButton,
    ACard,
    ASelect,
    ASecondaryButton,
    AIconButton,
    ADateInput,
  },

  data() {
    return {
      updateTreatmentDialog: false,
      updateTreatmentForm: false,
      loading: false,
    };
  },

  computed: {
    ...mapGetters('dog', {
      dog: 'getCurrentDog',
    }),
    ...mapGetters('treatment', {
      treatment: 'getCurrentTreatment',
    }),
    ...mapGetters('status', {
      statusList: 'getStatus',
    }),
  },

  methods: {
    ...mapActions('treatment', ['updateTreatment']),
    ...mapMutations(['SET_SNACKBAR']),

    async triggerUpdateTreatment() {
      this.loading = true;

      this.treatment.fechaInicio = this.treatment.fechaInicio ? this.treatment.fechaInicio : '1900-01-01';
      this.treatment.fechaFin = this.treatment.fechaFin ? this.treatment.fechaFin : '1900-01-01';

      try {
        await this.updateTreatment(this.treatment);
        this.SET_SNACKBAR({
          show: true,
          color: 'success',
          message: 'Incidencia médica editada correctamente.',
        });
      } catch (error) {
        this.SET_SNACKBAR({
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar editar la incidencia médica.',
        });
      }

      this.loading = false;
      this.updateTreatmentDialog = false;
    },
  },
};
</script>
