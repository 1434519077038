<template>
  <Apexchart
    width="100%"
    type="pie"
    :options="{
      labels: ['Canino', 'Felino', 'Ave'],
      theme: {
        mode: 'dark',
        monochrome: {
          enabled: true,
          color: '#611230',
          shadeTo: 'light',
          shadeIntensity: 0.65
        },
      },
      chart:{
        background: 'transparent'
      },
    }"
    :series="[44, 55, 12]"
  />
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>

</style>
