import http from '@/plugins/axios';

const endpoint = '/asignaciones';

const assignmentService = {
  getAssignments() {
    return http.get(`${endpoint}`)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  getAssignmentByDogId(dogId) {
    return http.get(`/caninos/${dogId}${endpoint}`)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  createAssignment(payload) {
    return http.post(endpoint, payload)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  updateAssignment(assignmentId, payload) {
    return http.put(`${endpoint}/${assignmentId}`, payload)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default assignmentService;
