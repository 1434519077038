<template>
  <v-dialog
    v-model="updateCleanlinessDialog"
    width="33%"
    overlay-opacity="0.9"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #activator="{on, attrs}">
      <AIconButton
        v-if="hasPermission(userProfile, 'UPDATE')"
        icon="mdi-pencil"
        class="mx-1"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <ACard>
      <v-card-title class="justify-center mb-4">
        Edición de aseo
      </v-card-title>
      <v-card-text>
        <v-form v-model="updateCleanlinessForm">
          <v-row>
            <v-col cols="12">
              <div class="text-caption ml-6 mb-2">
                Descripción
              </div>
              <wysiwyg
                v-model="cleanliness.descripcion"
                class="white text--black black--text"
              />
            </v-col>
            <v-col cols="6">
              <ADateInput
                v-model="cleanliness.fechaInicio"
                label="Fecha de inicio"
              />
            </v-col>
            <v-col cols="6">
              <ADateInput
                v-model="cleanliness.fechaFin"
                label="Fecha de finalización"
                clearable
              />
            </v-col>
            <v-col cols="12">
              <ATextField
                v-model="cleanliness.comentarios"
                label="Comentarios"
              />
            </v-col>
            <v-col cols="6">
              <ASelect
                v-model="cleanliness.fk_idCatEstatus"
                label="Estatus"
                :items="statusList"
                item-value="id"
                item-text="title"
              />
            </v-col>
            <v-col cols="6">
              <v-file-input
                v-model="cleanliness.adjunto"
                color="white"
                label="Archivo adjunto"
                filled
                rounded
                prepend-icon=""
                append-icon="mdi-file"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <ASecondaryButton
          label="Cancelar"
          @click="updateCleanlinessDialog = false"
        />
        <APrimaryButton
          label="Guardar registro"
          :loading="loading"
          :disabled="loading || !updateCleanlinessForm"
          @click="triggerUpdateCleanliness()"
        />
      </v-card-actions>
    </ACard>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ACard from '../atoms/ACard.vue';
import AIconButton from '../atoms/AIconButton.vue';
import APrimaryButton from '../atoms/APrimaryButton.vue';
import ASecondaryButton from '../atoms/ASecondaryButton.vue';
import ASelect from '../atoms/ASelect.vue';
import ATextField from '../atoms/ATextField.vue';
import ADateInput from '../atoms/ADateInput.vue';

export default {
  components: {
    APrimaryButton,
    ACard,
    ATextField,
    ASelect,
    ASecondaryButton,
    AIconButton,
    ADateInput,
  },

  data() {
    return {
      updateCleanlinessDialog: false,
      updateCleanlinessForm: false,
      loading: false,
    };
  },

  computed: {
    ...mapGetters('dog', {
      dog: 'getCurrentDog',
    }),
    ...mapGetters('cleanliness', {
      cleanliness: 'getCurrentCleanliness',
    }),
    ...mapGetters('status', {
      statusList: 'getStatus',
    }),
  },

  methods: {
    ...mapActions('cleanliness', ['updateCleanliness']),
    ...mapMutations(['SET_SNACKBAR']),

    async triggerUpdateCleanliness() {
      this.loading = true;

      this.cleanliness.fechaInicio = this.cleanliness.fechaInicio !== '' ? this.cleanliness.fechaInicio : '1900-01-01';
      this.cleanliness.fechaFin = this.cleanliness.fechaFin !== '' ? this.cleanliness.fechaFin : '1900-01-01';

      try {
        await this.updateCleanliness(this.cleanliness);
        this.SET_SNACKBAR({
          show: true,
          color: 'success',
          message: 'Cleanlinessa editada correctamente.',
        });
      } catch (error) {
        this.SET_SNACKBAR({
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar editar la cleanlinessa.',
        });
      }

      this.loading = false;
      this.updateCleanlinessDialog = false;
    },
  },
};
</script>
