<template>
  <ACard>
    <v-card-title class="d-block text-center">
      <ODogCardHeaderSection :dog="currentDog" />
    </v-card-title>
    <v-card-text>
      <v-divider class="mt-4" />
      <OResponsibleCard :responsible="responsibles[0]" />
    </v-card-text>
  </ACard>
</template>

<script>
import { mapGetters } from 'vuex';
import ACard from '../atoms/ACard.vue';
import ODogCardHeaderSection from './ODogCardHeaderSection.vue';
import OResponsibleCard from './OResponsibleCard.vue';

export default {
  components: {
    ACard,
    ODogCardHeaderSection,
    OResponsibleCard,

  },

  computed: {
    ...mapGetters('dog', {
      currentDog: 'getCurrentDog',
    }),
    ...mapGetters('responsible', {
      responsibles: 'getResponsibles',
    }),
  },

  methods: {
    generateDogDataItems(dog) {
      return [
        {
          cols: '12',
          label: 'Tatuaje',
          value: dog.tatuaje,
        },
        {
          cols: '12',
          label: 'Número de microchip',
          value: dog.noMicrochip,
        },
        {
          cols: '12',
          label: 'Tag RFID',
          value: dog.folioInventario,
        },
      ];
    },
  },
};
</script>
