import catalogosApi from '@/plugins/catalogos.axios';

async function getVaccineStatus() {
  const { data } = await catalogosApi.get('/estatusvacunas/');
  return data.data;
}

async function createVaccineStatus(vaccineStatus) {
  const { data } = await catalogosApi.post('/estatusvacunas/', vaccineStatus);
  return data.data[0].id;
}

async function updateVaccineStatus(vaccineStatus) {
  await catalogosApi.put(`/estatusvacunas/${vaccineStatus.id}`, vaccineStatus);
}

async function deleteVaccineStatus(id) {
  return Promise.resolve(id);
}

export default function createApiVaccineStatusRepository() {
  return {
    getVaccineStatus,
    createVaccineStatus,
    updateVaccineStatus,
    deleteVaccineStatus,
  };
}
