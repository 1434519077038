<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <catalogos-tabs />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CatalogosTabs from '@/components/CatalogosTabs.vue';

export default {
  components: {
    CatalogosTabs,
  },
};
</script>
