import createColor from '@/modules/color/application/createColor';
import deleteColor from '@/modules/color/application/deleteColor';
import getColors from '@/modules/color/application/getColors';
import updateColor from '@/modules/color/application/updateColor';
import createColorApiRepository from '@/modules/color/infrastructure/ApiColorRepository';
import Vue from 'vue';

const repository = createColorApiRepository();

export default {
  namespaced: true,

  state: {
    colors: [],
    color: {
      id: null,
      title: '',
      activo: true,
    },
  },

  getters: {
    getColors(state) {
      return state.colors;
    },
  },

  mutations: {
    SET_COLORS(state, payload) {
      Vue.set(state, 'colors', payload);
    },
  },

  actions: {
    async getColors({ commit }) {
      const colors = await getColors(repository);
      commit('SET_COLORS', colors);
    },

    async createColor({ commit, state, dispatch }) {
      try {
        await createColor(repository, state.color.title);
        dispatch('getColors');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Color agregado al catálogo.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar agregar el color.',
        }, { root: true });
      }
    },

    async updateColor({ commit, state, dispatch }) {
      try {
        await updateColor(repository, state.color);
        dispatch('getColors');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Color actualizado.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar actualizar el color.',
        }, { root: true });
      }
    },

    async deleteColor({ commit, dispatch }, { id, title }) {
      try {
        await deleteColor(repository, id);
        dispatch('getColors');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: `Color ${title} eliminado.`,
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar eliminar el color.',
        }, { root: true });
      }
    },
  },
};
