import http from '@/services/http-handler.service';

const endpoint = '/almacenes';

export default {
  namespaced: true,

  state: {
    currentWarehouse: null,
    warehouses: [],
  },

  getters: {
    getCurrentWarehouse(state) {
      if (state.currentWarehouse) {
        return { ...state.currentWarehouse };
      }
      return null;
    },

    getWarehouses(state) {
      return state.warehouses;
    },
  },

  mutations: {
    SET_CURRENT_WAREHOUSE(state, payload) {
      state.currentWarehouse = payload;
    },

    SET_WAREHOUSES(state, payload) {
      state.warehouses = payload;
    },
  },

  actions: {
    async getWarehouses({ commit }) {
      const warehouses = await http.get(endpoint);
      commit('SET_WAREHOUSES', warehouses);
      return warehouses;
    },

    async getWarehouseByCustomId({ commit }, customId) {
      const warehouse = await http.get(`aduanas/${customId}${endpoint}`);
      commit('SET_CURRENT_WAREHOUSE', warehouse[0]);
    },

    async createWarehouse({ dispatch }, warehouse) {
      await http.create(endpoint, warehouse);
      dispatch('getWarehouses');
    },

    async updateWarehouse({ dispatch }, warehouse) {
      await http.update(`${endpoint}/${warehouse.id}`, warehouse);
      dispatch('getWarehouses');
    },
  },
};
