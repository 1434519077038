import Vue from 'vue';
import VueRouter from 'vue-router';
import { publicPath } from '../../vue.config';
import MainPanelView from '../views/MainPanelView.vue';
import DogRecordView from '../views/DogRecordView.vue';
import MapView from '../views/MapView.vue';
import NotFoundView from '../views/NotFoundView.vue';
import LoginView from '../views/LoginView.vue';
import CatalogosView from '../views/CatalogosView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/home',
    name: 'main-panel',
    component: MainPanelView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/dog-record/:id',
    name: 'dog-record',
    component: DogRecordView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/mapa',
    name: 'map-view',
    component: MapView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/catalogos',
    name: 'catalogos',
    component: CatalogosView,
    meta: {
      isPublic: false,
    },
  },
  {
    path: '*',
    component: NotFoundView,
    meta: {
      isPublic: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: publicPath,
  routes,
});

export default router;
