import ColorDialog from '@/components/dialog-content/ColorDialog.vue';
import DResponsibleDialog from './components/dialog-content/DResponsibleDialog.vue';
import RazaDialog from './components/dialog-content/RazaDialog.vue';
import EstatusDialog from './components/dialog-content/EstatusDialog.vue';
import EstatusMedicoDialog from './components/dialog-content/EstatusMedicoDialog.vue';
import VaccineStatusDialog from './components/dialog-content/VaccineStatusDialog.vue';
import GenderDialog from './components/dialog-content/GenderDialog.vue';
import DocumentTypeDialog from './components/dialog-content/DocumentTypeDialog.vue';
import AnimalTypeDialog from './components/dialog-content/AnimalTypeDialog.vue';
import TipoBajaDialog from './components/dialog-content/TipoBajaDialog.vue';
import UserDialog from './components/dialog-content/UserDialog.vue';

export default {
  DEFAULT: {
    title: '',
    component: null,
    width: '360',
  },

  CREATE_COLOR_DIALOG: {
    title: 'Registrar nuevo color',
    component: ColorDialog,
    width: '360',
  },

  UPDATE_COLOR_DIALOG: {
    title: 'Editar color',
    component: ColorDialog,
    width: '360',
  },

  RESPONSIBLE_DIALOG: {
    title: 'Registro de Nuevo Responsable',
    component: DResponsibleDialog,
    width: '500',
  },

  UPDATE_RESPONSIBLE_DIALOG: {
    title: 'Editar responsable',
    component: DResponsibleDialog,
    width: '500',
  },

  CREATE_RAZA_DIALOG: {
    title: 'Registrar nueva raza',
    component: RazaDialog,
    width: '360',
  },

  UPDATE_RAZA_DIALOG: {
    title: 'Editar raza',
    component: RazaDialog,
    width: '360',
  },

  CREATE_ESTATUS_DIALOG: {
    title: 'Registrar nuevo estatus',
    component: EstatusDialog,
    width: '360',
  },

  UPDATE_ESTATUS_DIALOG: {
    title: 'Editar estatus',
    component: EstatusDialog,
    width: '360',
  },

  CREATE_ESTATUS_MEDICO_DIALOG: {
    title: 'Registrar nuevo estatus médico',
    component: EstatusMedicoDialog,
    width: '360',
  },

  UPDATE_ESTATUS_MEDICO_DIALOG: {
    title: 'Editar estatus médico',
    component: EstatusMedicoDialog,
    width: '360',
  },

  CREATE_VACCINE_STATUS_DIALOG: {
    title: 'Registrar nuevo estatus de vacuna',
    component: VaccineStatusDialog,
    width: '360',
  },

  UPDATE_VACCINE_STATUS_DIALOG: {
    title: 'Editar estatus de vacuna',
    component: VaccineStatusDialog,
    width: '360',
  },

  CREATE_GENDER_DIALOG: {
    title: 'Registrar nuevo género',
    component: GenderDialog,
    width: '360',
  },

  UPDATE_GENDER_DIALOG: {
    title: 'Editar género',
    component: GenderDialog,
    width: '360',
  },

  CREATE_DOCUMENT_TYPE_DIALOG: {
    title: 'Registrar nuevo tipo de documento',
    component: DocumentTypeDialog,
    width: '360',
  },

  UPDATE_DOCUMENT_TYPE_DIALOG: {
    title: 'Editar tipo de documento',
    component: DocumentTypeDialog,
    width: '360',
  },

  CREATE_ANIMAL_TYPE_DIALOG: {
    title: 'Registrar nuevo tipo de animal',
    component: AnimalTypeDialog,
    width: '360',
  },

  UPDATE_ANIMAL_TYPE_DIALOG: {
    title: 'Editar tipo de animal',
    component: AnimalTypeDialog,
    width: '360',
  },

  CREATE_TIPO_BAJA_DIALOG: {
    title: 'Registrar nuevo tipo de baja',
    component: TipoBajaDialog,
    width: '360',
  },

  UPDATE_TIPO_BAJA_DIALOG: {
    title: 'Editar tipo de baja',
    component: TipoBajaDialog,
    width: '360',
  },

  CREATE_USER_DIALOG: {
    title: 'Registrar nuevo usuario',
    component: UserDialog,
    width: '360',
  },

  UPDATE_USER_DIALOG: {
    title: 'Editar usuario',
    component: UserDialog,
    width: '360',
  },
};
