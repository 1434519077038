import http from '@/services/http-handler.service';

const endpoint = '/movimientosalmacen';

export default {
  namespaced: true,

  state: {
    currentWarehouseMovement: null,
    warehouseMovements: [],
  },

  getters: {
    getCurrentWarehouseMovement(state) {
      if (state.currentWarehouseMovement) {
        return { ...state.currentWarehouseMovement };
      }
      return null;
    },

    getWarehouseMovements(state) {
      return state.warehouseMovements;
    },
  },

  mutations: {
    SET_CURRENT_WAREHOUSE_MOVEMENT(state, payload) {
      state.currentWarehouseMovement = payload;
    },

    SET_WAREHOUSE_MOVEMENTS(state, payload) {
      state.warehouseMovements = payload;
    },
  },

  actions: {
    async getWarehouseMovements({ commit }) {
      const warehouseMovements = await http.get(endpoint);
      commit('SET_WAREHOUSE_MOVEMENTS', warehouseMovements);
      return warehouseMovements;
    },

    async getWarehouseMovementByCustomId({ commit }, warehouseId) {
      const warehouseMovement = await http.get(`Almacenes/${warehouseId}${endpoint}`);
      commit('SET_CURRENT_WAREHOUSE_MOVEMENT', warehouseMovement[0]);
    },

    async createWarehouseMovement({ dispatch }, warehouseMovement) {
      await http.create(endpoint, warehouseMovement);
      dispatch('getWarehouseMovements', warehouseMovement.fk_idMainticketsAlertamiento);
    },

    async updateWarehouseMovement({ dispatch }, warehouseMovement) {
      await http.update(`${endpoint}/${warehouseMovement.id}`, warehouseMovement);
      dispatch('getWarehouseMovements', warehouseMovement.fk_idMainticketsAlertamiento);
    },
  },
};
