<template>
  <ACard color="transparent">
    <v-card-title class="d-block text-center">
      <v-avatar
        size="80"
        color="accent"
      >
        <v-icon size="32">
          mdi-file
        </v-icon>
      </v-avatar>
      <br>
      <AChip
        color="secondary"
      >
        {{ document.tipoDocumento.title }}
      </AChip>
    </v-card-title>
    <v-card-text>
      <ATextField
        :value="document.observaciones"
        readonly
        label="Observaciones"
        hide-details
        class="mb-2"
      />
      <ATextField
        :value="getReadableDate(document.fecha)"
        readonly
        label="Fecha del documento"
        hide-details
        class="mb-2"
      />
    </v-card-text>
    <v-card-actions class="justify-center">
      <AIconButton
        :disabled="document.urlAdjunto === ''"
        icon="mdi-file-download"
        class="mx-1"
        @click="downloadDocument(document.urlAdjunto)"
      />
      <OUpdateDocumentDialog @input="setDocument($event, document)" />
    </v-card-actions>
  </ACard>
</template>

<script>
import { mapMutations } from 'vuex';
import ACard from '../atoms/ACard.vue';
import AChip from '../atoms/AChip.vue';
import AIconButton from '../atoms/AIconButton.vue';
import ATextField from '../atoms/ATextField.vue';
import OUpdateDocumentDialog from './OUpdateDocumentDialog.vue';

export default {
  components: {
    ACard,
    ATextField,
    AIconButton,
    OUpdateDocumentDialog,
    AChip,
  },

  props: {
    document: {
      type: Object,
      required: true,
    },
  },

  methods: {
    ...mapMutations('document', ['UPDATE_CURRENT_DOCUMENT']),
    setDocument(event, document) {
      if (event === true) {
        this.UPDATE_CURRENT_DOCUMENT(document);
      }
    },
  },
};
</script>
