import helpers from '@/mixins/helpers';
import documentService from '@/services/document.service';

export default {
  namespaced: true,

  state: {
    currentDocument: null,
    documents: [],
  },

  getters: {
    getCurrentDocument(state) {
      return { ...state.currentDocument };
    },

    getDocuments(state) {
      return state.documents.sort(helpers.methods.sortDescHelper);
    },

    getPawDocument(state) {
      if (state.documents.length > 0) {
        const pawDocuments = state.documents.filter((doc) => doc.fk_idTipoDocumento === 1);
        const lastPawDocument = pawDocuments[pawDocuments.length - 1];
        return lastPawDocument;
      }
      return null;
    },

    getBarkDocument(state) {
      if (state.documents.length > 0) {
        const barkDocuments = state.documents.filter((doc) => doc.fk_idTipoDocumento === 2);
        const lastBarkDocument = barkDocuments[barkDocuments.length - 1];
        return lastBarkDocument;
      }
      return null;
    },
  },

  mutations: {
    SET_CURRENT_DOCUMENT(state, payload) {
      state.currentDocument = payload;
    },

    SET_DOCUMENTS(state, payload) {
      state.documents = payload;
    },

    ADD_DOCUMENT(state, payload) {
      state.documents.push(payload);
    },

    UPDATE_CURRENT_DOCUMENT(state, payload) {
      state.currentDocument = { ...payload };
    },
  },

  actions: {
    async getDocuments({ commit }) {
      const documents = await documentService.getDocuments();
      commit('SET_DOCUMENTS', documents);
    },

    async getDocumentByDogId({ commit }, documentId) {
      const document = await documentService.getDocumentByDogId(documentId);
      commit('SET_DOCUMENTS', document);
    },

    async createDocument({ dispatch }, document) {
      const documentFormData = new FormData();
      documentFormData.append('fk_idCanino', document.fk_idCanino);
      documentFormData.append('fk_idTipoDocumento', document.fk_idTipoDocumento);
      documentFormData.append('observaciones', document.observaciones);
      documentFormData.append('fecha', document.fecha);
      documentFormData.append('adjunto', document.adjunto);

      await documentService.createDocument(documentFormData);
      dispatch('getDocumentByDogId', document.fk_idCanino);
    },

    async updateDocument({ dispatch }, document) {
      const documentFormData = new FormData();
      documentFormData.append('fk_idCanino', document.fk_idCanino);
      documentFormData.append('fk_idTipoDocumento', document.fk_idTipoDocumento);
      documentFormData.append('observaciones', document.observaciones);
      documentFormData.append('fecha', document.fecha);
      documentFormData.append('adjunto', document.adjunto);

      await documentService.updateDocument(document.id, documentFormData);
      dispatch('getDocumentByDogId', document.fk_idCanino);
    },
  },
};
