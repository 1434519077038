<template>
  <v-container class="mt-4">
    <v-row>
      <v-col
        cols="12"
        align="right"
      >
        <a-primary-button
          v-if="hasPermission(userProfile, 'CREATE')"
          label="Nuevo Responsable"
          @click="SET_DIALOG(DIALOG_TYPES.RESPONSIBLE_DIALOG)"
        />
      </v-col>
      <v-col
        v-for="responsible, index in responsibles"
        :key="index"
        cols="4"
      >
        <OResponsibleCard
          :responsible="responsible"
          show-actions
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import DIALOG_TYPES from '@/dialog-types';
import APrimaryButton from './atoms/APrimaryButton.vue';
import OResponsibleCard from './organisms/OResponsibleCard.vue';

export default {
  components: {
    APrimaryButton,
    OResponsibleCard,
  },

  data() {
    return {
      DIALOG_TYPES,
    };
  },

  computed: {
    ...mapGetters('responsible', {
      responsibles: 'getResponsibles',
    }),
  },
};
</script>
