<template>
  <v-row justify="center">
    <v-col cols="auto">
      <v-avatar
        size="120"
        :color="(dog.urlFoto !== '' || !dog.urlFoto) && 'white'"
      >
        <v-img
          v-if="dog.urlFoto !== '' && dog.urlFoto"
          :src="dog.urlFoto"
        />
        <v-icon
          v-else
          color="primary"
          size="64"
        >
          mdi-dog
        </v-icon>
      </v-avatar>
      <div v-if="$route.name === 'dog-record'">
        <OUpdateDogPhotoDialog :dog="dog" />
      </div>
      <div class="text-h6">
        {{ dog.nombre }}
      </div>
      <div class="text-subtitle-1">
        {{ dog.catRaza.title }}
      </div>
      <div class="text-subtitle-2">
        Perro
      </div>
      <v-chip-group>
        <AChip
          :color="dog.fk_idTipoBaja ? 'error' : 'success'"
        >
          {{ dog.fk_idTipoBaja ? 'INACTIVO' : 'ACTIVO' }}
        </AChip>
        <AChip
          :color="dog.fk_idSexo === 2 ? 'blue' : 'pink'"
        >
          {{ dog.catSexo.title }}
          <v-icon
            small
            class="ml-1"
          >
            {{ dog.fk_idSexo === 2 ? 'mdi-gender-male' : 'mdi-gender-female' }}
          </v-icon>
        </AChip>
        <v-chip
          label
          small
          color="surface black--text"
        >
          {{ dog.catColor.title }}
        </v-chip>
        <AChip
          color="secondary"
        >
          {{ getAge(dog.fechaNacimiento) + ' años' }}
        </AChip>
      </v-chip-group>
      <ASecondaryButton
        label="Descargar expediente"
        small
        @click="downloadPdf()"
      />
    </v-col>
  </v-row>
</template>

<script>
import EventBus from '@/plugins/EventBus';
import { mapMutations } from 'vuex';
import AChip from '../atoms/AChip.vue';
import ASecondaryButton from '../atoms/ASecondaryButton.vue';
import OUpdateDogPhotoDialog from './OUpdateDogPhotoDialog.vue';

export default {
  components: {
    AChip,
    ASecondaryButton,
    OUpdateDogPhotoDialog,
  },

  props: {
    dog: {
      type: Object,
      required: true,
    },
  },

  methods: {
    ...mapMutations('dog', ['UPDATE_CURRENT_DOG']),

    downloadPdf() {
      if (this.$route.name === 'main-panel') {
        this.UPDATE_CURRENT_DOG(this.dog);
        console.log('Seteando dog');
      }
      EventBus.$emit('generate', true);
    },
  },
};
</script>
