<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <ATextField
          v-model="$store.state.race.raceForm.raza"
          label="Raza"
        />
      </v-col>
      <v-col cols="12">
        <ASelect
          v-model="$store.state.race.raceForm.idTipoAnimal"
          label="Tipo de animal"
          item-value="id"
          item-text="nombre"
          :items="animalTypes"
        />
      </v-col>
      <v-col
        v-if="!isNewRaza"
        cols="12"
      >
        <v-switch
          v-model="$store.state.race.raceForm.activo"
          label="Activo"
          color="secondary"
        />
      </v-col>
      <v-col
        cols="6"
        class="text-center"
      >
        <ASecondaryButton
          label="Cancelar"
          @click="closeDialog()"
        />
      </v-col>
      <v-col
        cols="6"
        class="text-center"
      >
        <APrimaryButton
          label="Guardar"
          @click="triggerSaveRaza()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RaceEntity from '@/modules/race/domain/RaceEntity';
import { mapActions, mapGetters } from 'vuex';
import APrimaryButton from '../atoms/APrimaryButton.vue';
import ASecondaryButton from '../atoms/ASecondaryButton.vue';
import ATextField from '../atoms/ATextField.vue';
import ASelect from '../atoms/ASelect.vue';

export default {
  components: {
    ATextField,
    APrimaryButton,
    ASecondaryButton,
    ASelect,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters('animalType', {
      animalTypes: 'animalTypesSelectOptions',
    }),

    isNewRaza() {
      return !this.$store.state.race.raceForm.id;
    },
  },

  methods: {
    ...mapActions('race', ['createRace', 'updateRace']),

    async triggerSaveRaza() {
      this.isLoading = true;

      if (this.isNewRaza) {
        await this.createRace();
      } else {
        await this.updateRace();
      }

      this.isLoading = false;
      this.closeDialog();
    },

    closeDialog() {
      this.CLOSE_DIALOG();
      this.$store.state.race.raceForm = new RaceEntity();
    },
  },
};
</script>
