<template>
  <v-dialog
    v-model="createCleanlinessDialog"
    width="50%"
    overlay-opacity="0.9"
  >
    <template #activator="{on, attrs}">
      <APrimaryButton
        v-if="hasPermission(userProfile, 'CREATE')"
        label="Agregar aseo"
        :loading="loading"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <ACard>
      <v-card-title class="justify-center mb-4">
        Registro de nueva aseo
      </v-card-title>
      <v-card-text>
        <v-form v-model="createCleanlinessForm">
          <v-row>
            <v-col cols="12">
              <div class="text-caption ml-6 mb-2">
                Descripción
              </div>
              <wysiwyg
                v-model="cleanliness.descripcion"
                class="white text--black black--text"
              />
            </v-col>
            <v-col cols="6">
              <ADateInput
                v-model="cleanliness.fechaInicio"
                label="Fecha de inicio"
              />
            </v-col>
            <v-col cols="6">
              <ADateInput
                v-model="cleanliness.fechaFin"
                label="Fecha de finalización"
              />
            </v-col>
            <v-col cols="6">
              <ASelect
                v-model="cleanliness.fk_idCatEstatus"
                label="Estatus"
                :items="statusList"
                item-value="id"
                item-text="title"
              />
            </v-col>
            <v-col cols="6">
              <v-file-input
                v-model="cleanliness.adjunto"
                color="white"
                label="Archivo adjunto"
                filled
                rounded
                prepend-icon=""
                append-icon="mdi-file"
              />
            </v-col>
            <v-col cols="12">
              <ATextField
                v-model="cleanliness.comentarios"
                label="Comentarios"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <ASecondaryButton
          label="Cancelar"
          @click="createCleanlinessDialog = false"
        />
        <APrimaryButton
          label="Guardar registro"
          :loading="loading"
          :disabled="loading || !createCleanlinessForm"
          @click="triggerCreateCleanliness()"
        />
      </v-card-actions>
    </ACard>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ACard from '../atoms/ACard.vue';
import ADateInput from '../atoms/ADateInput.vue';
import APrimaryButton from '../atoms/APrimaryButton.vue';
import ASecondaryButton from '../atoms/ASecondaryButton.vue';
import ASelect from '../atoms/ASelect.vue';
import ATextField from '../atoms/ATextField.vue';

export default {
  components: {
    APrimaryButton,
    ACard,
    ATextField,
    ASelect,
    ASecondaryButton,
    ADateInput,
  },

  data() {
    return {
      createCleanlinessDialog: false,
      createCleanlinessForm: false,
      loading: false,
      cleanliness: {
        fk_idCanino: null,
        descripcion: '',
        fechaInicio: '',
        fechaFin: '',
        adjunto: null,
        comentarios: '',
        fk_idCatEstatus: null,
      },
    };
  },

  computed: {
    ...mapGetters('dog', {
      dog: 'getCurrentDog',
    }),
    ...mapGetters('status', {
      statusList: 'getStatus',
    }),
  },

  methods: {
    ...mapActions('cleanliness', ['createCleanliness']),
    ...mapMutations(['SET_SNACKBAR']),

    async triggerCreateCleanliness() {
      this.loading = true;

      try {
        this.cleanliness.fechaInicio = this.cleanliness.fechaInicio !== '' ? this.cleanliness.fechaInicio : '1900-01-01';
        this.cleanliness.fechaFin = this.cleanliness.fechaFin !== '' ? this.cleanliness.fechaFin : '1900-01-01';
        this.cleanliness.fk_idCanino = this.dog.id;

        await this.createCleanliness(this.cleanliness);
        this.SET_SNACKBAR({
          show: true,
          color: 'success',
          message: 'Aseo registrado correctamente.',
        });
      } catch (error) {
        this.SET_SNACKBAR({
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar registrar el aseo.',
        });
      }

      this.cleanliness = {
        fk_idCanino: null,
        descripcion: '',
        fechaInicio: '',
        fechaFin: '',
        adjunto: null,
        comentarios: '',
        fk_idCatEstatus: null,
      };
      this.selectedDateStart = '';
      this.selectedDateEnd = '';
      this.loading = false;
      this.createCleanlinessDialog = false;
    },
  },
};
</script>
