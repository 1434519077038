import http from '@/plugins/axiosPersonalAnam';

const endpoint = '/EonCloud/EstadoFuerza/search/personal';

const personalService = {
  searchPersonal(employeeNumber) {
    return http.post(`${endpoint}`, {
      nombre: '',
      segundoNombre: '',
      apaterno: '',
      amaterno: '',
      curp: '',
      CUIP: employeeNumber,
    })
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default personalService;
