import helpers from '@/mixins/helpers';
import medicalDiagnosticService from '@/services/medicalDiagnostic.service';

export default {
  namespaced: true,

  state: {
    currentMedicalDiagnostic: null,
    medicalDiagnostics: [],
  },

  getters: {
    getCurrentMedicalDiagnostic(state) {
      return { ...state.currentMedicalDiagnostic };
    },

    getMedicalDiagnostics(state) {
      return state.medicalDiagnostics.sort(helpers.methods.sortDescHelper);
    },
  },

  mutations: {
    SET_CURRENT_MEDICAL_DIAGNOSTIC(state, payload) {
      state.currentMedicalDiagnostic = payload;
    },

    SET_MEDICAL_DIAGNOSTICS(state, payload) {
      state.medicalDiagnostics = payload;
    },

    ADD_MEDICAL_DIAGNOSTIC(state, payload) {
      state.medicalDiagnostics.push(payload);
    },

    UPDATE_MEDICAL_DIAGNOSTICS(state, payload) {
      state.medicalDiagnostics = [
        ...state.medicalDiagnostics.filter(
          (diagnostic) => diagnostic.id !== payload.id,
        ),
        payload,
      ];
    },

    UPDATE_CURRENT_MEDICAL_DIAGNOSTIC(state, payload) {
      state.currentMedicalDiagnostic = { ...payload };
    },
  },

  actions: {
    async getMedicalDiagnostics({ commit }) {
      const medicalDiagnostics = await medicalDiagnosticService.getMedicalDiagnostics();
      commit('SET_MEDICAL_DIAGNOSTICS', medicalDiagnostics);
    },

    async getMedicalDiagnosticByDogId({ commit }, medicalDiagnosticId) {
      const medicalDiagnostic = await medicalDiagnosticService
        .getMedicalDiagnosticByDogId(medicalDiagnosticId);
      commit('SET_MEDICAL_DIAGNOSTICS', medicalDiagnostic);
    },

    async createMedicalDiagnostic({ dispatch }, medicalDiagnostic) {
      const medicalDiagnosticFormData = new FormData();
      medicalDiagnosticFormData.append('fk_idCanino', medicalDiagnostic.fk_idCanino);
      medicalDiagnosticFormData.append('descripcion', medicalDiagnostic.descripcion);
      medicalDiagnosticFormData.append('fecha', medicalDiagnostic.fecha);
      medicalDiagnosticFormData.append('adjunto', medicalDiagnostic.adjunto);
      medicalDiagnosticFormData.append('examenes', medicalDiagnostic.examenes);
      medicalDiagnosticFormData.append('fk_idCatEstatusMedico', medicalDiagnostic.fk_idCatEstatusMedico);

      await medicalDiagnosticService
        .createMedicalDiagnostic(medicalDiagnosticFormData);
      dispatch('getMedicalDiagnosticByDogId', medicalDiagnostic.fk_idCanino);
    },

    async updateMedicalDiagnostic({ dispatch }, medicalDiagnostic) {
      const medicalDiagnosticFormData = new FormData();
      medicalDiagnosticFormData.append('fk_idCanino', medicalDiagnostic.fk_idCanino);
      medicalDiagnosticFormData.append('descripcion', medicalDiagnostic.descripcion);
      medicalDiagnosticFormData.append('fecha', medicalDiagnostic.fecha);
      medicalDiagnosticFormData.append('adjunto', medicalDiagnostic.adjunto);
      medicalDiagnosticFormData.append('examenes', medicalDiagnostic.examenes);
      medicalDiagnosticFormData.append('fk_idCatEstatusMedico', medicalDiagnostic.fk_idCatEstatusMedico);

      await medicalDiagnosticService.updateMedicalDiagnostic(
        medicalDiagnostic.id,
        medicalDiagnosticFormData,
      );
      dispatch('getMedicalDiagnosticByDogId', medicalDiagnostic.fk_idCanino);
    },
  },
};
