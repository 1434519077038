import catalogosApi from '@/plugins/catalogos.axios';

async function getAnimalTypes() {
  const { data } = await catalogosApi.get('/tipoanimal/');

  return data.data;
}

async function createAnimalType(animalType) {
  const { data } = await catalogosApi.post('/tipoanimal/', animalType);
  return data.data[0].id;
}

async function updateAnimalType(animalType) {
  await catalogosApi.put(`/tipoanimal/${animalType.id}`, animalType);
}

async function deleteAnimalType(id) {
  return Promise.resolve(id);
}

export default function createAnimalTypeApiRepository() {
  return {
    getAnimalTypes,
    createAnimalType,
    updateAnimalType,
    deleteAnimalType,
  };
}
