import catalogosApi from '@/plugins/catalogos.axios';

async function getStatus() {
  const { data } = await catalogosApi.get('');
  return data.data;
}
async function createStatus(status) {
  const { data } = await catalogosApi.post('', status);

  return data.data[0].id;
}

async function updateStatus(status) {
  await catalogosApi.put(`${status.id}`, status);
}

async function deleteStatus(id) {
  return Promise.resolve(id);
}

export default function createApiStatusRepository() {
  return {
    getStatus,
    createStatus,
    updateStatus,
    deleteStatus,
  };
}
