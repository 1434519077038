<template>
  <ACard>
    <v-tabs
      v-model="selectedTab"
      color="white"
      background-color="primary lighten-1"
      grow
    >
      <v-tab
        v-for="dogTab, index in dogTabs"
        :key="index"
      >
        {{ dogTab }}
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="selectedTab"
      class="transparent"
    >
      <v-tab-item>
        <DogGeneralInfoTabItemComponent />
      </v-tab-item>
      <v-tab-item>
        <DogDocumentsTabItemComponent />
      </v-tab-item>
      <v-tab-item>
        <DogDietTabItemComponent />
      </v-tab-item>
      <v-tab-item>
        <DogCleanlinessTabItemComponent />
      </v-tab-item>
      <v-tab-item>
        <DogMedicalTabItemComponent />
      </v-tab-item>
      <v-tab-item>
        <DogTreatmentsTabItemComponent />
      </v-tab-item>
      <v-tab-item>
        <DogAssignmentsTabItemComponent />
      </v-tab-item>
      <v-tab-item>
        <DogVaccinesTabItem />
      </v-tab-item>
      <v-tab-item>
        <DogTimelineTabItemComponent />
      </v-tab-item>
    </v-tabs-items>
  </ACard>
</template>

<script>
import DogDietTabItemComponent from './DogDietTabItemComponent.vue';
import DogGeneralInfoTabItemComponent from './DogGeneralInfoTabItemComponent.vue';
import DogCleanlinessTabItemComponent from './DogCleanlinessTabItemComponent.vue';
import DogMedicalTabItemComponent from './DogMedicalTabItemComponent.vue';
import DogTreatmentsTabItemComponent from './DogTreatmentsTabItemComponent.vue';
import ACard from './atoms/ACard.vue';
import DogDocumentsTabItemComponent from './DogDocumentsTabItemComponent.vue';
import DogAssignmentsTabItemComponent from './DogAssignmentsTabItemComponent.vue';
import DogTimelineTabItemComponent from './DogTimelineTabItemComponent.vue';
import DogVaccinesTabItem from './DogVaccinesTabItem.vue';

export default {
  components: {
    DogGeneralInfoTabItemComponent,
    DogDietTabItemComponent,
    DogCleanlinessTabItemComponent,
    DogMedicalTabItemComponent,
    DogTreatmentsTabItemComponent,
    ACard,
    DogDocumentsTabItemComponent,
    DogAssignmentsTabItemComponent,
    DogTimelineTabItemComponent,
    DogVaccinesTabItem,
  },

  data() {
    return {
      dogTabs: [
        'Información general',
        'Documentos',
        'Dieta',
        'Aseo',
        'Medicina',
        'Incidencias médicas',
        'Responsable',
        'Vacunas',
        'Línea del tiempo',
      ],
      selectedTab: 0,
    };
  },
};
</script>

<style>

</style>
