import createMedicalStatus from '@/modules/medicalStatus/application/createMedicalStatus';
import deleteMedicalStatus from '@/modules/medicalStatus/application/deleteMedicalStatus';
import updateMedicalStatus from '@/modules/medicalStatus/application/updateMedicalStatus';
import createApiMedicalStatusRepository from '@/modules/medicalStatus/infrastructure/ApiMedicalStatusRepository';
import getMedicalStatus from '@/modules/medicalStatus/application/getMedicalStatus';

const repository = createApiMedicalStatusRepository();

export default {
  namespaced: true,

  state: {
    medicalStatus: [],
    medicalStatusForm: {
      id: null,
      title: '',
      activo: true,
    },
  },

  getters: {
    getMedicalStatus(state) {
      return state.medicalStatus;
    },
  },

  mutations: {
    SET_STATUS(state, payload) {
      state.medicalStatus = payload;
    },
  },

  actions: {
    async getMedicalStatus({ commit }) {
      const medicalStatus = await getMedicalStatus(repository);
      commit('SET_STATUS', medicalStatus);
    },

    async createMedicalStatus({ commit, state, dispatch }) {
      try {
        await createMedicalStatus(repository, state.medicalStatusForm.title);
        dispatch('getMedicalStatus');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Registro agregado al catálogo.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar agregar el registro.',
        }, { root: true });
      }
    },

    async updateMedicalStatus({ commit, state, dispatch }) {
      try {
        await updateMedicalStatus(repository, state.medicalStatusForm);
        dispatch('getMedicalStatus');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Registro actualizado.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar actualizar el registro.',
        }, { root: true });
      }
    },

    async deleteMedicalStatus({ commit, dispatch }, { id }) {
      try {
        await deleteMedicalStatus(repository, id);
        dispatch('getMedicalStatus');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Registro eliminado.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar eliminar el registro.',
        }, { root: true });
      }
    },
  },
};
