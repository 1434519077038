<template>
  <section>
    <ATextField
      label="Filtrar"
      dense
      hide-details
      class="mb-4"
    />
    <v-virtual-scroll
      height="240"
      item-height="64"
      :items="dogs"
    >
      <template #default="{item}">
        <v-list-item dense>
          <v-list-item-avatar size="48">
            <v-img :src="item.urlFoto" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.nombre }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-virtual-scroll>
    <v-divider class="my-4" />
    <section class="text-center mb-2">
      <div class="text-subtitle-2">
        Mascotas por tipo de animal
      </div>
      <TipoAnimalPieChart />
    </section>
    <section class="text-center mb-2">
      <div class="text-subtitle-2">
        Mascotas por raza
      </div>
      <RazaTreemapChart />
    </section>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import ATextField from '../atoms/ATextField.vue';
import RazaTreemapChart from '../charts/RazaTreemapChart.vue';
import TipoAnimalPieChart from '../charts/TipoAnimalPieChart.vue';

export default {
  components: {
    ATextField,
    TipoAnimalPieChart,
    RazaTreemapChart,
  },

  computed: {
    ...mapGetters('dog', {
      dogs: 'getDogs',
    }),
  },
};
</script>

<style lang="scss" scoped>

</style>
