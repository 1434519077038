import createVaccineStatus from '@/modules/vaccineStatus/application/createVaccineStatus';
import deleteVaccineStatus from '@/modules/vaccineStatus/application/deleteVaccineStatus';
import updateVaccineStatus from '@/modules/vaccineStatus/application/updateVaccineStatus';
import createApiVaccineStatusRepository from '@/modules/vaccineStatus/infrastructure/ApiVaccineStatusRepository';
import getVaccineStatus from '@/modules/vaccineStatus/application/getVaccineStatus';

const repository = createApiVaccineStatusRepository();

export default {
  namespaced: true,

  state: {
    vaccineStatus: [],
    vaccineStatusForm: {
      id: null,
      title: '',
      activo: true,
    },
  },

  getters: {
    getVaccineStatus(state) {
      return state.vaccineStatus;
    },
  },

  mutations: {
    SET_VACCINE_STATUS(state, payload) {
      state.vaccineStatus = payload;
    },
  },

  actions: {
    async getVaccineStatus({ commit }) {
      const vaccineStatus = await getVaccineStatus(repository);
      commit('SET_VACCINE_STATUS', vaccineStatus);
    },

    async createVaccineStatus({ commit, state, dispatch }) {
      try {
        await createVaccineStatus(repository, state.vaccineStatusForm.title);
        dispatch('getVaccineStatus');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Registro agregado al catálogo.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar agregar el registro.',
        }, { root: true });
      }
    },

    async updateVaccineStatus({ commit, state, dispatch }) {
      try {
        await updateVaccineStatus(repository, state.vaccineStatusForm);
        dispatch('getVaccineStatus');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Registro actualizado.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar actualizar el registro.',
        }, { root: true });
      }
    },

    async deleteVaccineStatus({ commit, dispatch }, { id }) {
      try {
        await deleteVaccineStatus(repository, id);
        dispatch('getVaccineStatus');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Registro eliminado.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar eliminar el registro.',
        }, { root: true });
      }
    },
  },
};
