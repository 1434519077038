<template>
  <v-container>
    <v-row>
      <v-col
        v-for="dogDataItem, index in dogData"
        :key="index"
        :cols="dogDataItem.cols"
        class="pb-0"
      >
        <ATextField
          :label="dogDataItem.label"
          :value="dogDataItem.value"
          dense
          readonly
          hide-details
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ATextField from '../atoms/ATextField.vue';

export default {
  components: {
    ATextField,
  },

  props: {
    dogData: {
      type: Array,
      required: true,
    },
  },
};
</script>
