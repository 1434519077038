<template>
  <v-dialog
    v-model="active"
    width="50%"
    persistent
  >
    <template #activator="{ on, attrs }">
      <AIconButton
        icon="mdi-comment"
        v-bind="attrs"
        v-on="on"
        @click="getMedicalCommentByMedicalId(medicalId)"
      />
    </template>
    <ACard>
      <v-card-title>Comentarios médicos</v-card-title>
      <v-card-text>
        <v-list-item-group v-if="medicalComments.length > 0">
          <v-virtual-scroll
            :items="medicalComments"
            item-height="60"
            height="240"
            class="my-4"
          >
            <template #default="{ item }">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ item.comentario }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.fecha }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </v-list-item-group>
        <div
          v-else
          class="text-subtitle-1 my-16 text-center"
        >
          No hay comentarios registrados.
        </div>
        <v-textarea
          v-model="medicalComment.comentario"
          label="Comentario"
          color="white"
          row-height="4"
          hide-details
          no-resize
          filled
          rounded
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <ASecondaryButton
          label="Cerrar"
          @click="closeDialog()"
        />
        <APrimaryButton
          label="Guardar comentario"
          :loading="loading"
          :disabled="loading || medicalComment.comentario === ''"
          @click="triggerSaveComment()"
        />
      </v-card-actions>
    </ACard>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ACard from '../atoms/ACard.vue';
import AIconButton from '../atoms/AIconButton.vue';
import APrimaryButton from '../atoms/APrimaryButton.vue';
import ASecondaryButton from '../atoms/ASecondaryButton.vue';

export default {
  components: {
    AIconButton,
    ACard,
    APrimaryButton,
    ASecondaryButton,
  },

  props: {
    medicalId: {
      type: Number,
      default: 0,
      required: true,
    },
  },

  data() {
    return {
      active: false,
      loading: false,
      medicalComment: {
        fk_idMedico: null,
        comentario: '',
        fecha: null,
      },
    };
  },

  computed: {
    ...mapGetters('medicalComment', {
      medicalComments: 'getMedicalComments',
    }),
  },

  methods: {
    ...mapActions('medicalComment', ['getMedicalCommentByMedicalId', 'createMedicalComment']),
    ...mapMutations(['SET_SNACKBAR']),

    closeDialog() {
      this.medicalComment.comentario = '';
      this.active = false;
    },

    async triggerSaveComment() {
      this.loading = true;

      try {
        this.medicalComment.fk_idMedico = this.medicalId;
        this.medicalComment.fecha = moment().format('YYYY-MM-DD HH:mm:ss');
        await this.createMedicalComment(this.medicalComment);
        this.SET_SNACKBAR({
          show: true,
          color: 'success',
          message: 'Comentario registrado correctamente.',
        });
        this.medicalComment.comentario = '';
      } catch (error) {
        this.SET_SNACKBAR({
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar registrar el comentario.',
        });
      }
      this.loading = false;
    },
  },
};
</script>
