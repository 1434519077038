import http from '@/plugins/axios';

const endpoint = '/Resultadoscursos';

const courseResultService = {
  getCourseResults() {
    return http.get(`${endpoint}`)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default courseResultService;
