import http from '@/plugins/axios';

const endpoint = '/vacunas';

const vaccineService = {
  getVaccines() {
    return http.get(`${endpoint}`)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  getVaccineByDogId(dogId) {
    return http.get(`/caninos/${dogId}${endpoint}`)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  createVaccine(payload) {
    return http.post(endpoint, payload)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  updateVaccine(vaccineId, payload) {
    return http.put(`${endpoint}/${vaccineId}`, payload)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default vaccineService;
