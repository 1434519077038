import helpers from '@/mixins/helpers';
import dogService from '@/services/dog.service';

export default {
  namespaced: true,

  state: {
    currentDog: null,
    searchResults: [],
    dogs: [],
  },

  getters: {
    getCurrentDog(state) {
      if (state.currentDog) {
        return { ...state.currentDog };
      }
      return null;
    },

    getDogs(state) {
      return state.dogs;
    },
  },

  mutations: {
    SET_CURRENT_DOG(state, payload) {
      state.currentDog = payload;
    },

    SET_SEARCH_RESULTS(state, payload) {
      state.searchResults = payload;
    },

    SET_DOGS(state, payload) {
      const dogWithFormatedBajaDate = payload.map((dog) => {
        const clonedDog = { ...dog };
        clonedDog.fechaBaja = helpers.methods.mapValidEndDate(clonedDog.fechaBaja);

        return clonedDog;
      });
      state.dogs = dogWithFormatedBajaDate;
    },

    ADD_DOG(state, payload) {
      state.dogs.push(payload);
    },

    UPDATE_DOG(state, payload) {
      const indexToUpdate = state.dogs.findIndex((dog) => dog.id === payload.id);
      state.dogs[indexToUpdate] = { ...payload };
    },

    UPDATE_CURRENT_DOG(state, payload) {
      const clonedDog = { ...payload };
      clonedDog.fechaBaja = helpers.methods.mapValidEndDate(clonedDog.fechaBaja);

      state.currentDog = clonedDog;
    },
  },

  actions: {
    async getDogs({ commit }) {
      const dogs = await dogService.getDogs();
      commit('SET_DOGS', dogs);
      return dogs;
    },

    async getDogById({ commit }, dogId) {
      const dog = await dogService.getDogById(dogId);

      commit('UPDATE_CURRENT_DOG', dog);
    },

    async createDog({ dispatch }, dog) {
      const dogFormData = new FormData();
      dogFormData.append('nombre', dog.nombre);
      dogFormData.append('apellido', dog.apellido);
      dogFormData.append('foto', dog.foto);
      dogFormData.append('fk_idRaza', dog.fk_idRaza);
      dogFormData.append('fk_idColor', dog.fk_idColor);
      dogFormData.append('fk_idSexo', dog.fk_idSexo);
      dogFormData.append('fechaNacimiento', dog.fechaNacimiento);
      dogFormData.append('lugarNacimiento', dog.lugarNacimiento);
      dogFormData.append('edad', dog.edad);
      dogFormData.append('tatuaje', dog.tatuaje);
      dogFormData.append('noMicrochip', dog.noMicrochip);
      dogFormData.append('folioInventario', dog.folioInventario);
      dogFormData.append('propiedad', dog.propiedad);
      dogFormData.append('fk_idTipoIngreso', dog.fk_idTipoIngreso);
      dogFormData.append('notasIngreso', dog.notasIngreso);
      dogFormData.append('fechaIngreso', dog.fechaIngreso);
      dogFormData.append('validadoCentral', dog.validadoCentral);
      dogFormData.append('fk_idCatAduana', dog.fk_idCatAduana);

      await dogService.createDog(dogFormData).then((response) => {
        if (response.result === false) {
          throw response.error_message;
        }
        dispatch('getDogs');
      }).catch((err) => { throw err; });
    },

    async updateDog(_, dog) {
      const dogFormData = new FormData();
      dogFormData.append('nombre', dog.nombre);
      dogFormData.append('apellido', dog.apellido);
      dogFormData.append('fk_idRaza', dog.fk_idRaza);
      dogFormData.append('fk_idColor', dog.fk_idColor);
      dogFormData.append('fk_idSexo', dog.fk_idSexo);
      dogFormData.append('fechaNacimiento', dog.fechaNacimiento);
      dogFormData.append('lugarNacimiento', dog.lugarNacimiento);
      dogFormData.append('edad', dog.edad);
      dogFormData.append('tatuaje', dog.tatuaje);
      dogFormData.append('noMicrochip', dog.noMicrochip);
      dogFormData.append('folioInventario', dog.folioInventario);
      dogFormData.append('propiedad', dog.propiedad);
      dogFormData.append('fk_idTipoIngreso', dog.fk_idTipoIngreso);
      dogFormData.append('notasIngreso', dog.notasIngreso);
      dogFormData.append('fechaIngreso', dog.fechaIngreso);
      if (dog.fk_idTipoBaja) {
        dogFormData.append('fk_idTipoBaja', dog.fk_idTipoBaja);
      }
      if (dog.fk_idTipoBaja) {
        dogFormData.append('notasBaja', dog.notasBaja);
      }
      dogFormData.append('fechaBaja', dog.fechaBaja);
      dogFormData.append('validadoCentral', dog.validadoCentral);
      if (dog.fk_idCatAduana) {
        dogFormData.append('fk_idCatAduana', dog.fk_idCatAduana);
      }

      await dogService.updateDog(dogFormData, dog.id);
    },

    async updateDogPhoto(_, dog) {
      const dogFormData = new FormData();
      dogFormData.append('nombre', dog.nombre);
      dogFormData.append('apellido', dog.apellido);
      dogFormData.append('foto', dog.foto);
      dogFormData.append('fk_idRaza', dog.fk_idRaza);
      dogFormData.append('fk_idColor', dog.fk_idColor);
      dogFormData.append('fk_idSexo', dog.fk_idSexo);
      dogFormData.append('fechaNacimiento', dog.fechaNacimiento);
      dogFormData.append('lugarNacimiento', dog.lugarNacimiento);
      dogFormData.append('edad', dog.edad);
      dogFormData.append('tatuaje', dog.tatuaje);
      dogFormData.append('noMicrochip', dog.noMicrochip);
      dogFormData.append('folioInventario', dog.folioInventario);
      dogFormData.append('propiedad', dog.propiedad);
      dogFormData.append('fk_idTipoIngreso', dog.fk_idTipoIngreso);
      dogFormData.append('notasIngreso', dog.notasIngreso);
      dogFormData.append('fechaIngreso', dog.fechaIngreso);
      if (dog.fk_idTipoBaja) {
        dogFormData.append('fk_idTipoBaja', dog.fk_idTipoBaja);
      }
      if (dog.fk_idTipoBaja) {
        dogFormData.append('notasBaja', dog.notasBaja);
      }
      dogFormData.append('fechaBaja', dog.fechaBaja);
      dogFormData.append('validadoCentral', dog.validadoCentral);
      if (dog.fk_idCatAduana) {
        dogFormData.append('fk_idCatAduana', dog.fk_idCatAduana);
      }

      await dogService.updateDog(dogFormData, dog.id);
    },
  },
};
