import http from '@/plugins/axios';

const endpoint = '/tiposIngreso';

const entranceTypeService = {
  getEntranceTypes() {
    return http.get(`${endpoint}`)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default entranceTypeService;
