<template>
  <v-container>
    <v-row
      justify="space-between"
      align="center"
    >
      <v-col cols="4">
        <ATextField
          v-model="colorSearched"
          label="Buscar"
          hide-details
          dense
        />
      </v-col>
      <v-col cols="auto">
        <APrimaryButton
          label="Nuevo registro"
          @click="SET_DIALOG(DIALOG_TYPES.CREATE_COLOR_DIALOG)"
        />
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="colors"
          :search="colorSearched"
          class="transparent"
        >
          <template #[`item.actions`]="{ item }">
            <v-icon
              class="mx-2"
              @click="openEditColorDialog(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DIALOG_TYPES from '@/dialog-types';
import APrimaryButton from '../atoms/APrimaryButton.vue';
import ATextField from '../atoms/ATextField.vue';

export default {
  components: { APrimaryButton, ATextField },

  data() {
    return {
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Color', value: 'title' },
        { text: 'Activo', value: 'activo' },
        { text: 'Acciones', value: 'actions', align: 'end' },
      ],
      DIALOG_TYPES,
      isDeletingColor: false,
      colorSearched: '',
    };
  },

  computed: {
    ...mapGetters('color', {
      colors: 'getColors',
    }),
  },

  methods: {
    ...mapActions('color', ['deleteColor']),

    openEditColorDialog(color) {
      this.$store.state.color.color = { ...color };
      this.SET_DIALOG(DIALOG_TYPES.UPDATE_COLOR_DIALOG);
    },

    async triggerDeleteColor(color) {
      this.isDeletingColor = true;
      await this.deleteColor(color);
      this.isDeletingColor = false;
    },
  },
};
</script>
