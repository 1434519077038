import createRace from '@/modules/race/application/createRace';
import getRace from '@/modules/race/application/getRace';
import deleteRace from '@/modules/race/application/deleteRace';
import updateRace from '@/modules/race/application/updateRace';
import createRaceApiRepository from '@/modules/race/infrastructure/ApiRaceRepository';
import RaceEntity from '@/modules/race/domain/RaceEntity';

const repository = createRaceApiRepository();

export default {
  namespaced: true,

  state: {
    races: [],
    raceForm: new RaceEntity(),
  },

  getters: {
    getRaces(state) {
      return state.races;
    },
  },

  mutations: {
    SET_RACES(state, payload) {
      state.races = payload;
    },
  },

  actions: {
    async getRaces({ commit }) {
      const races = await getRace(repository);
      commit('SET_RACES', races);
    },

    async createRace({ commit, state, dispatch }) {
      try {
        await createRace(repository, state.raceForm);
        dispatch('getRaces');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Raza agregado al catalogo',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrio un error al intentar agregar la raza.',
        }, { root: true });
      }
    },

    async updateRace({ commit, state, dispatch }) {
      try {
        await updateRace(repository, state.raceForm);
        dispatch('getRaces');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Raza actualizadoa',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar actualizar la raza',
        }, { root: true });
      }
    },

    async deleteRace({ commit, dispatch }, { id }) {
      try {
        await deleteRace(repository, id);
        dispatch('getRaces');
        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Raza eliminada',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar eliminar la raza.',
        }, { root: true });
      }
    },
  },
};
