<template>
  <vue-html2pdf
    ref="mypdf"
    :enable-download="false"
    :preview-modal="true"
    :manual-pagination="true"
    pdf-content-width="100%"
    :html-to-pdf-options="options"
  >
    <section
      v-if="dog"
      slot="pdf-content"
    >
      <v-app>
        <v-main class="white pt-4">
          <v-container>
            <v-row>
              <v-col cols="auto">
                <img
                  src="@/assets/img/logo-puebla.png"
                  width="360"
                  alt="logo"
                >
              </v-col>
              <v-spacer />
              <v-col
                cols="auto"
                align="right"
              >
                <MPdfHeaderText />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col
                cols="12"
                align="right"
              >
                <div class="text-subtitle-2">
                  Puebla de Zaragoza, a {{ getFormatedNowDate }}.
                </div>
              </v-col>
              <v-col
                cols="12"
                align="center"
              >
                <div class="text-subtitle-1 font-weight-bold">
                  Hoja de Datos Generales de la mascota
                </div>
              </v-col>
              <v-col
                cols="12"
                align="center"
              >
                <v-card
                  light
                  outlined
                >
                  <v-card-title class="justify-center">
                    <img
                      v-if="photo"
                      :src="photo.src"
                      crossOrigin="anonymous"
                      alt="perro"
                      width="240"
                      height="240"
                    >
                    <div
                      v-else
                      class="text-subtitle-1 grey pa-16"
                    >
                      <v-icon size="48">
                        mdi-image-off
                      </v-icon>
                      <br>
                      Foto no registrada
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <MDogResumeCardDataSection :dog-data="generateDogDataItems()" />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-main>
      </v-app>
    </section>
  </vue-html2pdf>
</template>
<script>
import EventBus from '@/plugins/EventBus';
import VueHtml2pdf from 'vue-html2pdf';
import { mapGetters } from 'vuex';
import moment from 'moment';
import MDogResumeCardDataSection from '../molecules/MDogResumeCardDataSection.vue';
import MPdfHeaderText from '../molecules/MPdfHeaderText.vue';

export default {
  components: {
    VueHtml2pdf,
    MPdfHeaderText,
    MDogResumeCardDataSection,
  },

  data() {
    return {
      options: {
        filename: '',
        image: {
          type: 'jpeg',
          quality: 0.98,
        },
        html2canvas: {
          scale: 2,
          useCORS: true,
          allowTaint: false,
        },
        jsPDF: {
          unit: 'px',
          format: 'a4',
          orientation: 'portrait',
          hotfixes: ['px_scaling'],
        },
      },

      photo: null,
    };
  },

  computed: {
    ...mapGetters('dog', {
      dog: 'getCurrentDog',
    }),

    getFormatedNowDate() {
      const day = moment().lang('es').format('dddd');
      const dayText = moment().lang('es').format('DD');
      const month = moment().lang('es').format('MMMM');
      const year = moment().lang('es').format('YYYY');
      return `${day} ${dayText} de ${month} de ${year}`;
    },
  },

  created() {
    EventBus.$on('generate', () => {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = this.dog.urlFoto;
      this.photo = img;

      this.downloadPdf();
    });
  },

  methods: {
    generateDogDataItems() {
      const data = [
        {
          cols: '4',
          label: 'Nombre',
          value: this.dog?.nombre,
        },
        {
          cols: '4',
          label: 'Raza',
          value: this.dog?.catRaza.title,
        },
        {
          cols: '4',
          label: 'Estatus',
          value: this.dog?.catTipoBaja ? 'Inactivo' : 'Activo',
        },
        {
          cols: '4',
          label: 'Fecha de nacimiento',
          value: this.dog?.fechaNacimiento,
        },
        {
          cols: '4',
          label: 'Sexo',
          value: this.dog?.catSexo.title,
        },
        {
          cols: '4',
          label: 'Color',
          value: this.dog?.catColor.title,
        },
        {
          cols: '4',
          label: 'Tatuaje',
          value: this.dog?.tatuaje,
        },
        {
          cols: '4',
          label: 'Número de microchip',
          value: this.dog?.noMicrochip,
        },
        {
          cols: '4',
          label: 'Tag RFID',
          value: this.dog?.folioInventario,
        },
      ];

      if (this.dog?.catTipoBaja) {
        data.push(
          {
            cols: '6',
            label: 'Fecha de deceso',
            value: this.dog?.fechaBaja,
          },
          {
            cols: '6',
            label: 'Tipo de deceso',
            value: this.dog?.catTipoBaja.title,
          },
          {
            cols: '12',
            label: 'Detalle del deceso',
            value: this.dog?.notasBaja,
          },
        );
      }

      return data;
    },

    downloadPdf() {
      this.$refs.mypdf.generatePdf();
    },

  },
};
</script>
