<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-row no-gutters>
      <v-col
        cols="8"
        xl="10"
      >
        <v-img
          src="@/assets/img/dog-banner.jpg"
          class="full-page-height"
        />
      </v-col>
      <v-col
        cols="4"
        xl="2"
        class="primary pa-8 align-content-center"
      >
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="text-h4 white--text">
                Bienvenido
              </div>
              <div class="text-subtitle-1 secondary--text">
                Inicie sesión con su cuenta
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <ATextField
                v-model="username"
                label="Usuario"
              />
              <ATextField
                v-model="password"
                label="Contraseña"
                type="password"
              />
            </v-col>
            <v-col
              cols="12"
              class="text-right"
            >
              <APrimaryButton
                label="Ingresar"
                @click="triggerLogin()"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ATextField from '@/components/atoms/ATextField.vue';
import APrimaryButton from '@/components/atoms/APrimaryButton.vue';
import { mapActions } from 'vuex';

export default {
  components: { ATextField, APrimaryButton },

  data() {
    return {
      isLoading: false,
      username: '',
      password: '',
    };
  },

  methods: {
    ...mapActions(['login']),

    async triggerLogin() {
      this.isLoading = true;
      await this.login({ username: this.username, password: this.password });
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.full-page-height{
  height: 100vh;
}
</style>
