<template>
  <v-container class="mt-4">
    <v-row
      justify="center"
      class="custom-height"
    >
      <v-col
        cols="12"
        class="text-right"
      >
        <v-row class="justify-end align-center">
          <v-col cols="3">
            <ATextField
              v-model="initialDate"
              label="Fecha inicial"
              type="date"
              hide-details
              clearable
              @input="selectedDate = $event"
            />
          </v-col>
          <v-col cols="3">
            <ATextField
              v-model="finalDate"
              label="Fecha final"
              type="date"
              hide-details
              clearable
            />
          </v-col>
          <v-col cols="4">
            <ASelect
              v-model="filterTimeline"
              label="Filtrar por tipo de registro"
              :items="timelineItemTypes"
              hide-details
              multiple
              clearable
            />
          </v-col>
          <v-col cols="auto">
            <APrimaryButton
              label="Descargar"
              @click="downloadTimeline()"
            />
          </v-col>
        </v-row>
        <OTimelinePdf :sorted-dates="filteredSortedDates" />
      </v-col>
      <v-col
        cols="8"
      >
        <v-timeline>
          <v-timeline-item
            v-for="item, index in filteredSortedDates"
            :key="index"
            small
            color="secondary lighten-3"
            fill-dot
          >
            <v-card
              color="surface"
              light
            >
              <v-card-title class="d-block">
                <div class="text-caption">
                  {{ formatTimelineDate(item.fecha) }}
                </div>
                <div class="text-subtitle-1">
                  {{ item.tipo }}
                </div>
              </v-card-title>
              <v-card-text>
                <div
                  class="black--text"
                  v-html="item.text"
                />
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import EventBus from '@/plugins/EventBus';
import APrimaryButton from './atoms/APrimaryButton.vue';
import OTimelinePdf from './organisms/OTimelinePdf.vue';
import ASelect from './atoms/ASelect.vue';
import ATextField from './atoms/ATextField.vue';

export default {
  components: {
    APrimaryButton,
    OTimelinePdf,
    ASelect,
    ATextField,
  },

  data() {
    return {
      filterTimeline: [],
      timelineItemTypes: [
        'Dieta',
        'Aseo',
        'Diagnóstico médico',
        'Incidencia médica',
        'Responsable',
        'Vacuna',
      ],
      initialDate: null,
      finalDate: null,
    };
  },

  computed: {
    ...mapGetters('task', {
      tasks: 'getTasks',
    }),
    ...mapGetters('diet', {
      diets: 'getDiets',
    }),
    ...mapGetters('cleanliness', {
      cleanlinessList: 'getCleanliness',
    }),
    ...mapGetters('medicalDiagnostic', {
      medicalDiagnostics: 'getMedicalDiagnostics',
    }),
    ...mapGetters('treatment', {
      treatments: 'getTreatments',
    }),
    ...mapGetters('course', {
      courses: 'getCourses',
    }),
    ...mapGetters('decoration', {
      decorations: 'getDecorations',
    }),
    ...mapGetters('assignment', {
      assignments: 'getAssignments',
    }),
    ...mapGetters('vaccine', {
      vaccines: 'getVaccines',
    }),

    sortedDates() {
      const timelineItems = [];

      if (this.diets.length > 0) {
        this.diets.forEach((item) => {
          timelineItems.push({
            tipo: 'Dieta',
            text: `La mascota inició la dieta: ${item.descripcion}`,
            fecha: item.fechaInicio,
          });

          if (this.getYear(item.fechaFin) !== '1900') {
            timelineItems.push({
              tipo: 'Dieta',
              text: `La mascota finalizó la dieta: ${item.descripcion}`,
              fecha: item.fechaFin,
            });
          }
        });
      }

      if (this.cleanlinessList.length > 0) {
        this.cleanlinessList.forEach((item) => {
          timelineItems.push({
            tipo: 'Aseo',
            text: `Se realizó el aseo ${item.descripcion}`,
            fecha: item.fechaInicio,
          });

          if (this.getYear(item.fechaFin) !== '1900') {
            timelineItems.push({
              tipo: 'Aseo',
              text: `Se finalizó el aseo ${item.descripcion}`,
              fecha: item.fechaFin,
            });
          }
        });
      }

      if (this.medicalDiagnostics.length > 0) {
        this.medicalDiagnostics.forEach((item) => {
          timelineItems.push({
            tipo: 'Diagnóstico médico',
            text: `Se realizó el diagnóstico médico ${item.descripcion}`,
            fecha: item.fecha,
          });
        });
      }

      if (this.treatments.length > 0) {
        this.treatments.forEach((item) => {
          timelineItems.push({
            tipo: 'Incidencia médica',
            text: item.descripcion,
            fecha: item.fechaInicio,
          });

          if (this.getYear(item.fechaFin) !== '1900') {
            timelineItems.push({
              tipo: 'Incidencia médica',
              text: item.descripcion,
              fecha: item.fechaFin,
            });
          }
        });
      }

      if (this.assignments.length > 0) {
        this.assignments.forEach((item) => {
          timelineItems.push({
            tipo: 'Responsable',
            text: 'Se asignó la mascota: ',
            fecha: item.fechaInicio,
          });

          if (this.getYear(item.fechaFin) !== '1900') {
            timelineItems.push({
              tipo: 'Responsable',
              text: 'Se finalizó la asignación de la mascota: ',
              fecha: item.fechaFin,
            });
          }
        });
      }

      if (this.vaccines.length > 0) {
        this.vaccines.forEach((item) => {
          timelineItems.push({
            tipo: 'Vacuna',
            text: `Se aplicó vacuna ${item.descripcion} a la mascota`,
            fecha: item.fechaAplicacion,
          });
        });
      }

      const sortedTimelineItems = timelineItems.sort((a, b) => moment(b.fecha, 'MM/DD/YYYY H:mm:ss A').format('YYYYMMDD') - moment(a.fecha, 'MM/DD/YYYY H:mm:ss A').format('YYYYMMDD'));

      return sortedTimelineItems;
    },

    filteredSortedDatesByType() {
      if (this.filterTimeline.length > 0) {
        return this.sortedDates.filter((item) => this.filterTimeline.includes(item.tipo));
      }
      return this.sortedDates;
    },

    filteredSortedDates() {
      if (this.initialDate && this.finalDate) {
        return this.filteredSortedDatesByType.filter((item) => moment(item.fecha, 'MM/DD/YYYY H:mm:ss A').isBetween(moment(this.initialDate), moment(this.finalDate)));
      }
      return this.filteredSortedDatesByType.filter((item) => moment(item.fecha, 'MM/DD/YYYY H:mm:ss A').isBefore(moment()));
    },
  },

  methods: {
    downloadTimeline() {
      EventBus.$emit('generate-timeline', true);
    },
  },
};
</script>

<style scoped>
.custom-height{
  height: 80vh;
  overflow-y: scroll;
}
</style>
