import http from '@/services/http-handler.service';

const endpoint = '/subcategorias';

export default {
  namespaced: true,

  state: {
    subcategories: [],
  },

  getters: {
    getSubcategories(state) {
      return state.subcategories;
    },
  },

  mutations: {
    SET_SUBCATEGORIES(state, payload) {
      state.subcategories = payload;
    },
  },

  actions: {
    async getSubcategories({ commit }) {
      const subcategories = await http.get(endpoint);
      commit('SET_SUBCATEGORIES', subcategories);
      return subcategories;
    },

    async createSubcategory({ dispatch }, subcategory) {
      await http.create(endpoint, subcategory);
      dispatch('getSubcategories');
    },

    async updateSubcategory({ dispatch }, subcategory) {
      await http.update(`${endpoint}/${subcategory.id}`, subcategory);
      dispatch('getSubcategories');
    },
  },
};
