import helpers from '@/mixins/helpers';
import dietService from '@/services/diet.service';

export default {
  namespaced: true,

  state: {
    currentDiet: null,
    diets: [],
  },

  getters: {
    getCurrentDiet(state) {
      return { ...state.currentDiet };
    },

    getDiets(state) {
      return state.diets.sort(helpers.methods.sortDescHelper);
    },
  },

  mutations: {
    SET_CURRENT_DIET(state, payload) {
      state.currentDiet = payload;
    },

    SET_DIETS(state, payload) {
      state.diets = payload;
    },

    ADD_DIET(state, payload) {
      state.diets.push(payload);
    },

    UPDATE_DIETS(state, payload) {
      state.diets = [
        ...state.diets.filter((diet) => diet.id !== payload.id),
        payload,
      ];
    },

    UPDATE_CURRENT_DIET(state, payload) {
      state.currentDiet = { ...payload };
    },
  },

  actions: {
    async getDiets({ commit }) {
      const diets = await dietService.getDiets();
      commit('SET_DIETS', diets);
    },

    async getDietByDogId({ commit }, dietId) {
      const diet = await dietService.getDietByDogId(dietId);
      commit('SET_DIETS', diet);
    },

    async createDiet({ dispatch }, diet) {
      const dietFormData = new FormData();
      dietFormData.append('fk_idCanino', diet.fk_idCanino);
      dietFormData.append('descripcion', diet.descripcion);
      dietFormData.append('fechaInicio', diet.fechaInicio);
      dietFormData.append('fechaFin', diet.fechaFin);
      dietFormData.append('adjunto', diet.adjunto);
      dietFormData.append('comentarios', diet.comentarios);
      dietFormData.append('fk_idCatEstatus', diet.fk_idCatEstatus);

      await dietService.createDiet(dietFormData);
      dispatch('getDietByDogId', diet.fk_idCanino);
    },

    async updateDiet({ dispatch }, diet) {
      const dietFormData = new FormData();
      dietFormData.append('fk_idCanino', diet.fk_idCanino);
      dietFormData.append('descripcion', diet.descripcion);
      dietFormData.append('fechaInicio', diet.fechaInicio);
      dietFormData.append('fechaFin', diet.fechaFin);
      dietFormData.append('adjunto', diet.adjunto);
      dietFormData.append('comentarios', diet.comentarios);
      dietFormData.append('fk_idCatEstatus', diet.fk_idCatEstatus);

      await dietService.updateDiet(diet.id, dietFormData);
      dispatch('getDietByDogId', diet.fk_idCanino);
    },
  },
};
