<template>
  <ACard color="transparent">
    <v-card-title class="d-block text-center">
      <AChip
        :color="getStatusColor(cleanliness.fk_idCatEstatus)"
      >
        {{ cleanliness.catEstatus.title }}
      </AChip>
      <div
        align="left"
        v-html="cleanliness.descripcion"
      />
    </v-card-title>
    <v-card-text>
      <ATextField
        :value="getReadableDate(cleanliness.fechaInicio)"
        readonly
        label="Fecha de inicio"
        hide-details
        class="mb-2"
      />
      <ATextField
        :value="cleanliness.fechaFin !== '1/1/1900 12:00:00 AM'
          ? getReadableDate(cleanliness.fechaFin) : 'No registrada'"
        readonly
        label="Fecha de finalización"
        hide-details
        class="mb-2"
      />
      <ATextField
        :value="cleanliness.comentarios"
        readonly
        label="Comentarios"
        hide-details
        class="mb-2"
      />
    </v-card-text>
    <v-card-actions class="justify-center">
      <AIconButton
        :disabled="cleanliness.urlAdjunto === ''"
        icon="mdi-file-download"
        @click="downloadDocument(cleanliness.urlAdjunto)"
      />
      <OUpdateCleanlinessDialog @input="setCleanliness($event, cleanliness)" />
    </v-card-actions>
  </ACard>
</template>

<script>
import { mapMutations } from 'vuex';
import ACard from '../atoms/ACard.vue';
import AChip from '../atoms/AChip.vue';
import AIconButton from '../atoms/AIconButton.vue';
import ATextField from '../atoms/ATextField.vue';
import OUpdateCleanlinessDialog from './OUpdateCleanlinessDialog.vue';

export default {
  components: {
    ACard,
    AChip,
    ATextField,
    AIconButton,
    OUpdateCleanlinessDialog,
  },

  props: {
    cleanliness: {
      type: Object,
      required: true,
    },
  },

  methods: {
    ...mapMutations('cleanliness', ['UPDATE_CURRENT_CLEANLINESS']),
    setCleanliness(event, cleanliness) {
      if (event === true) {
        this.UPDATE_CURRENT_CLEANLINESS(cleanliness);
      }
    },
  },
};
</script>
