<template>
  <ACard color="transparent">
    <v-card-title class="d-block text-center">
      Datos del Responsable
    </v-card-title>
    <v-card-text>
      <ATextField
        :value="responsibleFullName"
        readonly
        hide-details
        class="mb-2"
      />
      <ATextField
        :value="responsible.telefono"
        readonly
        label="Telefono 1"
        hide-details
        class="mb-2"
      />
      <ATextField
        readonly
        label="Telefono 2 (opcional)"
        hide-details
        class="mb-2"
      />
      <ATextField
        :value="responsible.email"
        readonly
        label="Email"
        hide-details
        class="mb-2"
      />
    </v-card-text>
    <v-card-actions
      v-if="showActions"
      class="justify-center"
    >
      <AIconButton
        icon="mdi-pencil"
        @click="openUpdateResponsibleDialog()"
      />
    </v-card-actions>
  </ACard>
</template>

<script>
import DIALOG_TYPES from '@/dialog-types';
import ACard from '../atoms/ACard.vue';
import AIconButton from '../atoms/AIconButton.vue';
import ATextField from '../atoms/ATextField.vue';

export default {
  components: {
    ACard,
    ATextField,
    AIconButton,
  },

  props: {
    responsible: {
      type: Object,
      required: true,
    },

    showActions: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      DIALOG_TYPES,
    };
  },

  computed: {
    responsibleFullName() {
      return `${this.responsible.nombre} ${this.responsible.paterno} ${this.responsible.materno}`;
    },
  },

  methods: {
    openUpdateResponsibleDialog() {
      this.$store.state.responsible.responsibleForm = { ...this.responsible };
      this.SET_DIALOG(DIALOG_TYPES.UPDATE_RESPONSIBLE_DIALOG);
    },
  },
};

</script>
