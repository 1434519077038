import http from '@/plugins/axios';

const endpoint = '/diagnosticosmedicos';

const medicalDiagnosticService = {
  getMedicalDiagnostics() {
    return http.get(`${endpoint}`)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  getMedicalDiagnosticByDogId(dogId) {
    return http.get(`caninos/${dogId}${endpoint}`)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  createMedicalDiagnostic(payload) {
    return http.post(endpoint, payload)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  updateMedicalDiagnostic(medicalDiagnosticId, payload) {
    return http.put(`${endpoint}/${medicalDiagnosticId}`, payload)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default medicalDiagnosticService;
