import createStatus from '@/modules/status/application/createStatus';
import deleteStatus from '@/modules/status/application/deleteStatus';
import updateStatus from '@/modules/status/application/updateStatus';
import createApiStatusRepository from '@/modules/status/infrastructure/ApiStatusRepository';
import statusService from '@/services/status.service';

const repository = createApiStatusRepository();

export default {
  namespaced: true,

  state: {
    status: [],
    statusForm: {
      id: null,
      title: '',
    },
  },

  getters: {
    getStatus(state) {
      return state.status;
    },
  },

  mutations: {
    SET_STATUS(state, payload) {
      state.status = payload;
    },
  },

  actions: {
    async getStatus({ commit }) {
      const status = await statusService.getStatus();
      commit('SET_STATUS', status);
    },

    async createStatus({ commit, dispatch, state }) {
      try {
        await createStatus(repository, state.statusForm.title);
        dispatch('getStatus');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Registro agregado al catálogo.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar agregar el registro.',
        }, { root: true });
      }
    },

    async updateStatus({ commit, state, dispatch }) {
      try {
        await updateStatus(repository, state.statusForm);
        dispatch('getStatus');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Registro actualizado.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar actualizar el registro.',
        }, { root: true });
      }
    },

    async deleteStatus({ commit, dispatch }, { id }) {
      try {
        await deleteStatus(repository, id);
        dispatch('getStatus');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Registro eliminado.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar eliminar el registro.',
        }, { root: true });
      }
    },
  },
};
