<template>
  <v-container fill-height>
    <v-row justify="center">
      <v-col
        cols="12"
        align="center"
      >
        <div class="text-h2 primary--text mb-8">
          Página no encontrada
        </div>
        <APrimaryButton
          label="Ir a página principal"
          to="/"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import APrimaryButton from '@/components/atoms/APrimaryButton.vue';

export default {
  components: {
    APrimaryButton,
  },
};
</script>
