<template>
  <v-container class="mt-4">
    <v-row>
      <v-col
        cols="12"
        align="right"
      >
        <OCreateMedicalDialog />
      </v-col>
      <v-col
        v-for="medicalDiagnostic, index in medicalDiagnostics"
        :key="index"
        cols="4"
      >
        <OMedicalCard :medical-diagnostic="medicalDiagnostic" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import OCreateMedicalDialog from './organisms/OCreateMedicalDialog.vue';
import OMedicalCard from './organisms/OMedicalCard.vue';

export default {
  components: {
    OCreateMedicalDialog,
    OMedicalCard,
  },

  computed: {
    ...mapGetters('medicalDiagnostic', {
      medicalDiagnostics: 'getMedicalDiagnostics',
    }),
  },
};
</script>
