<template>
  <Apexchart
    type="treemap"
    width="100%"
    height="360"
    :options="{
      theme: {
        mode: 'dark',
        monochrome: {
          enabled: true,
          color: '#611230',
          shadeTo: 'light',
          shadeIntensity: 0.65
        },
      },
      chart:{
        background: 'transparent'
      },
    }"
    :series="series"
  />
</template>

<script>
export default {
  data() {
    return {
      series: [
        {
          data: [
            {
              x: 'Pastor Alemán',
              y: 218,
            },
            {
              x: 'French Poodle',
              y: 149,
            },
            {
              x: 'Beagle',
              y: 184,
            },
            {
              x: 'Labrador',
              y: 55,
            },
            {
              x: 'Chihuahua',
              y: 84,
            },
            {
              x: 'Pomeranian',
              y: 31,
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>

</style>
