import helpers from '@/mixins/helpers';
import decorationService from '@/services/decoration.service';

export default {
  namespaced: true,

  state: {
    currentDecoration: null,
    decorations: [],
  },

  getters: {
    getCurrentDecoration(state) {
      return { ...state.currentDecoration };
    },

    getDecorations(state) {
      return state.decorations.sort(helpers.methods.sortDescHelper);
    },
  },

  mutations: {
    SET_CURRENT_DECORATION(state, payload) {
      state.currentDecoration = payload;
    },

    SET_DECORATIONS(state, payload) {
      state.decorations = payload;
    },

    ADD_DECORATION(state, payload) {
      state.decorations.push(payload);
    },

    UPDATE_DECORATIONS(state, payload) {
      state.decorations = [
        ...state.decorations.filter(
          (decoration) => decoration.id !== payload.id,
        ),
        payload,
      ];
    },

    UPDATE_CURRENT_DECORATION(state, payload) {
      state.currentDecoration = { ...payload };
    },
  },

  actions: {
    async getDecorations({ commit }) {
      const decorations = await decorationService.getDecorations();
      commit('SET_DECORATIONS', decorations);
    },

    async getDecorationByDogId({ commit }, decorationId) {
      const decoration = await decorationService.getDecorationByDogId(decorationId);
      commit('SET_DECORATIONS', decoration);
    },

    async createDecoration({ dispatch }, decoration) {
      const decorationFormData = new FormData();
      decorationFormData.append('fk_idCanino', decoration.fk_idCanino);
      decorationFormData.append('fk_idCatTipoCondecoracion', decoration.fk_idCatTipoCondecoracion);
      decorationFormData.append('nombre', decoration.nombre);
      decorationFormData.append('descripcion', decoration.descripcion);
      decorationFormData.append('fecha', decoration.fecha);
      decorationFormData.append('adjunto', decoration.adjunto);
      decorationFormData.append('comentarios', decoration.comentarios);

      await decorationService.createDecoration(decorationFormData);
      dispatch('getDecorationByDogId', decoration.fk_idCanino);
    },

    async updateDecoration({ dispatch }, decoration) {
      const decorationFormData = new FormData();
      decorationFormData.append('fk_idCanino', decoration.fk_idCanino);
      decorationFormData.append('fk_idCatTipoCondecoracion', decoration.fk_idCatTipoCondecoracion);
      decorationFormData.append('nombre', decoration.nombre);
      decorationFormData.append('descripcion', decoration.descripcion);
      decorationFormData.append('fecha', decoration.fecha);
      decorationFormData.append('adjunto', decoration.adjunto);
      decorationFormData.append('comentarios', decoration.comentarios);

      await decorationService.updateDecoration(decoration.id, decorationFormData);
      dispatch('getDecorationByDogId', decoration.fk_idCanino);
    },
  },
};
