import http from '@/plugins/axios';

const endpoint = '/condecoraciones';

const decorationService = {
  getDecorations() {
    return http.get(`${endpoint}`)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  getDecorationByDogId(dogId) {
    return http.get(`/caninos/${dogId}${endpoint}`)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  createDecoration(payload) {
    return http.post(endpoint, payload)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  updateDecoration(decorationId, payload) {
    return http.put(`${endpoint}/${decorationId}`, payload)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default decorationService;
