import helpers from '@/mixins/helpers';
import vaccineService from '@/services/vaccine.service';

export default {
  namespaced: true,

  state: {
    currentVaccine: null,
    vaccines: [],
  },

  getters: {
    getCurrentVaccine(state) {
      return { ...state.currentVaccine };
    },

    getVaccines(state) {
      return state.vaccines.sort(helpers.methods.sortDescHelper);
    },
  },

  mutations: {
    SET_CURRENT_VACCINE(state, payload) {
      state.currentVaccine = payload;
    },

    SET_VACCINES(state, payload) {
      state.vaccines = payload;
    },

    ADD_VACCINE(state, payload) {
      state.vaccines.push(payload);
    },

    UPDATE_VACCINES(state, payload) {
      state.vaccines = [
        ...state.vaccines.filter(
          (vaccine) => vaccine.id !== payload.id,
        ),
        payload,
      ];
    },

    UPDATE_CURRENT_VACCINE(state, payload) {
      state.currentVaccine = { ...payload };
    },
  },

  actions: {
    async getVaccines({ commit }) {
      const vaccines = await vaccineService.getVaccines();
      commit('SET_VACCINES', vaccines);
    },

    async getVaccineByDogId({ commit }, vaccineId) {
      const vaccine = await vaccineService.getVaccineByDogId(vaccineId);
      commit('SET_VACCINES', vaccine);
    },

    async createVaccine({ dispatch }, vaccine) {
      const vaccineFormData = new FormData();
      vaccineFormData.append('fk_idCanino', vaccine.fk_idCanino);
      vaccineFormData.append('descripcion', vaccine.descripcion);
      vaccineFormData.append('fk_idEstatusVacuna', vaccine.fk_idEstatusVacuna);
      vaccineFormData.append('fechaAplicacion', vaccine.fechaAplicacion);
      vaccineFormData.append('fechaProximaDosis', vaccine.fechaProximaDosis);
      vaccineFormData.append('adjunto', vaccine.adjunto);
      vaccineFormData.append('fechaProgramada', vaccine.fechaProgramada);

      await vaccineService.createVaccine(vaccineFormData);
      dispatch('getVaccineByDogId', vaccine.fk_idCanino);
    },

    async updateVaccine({ dispatch }, vaccine) {
      const vaccineFormData = new FormData();
      vaccineFormData.append('fk_idCanino', vaccine.fk_idCanino);
      vaccineFormData.append('descripcion', vaccine.descripcion);
      vaccineFormData.append('fk_idEstatusVacuna', vaccine.fk_idEstatusVacuna);
      vaccineFormData.append('fechaAplicacion', vaccine.fechaAplicacion);
      vaccineFormData.append('fechaProximaDosis', vaccine.fechaProximaDosis);
      vaccineFormData.append('adjunto', vaccine.adjunto);
      vaccineFormData.append('fechaProgramada', vaccine.fechaProgramada);

      await vaccineService.updateVaccine(vaccine.id, vaccineFormData);
      dispatch('getVaccineByDogId', vaccine.fk_idCanino);
    },
  },
};
