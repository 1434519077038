import helpers from '@/mixins/helpers';
import treatmentMonitoringService from '@/services/treatment-monitoring.service';

export default {
  namespaced: true,

  state: {
    currentTreatmentMonitoring: null,
    treatmentMonitorings: [],
  },

  getters: {
    getCurrentTreatmentMonitoring(state) {
      return { ...state.currentTreatmentMonitoring };
    },

    getTreatmentMonitorings(state) {
      return state.treatmentMonitorings.sort(helpers.methods.sortDescHelper);
    },
  },

  mutations: {
    SET_CURRENT_TREATMENT_MONITORING(state, payload) {
      state.currentTreatmentMonitoring = payload;
    },

    SET_TREATMENT_MONITORINGS(state, payload) {
      state.treatmentMonitorings = payload;
    },

    ADD_TREATMENT_MONITORING(state, payload) {
      state.treatmentMonitorings.push(payload);
    },

    UPDATE_TREATMENT_MONITORINGS(state, payload) {
      state.treatmentMonitorings = [
        ...state.treatmentMonitorings.filter(
          (treatmentMonitoring) => treatmentMonitoring.id !== payload.id,
        ),
        payload,
      ];
    },

    UPDATE_CURRENT_TREATMENT_MONITORING(state, payload) {
      state.currentTreatmentMonitoring = { ...payload };
    },
  },

  actions: {
    async getTreatmentMonitorings({ commit }) {
      const treatmentMonitorings = await treatmentMonitoringService.getTreatmentMonitorings();
      commit('SET_TREATMENT_MONITORINGS', treatmentMonitorings);
    },

    async getTreatmentMonitoringByTreatmentId({ commit }, treatmentId) {
      const treatmentMonitorings = await treatmentMonitoringService
        .getTreatmentMonitoringByTreatmentId(treatmentId);
      commit('SET_TREATMENT_MONITORINGS', treatmentMonitorings);
    },

    async createTreatmentMonitoring({ dispatch }, treatmentMonitoring) {
      const treatmentMonitoringFormData = new FormData();
      treatmentMonitoringFormData.append('fk_idTratamiento', treatmentMonitoring.fk_idTratamiento);
      treatmentMonitoringFormData.append('descripcion', treatmentMonitoring.descripcion);
      treatmentMonitoringFormData.append('fecha', treatmentMonitoring.fecha);
      treatmentMonitoringFormData.append('adjunto', treatmentMonitoring.adjunto);

      await treatmentMonitoringService.createTreatmentMonitoring(treatmentMonitoringFormData);
      dispatch('getTreatmentMonitoringByTreatmentId', treatmentMonitoring.fk_idTratamiento);
    },

    async updateTreatmentMonitoring({ dispatch }, treatmentMonitoring) {
      const treatmentMonitoringFormData = new FormData();
      treatmentMonitoringFormData.append('fk_idTratamiento', treatmentMonitoring.fk_idTratamiento);
      treatmentMonitoringFormData.append('descripcion', treatmentMonitoring.descripcion);
      treatmentMonitoringFormData.append('fecha', treatmentMonitoring.fecha);
      treatmentMonitoringFormData.append('adjunto', treatmentMonitoring.adjunto);

      await treatmentMonitoringService
        .updateTreatmentMonitoring(treatmentMonitoring.id, treatmentMonitoringFormData);
      dispatch('getTreatmentMonitoringByTreatmentId', treatmentMonitoring.fk_idTratamiento);
    },
  },
};
