import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/lib/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        primary: '#611230',
        secondary: '#B48E5D',
        accent: '#002F2B',
        error: '#FF9078',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        surface: '#F2EADF',
      },
    },
    dark: true,
  },
  lang: {
    locales: { es },
    current: 'es',
  },
});
