<template>
  <section>
    <ASelect
      label="Municipio"
      dense
      hide-details
      class="mb-4"
    />
    <ASelect
      label="Colonia"
      dense
      hide-details
      class="mb-4"
    />
    <ASelect
      label="Tipo de animal"
      dense
      hide-details
      class="mb-4"
    />
    <ASelect
      label="Raza"
      dense
      hide-details
      class="mb-4"
    />
    <APrimaryButton label="Buscar" />
  </section>
</template>

<script>
import APrimaryButton from '../atoms/APrimaryButton.vue';
import ASelect from '../atoms/ASelect.vue';

export default {
  components: { ASelect, APrimaryButton },
};
</script>
