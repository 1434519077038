import courseStatusService from '@/services/courseStatus.service';

export default {
  namespaced: true,

  state: {
    courseStatus: [],
  },

  getters: {
    getCourseStatus(state) {
      return state.courseStatus;
    },
  },

  mutations: {
    SET_COURSE_STATUS(state, payload) {
      state.courseStatus = payload;
    },
  },

  actions: {
    async getCourseStatus({ commit }) {
      const courseStatus = await courseStatusService.getCourseStatus();
      commit('SET_COURSE_STATUS', courseStatus);
    },
  },
};
