<template>
  <v-container class="mt-4">
    <v-row>
      <v-col
        cols="12"
        align="right"
      >
        <OCreateVaccineDialog />
      </v-col>
      <v-col
        v-for="vaccine, index in vaccines"
        :key="index"
        cols="4"
      >
        <OVaccineCard :vaccine="vaccine" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import OCreateVaccineDialog from './organisms/OCreateVaccineDialog.vue';
import OVaccineCard from './organisms/OVaccineCard.vue';

export default {
  components: {
    OCreateVaccineDialog,
    OVaccineCard,
  },

  computed: {
    ...mapGetters('vaccine', {
      vaccines: 'getVaccines',
    }),
  },
};
</script>
