<template>
  <v-chip
    class="white--text"
    label
    small
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-chip>
</template>

<script>
export default {

};
</script>

<style>

</style>
