import Vue from 'vue';
import AudioVisual from 'vue-audio-visual';
import Vuelidate from 'vuelidate';
import wysiwyg from 'vue-wysiwyg';
import VueApexCharts from 'vue-apexcharts';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import helpers from './mixins/helpers';
import 'vue-wysiwyg/dist/vueWysiwyg.css';
import dialogMixin from './mixins/dialogMixin';

Vue.config.productionTip = false;

Vue.use(AudioVisual);
Vue.use(Vuelidate);
Vue.use(VueApexCharts);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Apexchart', VueApexCharts);
Vue.mixin(helpers);
Vue.mixin(dialogMixin);
Vue.use(wysiwyg, {
  hideModules: {
    image: true,
    table: true,
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
