import catalogosApi from '@/plugins/catalogos.axios';

async function getResponsibles() {
  const { data } = await catalogosApi.get('/responsable/');

  return data.data;
}

async function createResponsible(responsible) {
  const { data } = await catalogosApi.post('/responsable', responsible);
  return data.data[0].id;
}

async function updateResponsible(responsible) {
  await catalogosApi.put(`/responsable/${responsible.id}`, responsible);
}

export default function createApiResponsibleRepository() {
  return {
    getResponsibles,
    createResponsible,
    updateResponsible,
  };
}
