<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <ATextField
          v-model="$store.state.responsible.responsibleForm.nombre"
          label="Nombre"
        />
      </v-col>
      <v-col cols="12">
        <ATextField
          v-model="$store.state.responsible.responsibleForm.paterno"
          label="Apellido Paterno"
        />
      </v-col>
      <v-col cols="12">
        <ATextField
          v-model="$store.state.responsible.responsibleForm.materno"
          label="Apellido Materno"
        />
      </v-col>
      <v-col cols="6">
        <ATextField
          v-model="$store.state.responsible.responsibleForm.telefono"
          label="Telefono"
        />
      </v-col>
      <v-col cols="6">
        <ATextField
          label="Telefono 2 (Opcional)"
        />
      </v-col>
      <v-col cols="12">
        <ATextField
          v-model="$store.state.responsible.responsibleForm.email"
          label="Email"
        />
      </v-col>
      <v-col
        v-if="!isNewResponsible"
        cols="12"
      >
        <v-switch
          v-model="$store.state.responsible.responsibleForm.activo"
          label="Activo"
          color="secondary"
        />
      </v-col>
      <v-col cols="6">
        <ASecondaryButton
          label="Cancelar"
          @click="closeDialog()"
        />
      </v-col>
      <v-col cols="6">
        <APrimaryButton
          label="Guardar"
          :loading="isLoading"
          :disabled="isLoading"
          @click="saveResponsible()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ResponsibleEntity from '@/modules/responsible/domain/ResponsibleEntity';
import { mapActions, mapGetters } from 'vuex';
import APrimaryButton from '../atoms/APrimaryButton.vue';
import ASecondaryButton from '../atoms/ASecondaryButton.vue';
import ATextField from '../atoms/ATextField.vue';

export default {
  components: {
    APrimaryButton,
    ATextField,
    ASecondaryButton,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters('dog', {
      dog: 'getCurrentDog',
    }),

    isNewResponsible() {
      return !this.$store.state.responsible.responsibleForm.id;
    },
  },

  methods: {
    ...mapActions('responsible', ['createResponsible', 'updateResponsible']),

    closeDialog() {
      this.isLoading = false;
      this.CLOSE_DIALOG();
      this.$store.state.responsible.responsibleForm = new ResponsibleEntity()
        .withCaninoId(this.dog.id);
    },

    async saveResponsible() {
      this.isLoading = true;

      if (this.isNewResponsible) {
        await this.createResponsible();
      } else {
        await this.updateResponsible();
      }

      this.closeDialog();
    },
  },

};
</script>
