<template>
  <ACard color="transparent">
    <v-card-title class="d-block text-center">
      <AChip
        :color="getStatusColor(medicalDiagnostic.fk_idCatEstatusMedico)"
      >
        {{ medicalDiagnostic.catEstatusMedico.title }}
      </AChip>
      <div
        align="left"
        v-html="medicalDiagnostic.descripcion"
      />
    </v-card-title>
    <v-card-text>
      <ATextField
        :value="getReadableDate(medicalDiagnostic.fecha)"
        readonly
        label="Fecha"
        hide-details
        class="mb-2"
      />
      <ATextField
        :value="medicalDiagnostic.examenes"
        readonly
        label="Exámenes"
        hide-details
        class="mb-2"
      />
    </v-card-text>
    <v-card-actions class="justify-center">
      <AIconButton
        :disabled="medicalDiagnostic.urlAdjunto === ''"
        icon="mdi-file-download"
        @click="downloadDocument(medicalDiagnostic.urlAdjunto)"
      />
      <OUpdateMedicalDialog @input="setDiagnostic($event, medicalDiagnostic)" />
      <OMedicalCommentsDialog :medical-id="medicalDiagnostic.id" />
    </v-card-actions>
  </ACard>
</template>

<script>
import { mapMutations } from 'vuex';
import ACard from '../atoms/ACard.vue';
import AChip from '../atoms/AChip.vue';
import AIconButton from '../atoms/AIconButton.vue';
import ATextField from '../atoms/ATextField.vue';
import OMedicalCommentsDialog from './OMedicalCommentsDialog.vue';
import OUpdateMedicalDialog from './OUpdateMedicalDialog.vue';

export default {
  components: {
    ACard,
    AChip,
    ATextField,
    AIconButton,
    OUpdateMedicalDialog,
    OMedicalCommentsDialog,
  },

  props: {
    medicalDiagnostic: {
      type: Object,
      required: true,
    },
  },

  methods: {
    ...mapMutations('medicalDiagnostic', ['UPDATE_CURRENT_MEDICAL_DIAGNOSTIC']),
    setDiagnostic(event, diagnostic) {
      if (event === true) {
        this.UPDATE_CURRENT_MEDICAL_DIAGNOSTIC(diagnostic);
      }
    },
  },
};
</script>
