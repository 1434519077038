import catalogosApi from '@/plugins/catalogos.axios';

async function getTipoBaja() {
  const { data } = await catalogosApi.get('/tiposbaja/');
  return data.data;
}

async function createTipoBaja(tipoBaja) {
  const { data } = await catalogosApi.post('/tiposbaja/', tipoBaja);
  return data.data[0].id;
}

async function updateTipoBaja(tipoBaja) {
  await catalogosApi.put(`/tiposbaja/${tipoBaja.id}`, tipoBaja);
}

async function deleteTipoBaja(id) {
  return Promise.resolve(id);
}

export default function createApiTipoBajaRepository() {
  return {
    getTipoBaja,
    createTipoBaja,
    updateTipoBaja,
    deleteTipoBaja,
  };
}
