import http from '@/plugins/axios';

const endpoint = '/tareas';

const taskService = {
  getTasks() {
    return http.get(`${endpoint}`)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  getTaskByDogId(dogId) {
    return http.get(`/caninos/${dogId}${endpoint}`)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  createTask(payload) {
    return http.post(endpoint, payload)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  updateTask(taskId, payload) {
    return http.put(`${endpoint}/${taskId}`, payload)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default taskService;
