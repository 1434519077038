<template>
  <v-dialog
    v-model="createDogDialog"
    width="50%"
    overlay-opacity="0.9"
  >
    <template #activator="{on, attrs}">
      <APrimaryButton
        v-if="hasPermission(userProfile, 'CREATE')"
        label="Agregar nueva mascota"
        :loading="loading"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <ACard>
      <v-card-title class="justify-center mb-4">
        Registro de nueva mascota
      </v-card-title>
      <v-card-text>
        <v-form v-model="createDogForm">
          <v-row>
            <v-col cols="4">
              <v-file-input
                v-model="dog.foto"
                color="white"
                label="Foto del la mascota"
                filled
                rounded
                prepend-icon=""
                append-icon="mdi-file"
              />
            </v-col>
            <v-col cols="4">
              <ATextField
                v-model="dog.nombre"
                label="Nombre o alias"
              />
            </v-col>
            <v-col cols="4">
              <ASelect
                v-model="dog.fk_idRaza"
                label="Raza"
                :items="races"
              />
            </v-col>
            <v-col cols="6">
              <AAutocomplete
                v-model="selectedMainCustom"
                label="Municipio"
                :items="mainCustoms"
              />
            </v-col>
            <v-col cols="6">
              <AAutocomplete
                v-model="dog.fk_idCatAduana"
                label="Colonia"
                :items="filteredCustoms"
              />
            </v-col>
            <v-col cols="6">
              <ASelect
                v-model="dog.fk_idColor"
                label="Color"
                :items="colors"
              />
            </v-col>
            <v-col cols="6">
              <ASelect
                v-model="dog.fk_idSexo"
                label="Sexo"
                :items="genders"
              />
            </v-col>
            <v-col
              cols="6"
            >
              <ADateInput
                v-model="dog.fechaNacimiento"
                label="Fecha de nacimiento"
              />
            </v-col>
            <v-col cols="6">
              <ATextField
                v-model="dog.propiedad"
                label="Edad"
              />
            </v-col>
            <v-col cols="8">
              <ATextField
                v-model="dog.lugarNacimiento"
                label="Lugar de nacimiento"
              />
            </v-col>
            <v-col cols="4">
              <ATextField
                v-model="dog.tatuaje"
                label="Tatuaje *"
                :rules="[required]"
              />
            </v-col>
            <v-col cols="6">
              <ATextField
                v-model="dog.noMicrochip"
                label="Número de microchip *"
                :rules="[required]"
              />
            </v-col>
            <v-col cols="6">
              <ATextField
                v-model="dog.folioInventario"
                label="Tag RFID *"
                :rules="[required]"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <ASecondaryButton
          label="Cancelar"
          @click="createDogDialog = false"
        />
        <APrimaryButton
          label="Guardar registro"
          :loading="loading"
          :disabled="loading || !createDogForm"
          @click="triggerCreateDog()"
        />
      </v-card-actions>
    </ACard>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ACard from '../atoms/ACard.vue';
import ADateInput from '../atoms/ADateInput.vue';
import APrimaryButton from '../atoms/APrimaryButton.vue';
import ASecondaryButton from '../atoms/ASecondaryButton.vue';
import ASelect from '../atoms/ASelect.vue';
import ATextField from '../atoms/ATextField.vue';
import AAutocomplete from '../atoms/AAutocomplete.vue';

export default {
  components: {
    APrimaryButton,
    ACard,
    ATextField,
    ASelect,
    ASecondaryButton,
    ADateInput,
    AAutocomplete,
  },

  data() {
    return {
      createDogDialog: false,
      createDogForm: false,
      loading: false,
      dog: {
        nombre: '',
        foto: null,
        apellido: '',
        fk_idRaza: null,
        fk_idColor: null,
        fk_idSexo: null,
        fechaNacimiento: '',
        lugarNacimiento: '',
        edad: '',
        tatuaje: '',
        noMicrochip: '',
        folioInventario: '',
        propiedad: '',
        fk_idTipoIngreso: null,
        notasIngreso: '',
        fechaIngreso: '',
        fk_idTipoBaja: null,
        notasBaja: '',
        fechaBaja: null,
        validadoCentral: false,
        fk_idCatAduana: null,
      },
      selectedMainCustom: null,
    };
  },

  computed: {
    ...mapGetters('race', {
      races: 'getRaces',
    }),

    ...mapGetters('color', {
      colors: 'getColors',
    }),

    ...mapGetters('gender', {
      genders: 'getGenders',
    }),

    ...mapGetters('entranceType', {
      entranceTypes: 'getEntranceTypes',
    }),

    ...mapGetters('unsuscribedType', {
      unsuscribedTypes: 'getUnsuscribedTypes',
    }),

    ...mapGetters('custom', {
      customs: 'getCustoms',
      mainCustoms: 'getMainCustoms',
    }),

    filteredCustoms() {
      if (this.selectedMainCustom) {
        return this.customs.filter(
          (custom) => custom.fk_idAduanaPrincipal === this.selectedMainCustom,
        );
      }
      return this.customs;
    },
  },

  methods: {
    ...mapActions('dog', ['createDog']),
    ...mapMutations(['SET_SNACKBAR']),

    async triggerCreateDog() {
      this.loading = true;

      this.dog.edad = this.getAge(this.dog.fechaNacimiento);

      try {
        await this.createDog(this.dog);
        this.SET_SNACKBAR({
          show: true,
          color: 'success',
          message: 'Macota registrada correctamente.',
        });
      } catch (error) {
        this.SET_SNACKBAR({
          show: true,
          color: 'error',
          message: error.name ? 'Ocurrió un error al registrar a la mascota.' : error,
        });
      }

      this.dog = {
        nombre: '',
        foto: null,
        apellido: '',
        fk_idRaza: null,
        fk_idColor: null,
        fk_idSexo: null,
        fechaNacimiento: '',
        lugarNacimiento: '',
        edad: '',
        tatuaje: '',
        noMicrochip: '',
        folioInventario: '',
        propiedad: '',
        fk_idTipoIngreso: null,
        notasIngreso: '',
        fechaIngreso: '',
        fk_idTipoBaja: null,
        notasBaja: '',
        fechaBaja: null,
        validadoCentral: false,
        fk_idCatAduana: null,
      };
      this.loading = false;
      this.createDogDialog = false;
    },
  },
};
</script>
