<template>
  <v-dialog
    v-model="createMedicalDialog"
    width="50%"
    overlay-opacity="0.9"
  >
    <template #activator="{on, attrs}">
      <APrimaryButton
        v-if="hasPermission(userProfile, 'CREATE')"
        label="Agregar diagnóstico medico"
        :loading="loading"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <ACard>
      <v-card-title class="justify-center mb-4">
        Registro de nuevo diagnóstico médico
      </v-card-title>
      <v-card-text>
        <v-form v-model="createMedicalForm">
          <v-row>
            <v-col cols="12">
              <div class="text-caption ml-6 mb-2">
                Descripción
              </div>
              <wysiwyg
                v-model="medical.descripcion"
                class="white text--black black--text"
              />
            </v-col>
            <v-col cols="4">
              <ADateInput
                v-model="medical.fecha"
                label="Fecha"
              />
            </v-col>
            <v-col cols="4">
              <ASelect
                v-model="medical.fk_idCatEstatusMedico"
                label="Estatus médico"
                :items="medicalStatusList"
                item-value="id"
                item-text="title"
              />
            </v-col>
            <v-col cols="4">
              <v-file-input
                v-model="medical.adjunto"
                color="white"
                label="Archivo adjunto"
                filled
                rounded
                prepend-icon=""
                append-icon="mdi-file"
              />
            </v-col>
            <v-col cols="12">
              <ATextField
                v-model="medical.examenes"
                label="Exámenes"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <ASecondaryButton
          label="Cancelar"
          @click="createMedicalDialog = false"
        />
        <APrimaryButton
          label="Guardar registro"
          :loading="loading"
          :disabled="loading || !createMedicalForm"
          @click="triggerCreateMedicalDiagnostic()"
        />
      </v-card-actions>
    </ACard>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ACard from '../atoms/ACard.vue';
import ADateInput from '../atoms/ADateInput.vue';
import APrimaryButton from '../atoms/APrimaryButton.vue';
import ASecondaryButton from '../atoms/ASecondaryButton.vue';
import ASelect from '../atoms/ASelect.vue';
import ATextField from '../atoms/ATextField.vue';

export default {
  components: {
    APrimaryButton,
    ACard,
    ATextField,
    ASelect,
    ASecondaryButton,
    ADateInput,
  },

  data() {
    return {
      createMedicalDialog: false,
      createMedicalForm: false,
      loading: false,
      medical: {
        fk_idCanino: null,
        descripcion: '',
        fecha: '',
        adjunto: null,
        examenes: '',
        fk_idCatEstatusMedico: null,
      },
    };
  },

  computed: {
    ...mapGetters('dog', {
      dog: 'getCurrentDog',
    }),
    ...mapGetters('medicalStatus', {
      medicalStatusList: 'getMedicalStatus',
    }),
  },

  methods: {
    ...mapActions('medicalDiagnostic', ['createMedicalDiagnostic']),
    ...mapMutations(['SET_SNACKBAR']),

    async triggerCreateMedicalDiagnostic() {
      this.loading = true;

      try {
        this.medical.fecha = this.medical.fecha !== '' ? this.medical.fecha : '1900-01-01';
        this.medical.fk_idCanino = this.dog.id;

        await this.createMedicalDiagnostic(this.medical);
        this.SET_SNACKBAR({
          show: true,
          color: 'success',
          message: 'Diagnóstico registrada correctamente.',
        });
      } catch (error) {
        this.SET_SNACKBAR({
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar registrar el diagnóstico.',
        });
      }

      this.medical = {
        fk_idCanino: null,
        descripcion: '',
        fecha: '',
        adjunto: null,
        examenes: '',
        fk_idCatEstatusMedico: null,
      };
      this.selectedDate = '';
      this.loading = false;
      this.createMedicalDialog = false;
    },
  },
};
</script>
