<template>
  <v-dialog
    v-model="updateMedicalDiagnosticDialog"
    width="33%"
    overlay-opacity="0.9"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #activator="{on, attrs}">
      <AIconButton
        v-if="hasPermission(userProfile, 'UPDATE')"
        icon="mdi-pencil"
        class="mx-1"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <ACard>
      <v-card-title class="justify-center mb-4">
        Edición de diagnóstico médico
      </v-card-title>
      <v-card-text>
        <v-form v-model="updateMedicalDiagnosticForm">
          <v-row>
            <v-col cols="12">
              <div class="text-caption ml-6 mb-2">
                Descripción
              </div>
              <wysiwyg
                v-model="medicalDiagnostic.descripcion"
                class="white text--black black--text"
              />
            </v-col>
            <v-col cols="6">
              <ADateInput
                v-model="medicalDiagnostic.fecha"
                label="Fecha"
              />
            </v-col>
            <v-col cols="12">
              <ATextField
                v-model="medicalDiagnostic.examenes"
                label="Exámenes"
              />
            </v-col>
            <v-col cols="6">
              <ASelect
                v-model="medicalDiagnostic.fk_idCatEstatusMedico"
                label="Estatus médico"
                :items="medicalStatusList"
                item-value="id"
                item-text="title"
              />
            </v-col>
            <v-col cols="6">
              <v-file-input
                v-model="medicalDiagnostic.adjunto"
                color="white"
                label="Archivo adjunto"
                filled
                rounded
                prepend-icon=""
                append-icon="mdi-file"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <ASecondaryButton
          label="Cancelar"
          @click="updateMedicalDiagnosticDialog = false"
        />
        <APrimaryButton
          label="Guardar registro"
          :loading="loading"
          :disabled="loading || !updateMedicalDiagnosticForm"
          @click="triggerUpdateMedicalDiagnostic()"
        />
      </v-card-actions>
    </ACard>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ACard from '../atoms/ACard.vue';
import AIconButton from '../atoms/AIconButton.vue';
import APrimaryButton from '../atoms/APrimaryButton.vue';
import ASecondaryButton from '../atoms/ASecondaryButton.vue';
import ASelect from '../atoms/ASelect.vue';
import ATextField from '../atoms/ATextField.vue';
import ADateInput from '../atoms/ADateInput.vue';

export default {
  components: {
    APrimaryButton,
    ACard,
    ATextField,
    ASelect,
    ASecondaryButton,
    AIconButton,
    ADateInput,
  },

  data() {
    return {
      updateMedicalDiagnosticDialog: false,
      updateMedicalDiagnosticForm: false,
      loading: false,
      selectedDate: '',
    };
  },

  computed: {
    ...mapGetters('dog', {
      dog: 'getCurrentDog',
    }),
    ...mapGetters('medicalDiagnostic', {
      medicalDiagnostic: 'getCurrentMedicalDiagnostic',
    }),
    ...mapGetters('medicalStatus', {
      medicalStatusList: 'getMedicalStatus',
    }),
  },

  methods: {
    ...mapActions('medicalDiagnostic', ['updateMedicalDiagnostic']),
    ...mapMutations(['SET_SNACKBAR']),

    async triggerUpdateMedicalDiagnostic() {
      this.loading = true;

      this.medicalDiagnostic.fecha = this.medicalDiagnostic.fecha !== '' ? this.medicalDiagnostic.fecha : '1900-01-01';

      try {
        await this.updateMedicalDiagnostic(this.medicalDiagnostic);
        this.SET_SNACKBAR({
          show: true,
          color: 'success',
          message: 'Diagnóstico médico editado correctamente.',
        });
      } catch (error) {
        this.SET_SNACKBAR({
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar editar el diagnóstico médico.',
        });
      }

      this.loading = false;
      this.updateMedicalDiagnosticDialog = false;
    },
  },
};
</script>
