<template>
  <v-container class="mt-4">
    <v-row>
      <v-col
        cols="12"
        align="right"
      >
        <OCreateDocumentDialog />
      </v-col>
      <v-col
        v-for="document, index in documents"
        :key="index"
        cols="4"
      >
        <ODocumentCard :document="document" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import OCreateDocumentDialog from './organisms/OCreateDocumentDialog.vue';
import ODocumentCard from './organisms/ODocumentCard.vue';

export default {
  components: {
    OCreateDocumentDialog,
    ODocumentCard,
  },

  computed: {
    ...mapGetters('document', {
      documents: 'getDocuments',
    }),
  },
};
</script>
