import http from '@/services/http-handler.service';

export default {
  namespaced: true,

  state: {
    decorationTypes: [],
  },

  getters: {
    getDecorationTypes(state) {
      return state.decorationTypes;
    },
  },

  mutations: {
    SET_DECORATION_TYPES(state, payload) {
      state.decorationTypes = payload;
    },
  },

  actions: {
    async getDecorationTypes({ commit }) {
      const endpoint = 'tiposcondecoraciones';
      const decorationTypes = await http.get(endpoint);
      commit('SET_DECORATION_TYPES', decorationTypes);
    },
  },
};
