import catalogosApi from '@/plugins/catalogos.axios';

async function login({ username, password }) {
  const response = await catalogosApi.post('/usuarios/login', { email: username, password });
  return response;
}

export default function createApiAuthorizationRepository() {
  return {
    login,
  };
}
