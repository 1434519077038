<template>
  <v-dialog
    :value="active"
    width="25%"
    persistent
  >
    <ACard>
      <v-card-title class="justify-center">
        {{ personalName }}
      </v-card-title>
      <v-card-subtitle class="text-center">
        Responsable actual
      </v-card-subtitle>
      <v-card-actions class="justify-center">
        <APrimaryButton
          label="Cerrar"
          @click="active = false"
        />
      </v-card-actions>
    </ACard>
  </v-dialog>
</template>

<script>
import EventBus from '@/plugins/EventBus';
import APrimaryButton from '../atoms/APrimaryButton.vue';
import ACard from '../atoms/ACard.vue';

export default {
  components: {
    APrimaryButton,
    ACard,
  },

  data() {
    return {
      active: false,
      personalName: '',
    };
  },

  mounted() {
    EventBus.$on('open-personal-dialog', (personal) => {
      this.active = true;
      this.personalName = `${personal.nombre} ${personal.paterno} ${personal.materno}`;
    });
  },
};
</script>
