import customService from '@/services/custom.service';
import http from '@/plugins/axios';

export default {
  namespaced: true,

  state: {
    customs: [],
    mainCustoms: [],
  },

  getters: {
    getCustoms(state) {
      return state.customs;
    },

    getMainCustoms(state) {
      return state.mainCustoms;
    },
  },

  mutations: {
    SET_CUSTOMS(state, payload) {
      state.customs = payload;
    },

    SET_MAIN_CUSTOMS(state, payload) {
      state.mainCustoms = payload;
    },
  },

  actions: {
    async getCustoms({ commit }) {
      const customs = await customService.getCustoms();
      commit('SET_CUSTOMS', customs);
    },

    async getMainCustoms({ commit }) {
      const endpoint = 'AduanasPrincipales';
      const mainCustoms = await http.get(endpoint);
      commit('SET_MAIN_CUSTOMS', mainCustoms.data.data);
    },
  },
};
