import catalogosApi from '@/plugins/catalogos.axios';

async function getColors() {
  const { data } = await catalogosApi.get('/colores/');
  return data.data;
}

async function createColor(color) {
  const { data } = await catalogosApi.post('/colores/', color);
  return data.data[0].id;
}

async function updateColor(color) {
  await catalogosApi.put(`/colores/${color.id}`, color);
}

async function deleteColor(id) {
  return Promise.resolve(id);
}

export default function createColorApiRepository() {
  return {
    getColors,
    createColor,
    updateColor,
    deleteColor,
  };
}
