import createUser from '@/modules/user/application/createUser';
import deleteUser from '@/modules/user/application/deleteUser';
import getUsers from '@/modules/user/application/getUsers';
import updateUser from '@/modules/user/application/updateUser';
import UserEntity from '@/modules/user/domain/UserEntity';
import createApiUserRepository from '@/modules/user/infrastructure/ApiUserRepository';

const repository = createApiUserRepository();

export default {
  namespaced: true,

  state: {
    users: [],
    userForm: new UserEntity(),
  },

  getters: {
    getUsers(state) {
      return state.users;
    },
  },

  mutations: {
    SET_USERS(state, payload) {
      state.users = payload;
    },
  },

  actions: {
    async getUsers({ commit }) {
      const users = await getUsers(repository);
      commit('SET_USERS', users);
    },

    async createUser({ commit, state, dispatch }) {
      try {
        await createUser(repository, state.userForm);
        dispatch('getUsers');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Registro agregado al catálogo.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar agregar el registro.',
        }, { root: true });
      }
    },

    async updateUser({ commit, state, dispatch }) {
      try {
        const payload = {
          ...state.userForm,
        };
        delete payload.password;

        await updateUser(repository, payload);
        dispatch('getUsers');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Registro actualizado.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar actualizar el registro.',
        }, { root: true });
      }
    },

    async deleteUser({ commit, dispatch }, { id }) {
      try {
        await deleteUser(repository, id);
        dispatch('getUser');

        commit('SET_SNACKBAR', {
          show: true,
          color: 'success',
          message: 'Registro eliminado.',
        }, { root: true });
      } catch (error) {
        commit('SET_SNACKBAR', {
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar eliminar el registro.',
        }, { root: true });
      }
    },
  },
};
