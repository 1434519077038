import axios from 'axios';

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL_PERSONAL_ANAM,
  headers: {
    Authorization: process.env.VUE_APP_API_URL_PERSONAL_ANAM_TOKEN,
  },
});

export default http;
