import http from '@/plugins/axios';

const endpoint = '/caninos';

const dogService = {
  getDogs() {
    return http.get(`${endpoint}`)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  getDogById(dogId) {
    return http.get(`${endpoint}/${dogId}`)
      .then((res) => res.data.data[0])
      .catch((error) => {
        throw error;
      });
  },

  searchDog(payload) {
    return http.get(endpoint, {
      params: {
        ...payload,
      },
    })
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  createDog(payload) {
    return http.post(endpoint, payload)
      .then((res) => {
        if (!res.data.data) {
          return res.data;
        }
        return res.data.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  updateDog(payload, id) {
    return http.put(`${endpoint}/${id}`, payload)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default dogService;
