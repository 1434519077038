import catalogosApi from '@/plugins/catalogos.axios';

async function getUsers() {
  const { data } = await catalogosApi.get('/usuarios/');
  return data.data;
}

async function createUser(user) {
  const { data } = await catalogosApi.post('/usuarios/', user);
  return data.data[0].id;
}

async function updateUser(user) {
  await catalogosApi.put(`/usuarios/${user.id}`, user);
}

async function deleteUser(id) {
  return Promise.resolve(id);
}

export default function createApiUserRepository() {
  return {
    getUsers,
    createUser,
    updateUser,
    deleteUser,
  };
}
