import http from '@/plugins/axios';

const endpoint = '/tiposbaja';

const unsuscribedTypeService = {
  getUnsuscribedTypes() {
    return http.get(`${endpoint}`)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default unsuscribedTypeService;
