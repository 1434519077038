<template>
  <v-dialog
    v-model="updateDocumentDialog"
    width="33%"
    overlay-opacity="0.9"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #activator="{on, attrs}">
      <AIconButton
        v-if="hasPermission(userProfile, 'UPDATE')"
        icon="mdi-pencil"
        class="mx-1"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <ACard>
      <v-card-title class="justify-center mb-4">
        Edición de documento
      </v-card-title>
      <v-card-text>
        <v-form v-model="updateDocumentForm">
          <v-row>
            <v-col cols="6">
              <v-file-input
                v-model="document.adjunto"
                color="white"
                label="Archivo adjunto"
                filled
                rounded
                prepend-icon=""
                append-icon="mdi-file"
              />
            </v-col>
            <v-col cols="6">
              <ASelect
                v-model="document.fk_idTipoDocumento"
                label="Tipo de documento"
                :items="documentTypes"
              />
            </v-col>
            <v-col cols="12">
              <ATextField
                v-model="document.observaciones"
                label="Observaciones"
              />
            </v-col>
            <v-col cols="6">
              <ADateInput
                v-model="document.fecha"
                label="Fecha del documento"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <ASecondaryButton
          label="Cancelar"
          @click="updateDocumentDialog = false"
        />
        <APrimaryButton
          label="Guardar registro"
          :loading="loading"
          :disabled="loading || !updateDocumentForm"
          @click="triggerUpdateDocument()"
        />
      </v-card-actions>
    </ACard>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ACard from '../atoms/ACard.vue';
import AIconButton from '../atoms/AIconButton.vue';
import APrimaryButton from '../atoms/APrimaryButton.vue';
import ASecondaryButton from '../atoms/ASecondaryButton.vue';
import ATextField from '../atoms/ATextField.vue';
import ASelect from '../atoms/ASelect.vue';
import ADateInput from '../atoms/ADateInput.vue';

export default {
  components: {
    APrimaryButton,
    ACard,
    ATextField,
    ASecondaryButton,
    AIconButton,
    ASelect,
    ADateInput,
  },

  data() {
    return {
      updateDocumentDialog: false,
      updateDocumentForm: false,
      loading: false,
    };
  },

  computed: {
    ...mapGetters('dog', {
      dog: 'getCurrentDog',
    }),
    ...mapGetters('document', {
      document: 'getCurrentDocument',
    }),
    ...mapGetters('documentType', {
      documentTypes: 'getDocumentTypes',
    }),
  },

  methods: {
    ...mapActions('document', ['updateDocument']),
    ...mapMutations(['SET_SNACKBAR']),

    async triggerUpdateDocument() {
      this.loading = true;

      try {
        await this.updateDocument(this.document);
        this.SET_SNACKBAR({
          show: true,
          color: 'success',
          message: 'Documento registrado correctamente.',
        });
      } catch (error) {
        this.SET_SNACKBAR({
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar registrar el documento.',
        });
      }

      this.loading = false;
      this.updateDocumentDialog = false;
    },
  },
};
</script>
