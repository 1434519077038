import catalogosApi from '@/plugins/catalogos.axios';

async function getMedicalStatus() {
  const { data } = await catalogosApi.get('/estatusmedico/');
  return data.data;
}

async function createMedicalStatus(medicalStatus) {
  const { data } = await catalogosApi.post('/estatusmedico/', medicalStatus);
  return data.data[0].id;
}

async function updateMedicalStatus(medicalStatus) {
  await catalogosApi.put(`/estatusmedico/${medicalStatus.id}`, medicalStatus);
}

async function deleteMedicalStatus(id) {
  return Promise.resolve(id);
}

export default function createApiMedicalStatusRepository() {
  return {
    getMedicalStatus,
    createMedicalStatus,
    updateMedicalStatus,
    deleteMedicalStatus,
  };
}
