import http from '@/services/http-handler.service';

const endpoint = '/almacenesprincipales';

export default {
  namespaced: true,

  state: {
    currentMainWarehouse: null,
    mainWarehouses: [],
  },

  getters: {
    getCurrentMainWarehouse(state) {
      if (state.currentMainWarehouse) {
        return { ...state.currentMainWarehouse };
      }
      return null;
    },

    getMainWarehouses(state) {
      return state.mainWarehouses;
    },
  },

  mutations: {
    SET_CURRENT_MAIN_WAREHOUSE(state, payload) {
      state.currentMainWarehouse = payload;
    },

    SET_MAIN_WAREHOUSES(state, payload) {
      state.mainWarehouses = payload;
    },
  },

  actions: {
    async getMainWarehouses({ commit }) {
      const mainWarehouses = await http.get(endpoint);
      commit('SET_MAIN_WAREHOUSES', mainWarehouses);
      return mainWarehouses;
    },

    async getMainWarehouseByCustomId({ commit }, customId) {
      const mainWarehouse = await http.get(`aduanas/${customId}${endpoint}`);
      commit('SET_CURRENT_MAIN_WAREHOUSE', mainWarehouse[0]);
    },

    async createMainWarehouse({ dispatch }, mainWarehouse) {
      await http.create(endpoint, mainWarehouse);
      dispatch('getMainWarehouses');
    },

    async updateMainWarehouse({ dispatch }, mainWarehouse) {
      await http.update(`${endpoint}/${mainWarehouse.id}`, mainWarehouse);
      dispatch('getMainWarehouses');
    },
  },
};
