<template>
  <ACard>
    <v-card-title class="text-center">
      <ODogCardHeaderSection :dog="dog" />
    </v-card-title>
    <v-card-text>
      <MDogResumeCardDataSection :dog-data="generateDogDataItems(dog)" />
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn
        fab
        color="secondary"
        dark
        tile
        small
        class="rounded-lg"
        @click="goToDogFile(dog)"
      >
        <v-icon>mdi-file-document-outline</v-icon>
      </v-btn>
      <v-btn
        fab
        color="secondary"
        dark
        tile
        small
        class="rounded-lg"
        @click="getDogCurrentPersonal(dog.id)"
      >
        <v-icon>mdi-account-search</v-icon>
      </v-btn>
    </v-card-actions>
  </ACard>
</template>

<script>
import MDogResumeCardDataSection from '@/components/molecules/MDogResumeCardDataSection.vue';
import { mapActions, mapMutations } from 'vuex';
import EventBus from '@/plugins/EventBus';
import ACard from '../atoms/ACard.vue';
import ODogCardHeaderSection from './ODogCardHeaderSection.vue';

export default {
  components: {
    ACard,
    ODogCardHeaderSection,
    MDogResumeCardDataSection,
  },

  props: {
    dog: {
      type: Object,
      required: true,
    },
  },

  methods: {
    ...mapMutations('dog', ['SET_CURRENT_DOG']),
    ...mapMutations(['SET_SNACKBAR']),
    ...mapActions('responsible', ['getResponsibles']),

    generateDogDataItems(dog) {
      return [
        {
          cols: '12',
          label: 'Tatuaje',
          value: dog.tatuaje,
        },
        {
          cols: '12',
          label: 'Número de microchip',
          value: dog.noMicrochip,
        },
        {
          cols: '12',
          label: 'Tag RFID',
          value: dog.folioInventario,
        },
      ];
    },

    goToDogFile(dog) {
      this.SET_CURRENT_DOG(dog);
      this.$router.push({
        name: 'dog-record',
        params: {
          id: dog.id,
        },
      });
    },

    downloadPdf() {
      EventBus.$emit('generate', true);
    },

    async getDogCurrentPersonal(dogId) {
      await this.getResponsibles(dogId);

      if (this.$store.state.responsible.responsibles.length > 0) {
        EventBus.$emit('open-personal-dialog', this.$store.state.responsible.responsibles[0]);
      } else {
        this.SET_SNACKBAR({
          show: true,
          color: 'error',
          message: 'La mascota no cuenta con un responsable vigente.',
        });
      }
    },
  },
};
</script>
