<template>
  <v-dialog
    v-model="active"
    width="25%"
    persistent
  >
    <template #activator="{ on, attrs }">
      <APrimaryButton
        v-if="!updateData"
        label="Agregar seguimiento"
        v-bind="attrs"
        v-on="on"
      />
      <AIconButton
        v-else
        icon="mdi-pencil"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <ACard>
      <v-card-title class="justify-center">
        Seguimiento de incidencia médica
      </v-card-title>
      <v-card-text>
        <v-form>
          <ATextField
            v-model="treatmentMonitoring.descripcion"
            label="Descripción"
          />
          <ATextField
            :value="selectedDateValue"
            label="Fecha"
            type="datetime-local"
            @input="selectedDate = $event"
          />
          <v-file-input
            v-model="treatmentMonitoring.adjunto"
            label="Adjunto"
            color="white"
            filled
            rounded
            prepend-icon=""
            append-icon="mdi-file"
          />
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center">
        <ASecondaryButton
          label="Cerrar"
          @click="closeDialog()"
        />
        <APrimaryButton
          label="Guardar"
          @click="saveTreatmentMonitoring()"
        />
      </v-card-actions>
    </ACard>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import { mapActions, mapMutations } from 'vuex';
import ACard from '../atoms/ACard.vue';
import AIconButton from '../atoms/AIconButton.vue';
import APrimaryButton from '../atoms/APrimaryButton.vue';
import ASecondaryButton from '../atoms/ASecondaryButton.vue';
import ATextField from '../atoms/ATextField.vue';

export default {
  components: {
    ACard,
    APrimaryButton,
    ATextField,
    ASecondaryButton,
    AIconButton,
  },

  props: {
    updateData: {
      type: Object,
      default: () => null,
    },

    treatmentId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      active: false,
      loading: false,
      treatmentMonitoring: {
        fk_idTratamiento: this.updateData?.fk_idTratamiento || this.treatmentId,
        descripcion: this.updateData?.descripcion || '',
        fecha: '',
        adjunto: null,
      },

      selectedDate: this.updateData?.fecha || '',
    };
  },

  computed: {
    selectedDateValue() {
      if (this.updateData) {
        return moment(moment(this.selectedDate, 'MM/DD/YYYY h:mm:ss A')).format('YYYY-MM-DD[T]HH:mm');
      } return this.selectedDate;
    },
  },

  methods: {
    ...mapActions('treatmentMonitoring', ['createTreatmentMonitoring', 'updateTreatmentMonitoring']),
    ...mapMutations(['SET_SNACKBAR']),

    async saveTreatmentMonitoring() {
      this.loading = true;

      try {
        this.treatmentMonitoring.fecha = this.formatDate(this.selectedDate);

        if (this.updateData) {
          await this.updateTreatmentMonitoring({
            ...this.treatmentMonitoring,
            id: this.updateData.id,
          });
        } else {
          this.treatmentMonitoring.fk_idTratamiento = this.treatmentId;
          await this.createTreatmentMonitoring(this.treatmentMonitoring);
        }
        this.SET_SNACKBAR({
          show: true,
          color: 'success',
          message: 'Seguimiento guardado correctamente.',
        });
        this.closeDialog();
      } catch (error) {
        this.SET_SNACKBAR({
          show: true,
          color: 'error',
          message: 'Ocurrió un error al intentar guardar el seguimiento.',
        });
      }

      this.loading = false;
    },

    closeDialog() {
      if (!this.updateData) {
        this.treatmentMonitoring = {
          fk_idTratamiento: this.treatmentId,
          descripcion: '',
          fecha: '',
          adjunto: null,
        };
        this.selectedDate = '';
      }
      this.active = false;
    },
  },
};
</script>
