<template>
  <ACard color="transparent">
    <v-card-title class="d-block text-center">
      <div>
        {{ vaccine.descripcion }}
      </div>
      <v-chip
        label
        small
        color="surface"
        class="black--text mx-2"
      >
        {{ vaccine.catEstatusVacuna.title }}
      </v-chip>
      <v-chip
        label
        small
        :color="statusNotification.color"
        :class="statusNotification.textColor"
      >
        {{ statusNotification.text }}
      </v-chip>
    </v-card-title>
    <v-card-text>
      <ATextField
        :value="getReadableDate(vaccine.fechaAplicacion)"
        readonly
        label="Fecha de aplicación"
        hide-details
        class="mb-2"
      />
      <ATextField
        :value="vaccine.fechaProximaDosis !== '1/1/1900 12:00:00 AM'
          ? getReadableDate(vaccine.fechaProximaDosis) : 'No registrada'"
        readonly
        label="Fecha de próxima dosis"
        hide-details
        class="mb-2"
      />
      <ATextField
        :value="vaccine.fechaProgramada !== '1/1/1900 12:00:00 AM'
          ? getReadableDate(vaccine.fechaProgramada) : 'No registrada'"
        readonly
        label="Fecha programada"
        hide-details
        class="mb-2"
      />
    </v-card-text>
    <v-card-actions class="justify-center">
      <AIconButton
        :disabled="vaccine.urlAdjunto === ''"
        icon="mdi-file-download"
        class="mx-1"
        @click="downloadDocument(vaccine.urlAdjunto)"
      />
      <OUpdateVaccineDialog @input="setVaccine($event, vaccine)" />
    </v-card-actions>
  </ACard>
</template>

<script>
import moment from 'moment';
import { mapMutations } from 'vuex';
import ACard from '../atoms/ACard.vue';
import AIconButton from '../atoms/AIconButton.vue';
import ATextField from '../atoms/ATextField.vue';
import OUpdateVaccineDialog from './OUpdateVaccineDialog.vue';

export default {
  components: {
    ACard,
    ATextField,
    AIconButton,
    OUpdateVaccineDialog,
  },

  props: {
    vaccine: {
      type: Object,
      required: true,
    },
  },

  computed: {
    statusNotification() {
      const statusNotification = {
        text: 'Expira en más de tres meses',
        color: 'success',
        textColor: 'white--text',
      };

      if (this.vaccine) {
        const today = moment();
        const vaccineDate = moment(this.vaccine.fechaAplicacion, 'MM/DD/YYYY H:mm:ss A').format('YYYY-MM-DD');
        const monthDiff = moment(today).diff(vaccineDate, 'months');

        if (monthDiff < 3) {
          statusNotification.text = 'Expira en menos de 3 meses';
          statusNotification.color = 'warning';
          statusNotification.textColor = 'black--text';
        }
        if (monthDiff <= 1) {
          statusNotification.text = 'Expira en menos de un mes';
          statusNotification.color = 'error';
          statusNotification.textColor = 'white--text';
        }

        return statusNotification;
      }

      return statusNotification;
    },
  },

  methods: {
    ...mapMutations('vaccine', ['UPDATE_CURRENT_VACCINE']),
    setVaccine(event, vaccine) {
      if (event === true) {
        this.UPDATE_CURRENT_VACCINE(vaccine);
      }
    },
  },
};
</script>
