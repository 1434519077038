const getCookieByName = (name) => {
  const cookies = `; ${document.cookie}`;
  const selectedCookie = cookies.split(`; ${name}=`);

  if (selectedCookie.length === 2) {
    return selectedCookie.pop().split(';').shift();
  }

  return null;
};

export default getCookieByName;
