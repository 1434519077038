import unsuscribedTypeService from '@/services/unsuscribedType.service';

export default {
  namespaced: true,

  state: {
    unsuscribedTypes: [],
  },

  getters: {
    getUnsuscribedTypes(state) {
      return state.unsuscribedTypes;
    },
  },

  mutations: {
    SET_UNSUSCRIBED_TYPES(state, payload) {
      state.unsuscribedTypes = payload;
    },
  },

  actions: {
    async getUnsuscribedTypes({ commit }) {
      const unsuscribedTypes = await unsuscribedTypeService.getUnsuscribedTypes();
      commit('SET_UNSUSCRIBED_TYPES', unsuscribedTypes);
    },
  },
};
