<template>
  <ACard
    tile
    flat
    class="secondary darken-2"
  >
    <v-card-text>
      <v-row
        align="center"
        justify="end"
      >
        <v-col cols="auto">
          Filtrar resultados:
        </v-col>
        <v-col cols="3">
          <ATextField
            :value="nameFilter"
            label="Nombre o alias"
            hide-details
            dense
            @input="$emit('update:nameFilter', $event)"
          />
        </v-col>
        <v-col cols="auto">
          <ATextField
            :value="tattooFilter"
            label="Tatuaje"
            hide-details
            dense
            @input="$emit('update:tattooFilter', $event)"
          />
        </v-col>
        <v-col cols="2">
          <ATextField
            :value="chipFilter"
            label="Número de microchip"
            hide-details
            dense
            @input="$emit('update:chipFilter', $event)"
          />
        </v-col>
        <v-col cols="2">
          <ATextField
            :value="inventoryInvoiceFilter"
            label="Tag RFID"
            hide-details
            dense
            @input="$emit('update:inventoryInvoiceFilter', $event)"
          />
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <OCreateDogDialogComponent />
        </v-col>
      </v-row>
    </v-card-text>
  </ACard>
</template>

<script>
import ACard from '../atoms/ACard.vue';
import ATextField from '../atoms/ATextField.vue';
import OCreateDogDialogComponent from './OCreateDogDialogComponent.vue';

export default {
  components: {
    ATextField,
    ACard,
    OCreateDogDialogComponent,
  },

  props: {
    nameFilter: {
      type: String,
      required: true,
    },

    tattooFilter: {
      type: String,
      required: true,
    },

    chipFilter: {
      type: String,
      required: true,
    },

    inventoryInvoiceFilter: {
      type: String,
      required: true,
    },

    showInactives: {
      type: Boolean,
      default: false,
    },
  },

};
</script>
