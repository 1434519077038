import http from '@/plugins/axios';

const endpoint = '/cursos';

const courseService = {
  getCourses() {
    return http.get(`${endpoint}`)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  getCourseByDogId(dogId) {
    return http.get(`/caninos/${dogId}${endpoint}`)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  createCourse(payload) {
    return http.post(endpoint, payload)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  updateCourse(courseId, payload) {
    return http.put(`${endpoint}/${courseId}`, payload)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default courseService;
