<template>
  <ATextField
    v-model="dateValue"
    type="date"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import moment from 'moment';
import ATextField from './ATextField.vue';

export default {
  components: {
    ATextField,
  },
  props: {
    value: {
      type: [Number, String],
      default: '',
    },
  },

  computed: {
    dateValue: {
      get() {
        if (this.value !== '') {
          // * Formato de fecha compatible para mapear en input
          return moment(this.value).format('YYYY-MM-DD');
        } return '';
      },
      set(value) {
        if (value !== '') {
          this.$emit('input', moment(value).format('YYYY-MM-DD'));
        }
      },
    },
  },
};
</script>
