import http from '@/plugins/axios';

const endpoint = '/SeguimientosTratamientos';

const treatmentService = {
  getTreatmentMonitorings() {
    return http.get(`${endpoint}`)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  getTreatmentMonitoringByTreatmentId(treatmentId) {
    return http.get(`/tratamientos/${treatmentId}${endpoint}`)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  createTreatmentMonitoring(payload) {
    return http.post(endpoint, payload)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },

  updateTreatmentMonitoring(treatmentId, payload) {
    return http.put(`${endpoint}/${treatmentId}`, payload)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default treatmentService;
