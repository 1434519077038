import http from '@/plugins/axios';

const endpoint = '/estatus';

const statusService = {
  getStatus() {
    return http.get(`${endpoint}`)
      .then((res) => res.data.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default statusService;
