import Vue from 'vue';
import DIALOG_TYPES from '@/dialog-types';

export default {
  namespaced: true,

  state: {
    selectedDialog: DIALOG_TYPES.DEFAULT,
  },

  getters: {
    getSelectedDialog(state) {
      return state.selectedDialog;
    },
  },

  mutations: {
    SET_DIALOG(state, dialogType) {
      Vue.set(state, 'selectedDialog', dialogType);
    },

    CLOSE_DIALOG(state) {
      Vue.set(state, 'selectedDialog', DIALOG_TYPES.DEFAULT);
    },
  },
};
