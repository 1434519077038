export default class RaceEntity {
  constructor() {
    this.id = null;
    this.raza = '';
    this.activo = true;
    this.tipoAnimal = null;
    this.idTipoAnimal = null;
  }

  fromDto(raceDto) {
    this.id = raceDto.id;
    this.raza = raceDto.title;
    this.activo = raceDto.activo;
    this.tipoAnimal = raceDto.tipoAnimal.nombre;
    this.idTipoAnimal = raceDto.tipoAnimal.id;

    return this;
  }
}
