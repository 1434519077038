export default class RaceDto {
  constructor() {
    this.id = null;
    this.raza = '';
    this.activo = true;
    this.id_cat_tipo_animal = null;
  }

  fromEntity(race) {
    this.id = race.id;
    this.raza = race.raza;
    this.activo = race.activo;
    this.id_cat_tipo_animal = race.idTipoAnimal;

    return this;
  }
}
